<template>
  <div>
    <el-row>
      <el-col :xs="{span:24,offset:0}" :md="{span:18,offset:3}">
        <div class="activities-box">
          <!--    我是最新活动-->
<!--          标题-->
          <ModuleTitle title="最新活动"></ModuleTitle>
<!--          tab标签页-->
<!--          <TabAssembly-->
<!--              :activeName="tabList[0].id"-->
<!--              :tabList="tabList"-->
<!--              :more-btn="true"-->
<!--              :activityFlag="true"-->
<!--              @getActivityByKey="getActivityByKey"-->
<!--          ></TabAssembly>-->
<!--          卡片区域-->
          <div>
            <el-row>
              <el-col :xs="{span:20,offset:2}" :sm="{span:14,offset:5}"  v-if="HamburgerBtnFlag">
                <!--                大图片位置-->
                <div class="big-image-box" v-if="bigImageObj">
                  <video
                      v-if="bigImageObj.video"
                      Controls="true"
                      :Autoplay="Autoplay"
                      class="video-mini"
                      :src="bigImageObj.video"
                      :poster="bigImageObj.picture"
                      muted="true"
                  >
                    您的浏览器不支持video
                  </video>
                  <div v-else class="img-box">
                    <el-image
                        class="video-mini"
                        :src="bigImageObj.picture"
                        fit="cover"
                        @click="goActivityDetail(bigImageObj.id)"
                    ></el-image>
                    <div class="image-tx" @click="goActivityDetail(bigImageObj.id)">
                      <el-button @click="goActivityDetail(bigImageObj.id)" class="image-btn" type="text">{{bigImageObj.title}}</el-button>
                    </div>
                  </div>
                </div>
              </el-col>

              <el-col :xs="{span:20,offset:2}" :sm="{span:14,offset:5}" :md="{span:4,offset:0}" >
<!--                小图片位置-->
                <div :class="[HamburgerBtnFlag?'hidden-scroll-box-mini':'hidden-scroll-box']">
                  <div :class="[HamburgerBtnFlag?'small-image-box-mini':'small-image-box']">
                      <img
                          v-for="(item,index) in smallImages" :key="index"
                          :class="[HamburgerBtnFlag?'small-img-style-mini':'small-img-style',isActive == index ? 'activeImg':'']"
                          :src="item.picture"
                          alt="活动缩略图"
                          @click="clickImg(index)"
                      >
                  </div>
                </div>
              </el-col>
              <el-col :md="{span:14,offset:0}" v-if="!HamburgerBtnFlag">
<!--                大图片位置-->
                <div class="big-image-box" v-if="bigImageObj">
                  <video
                      v-if="bigImageObj.video"
                      id="videoPlay"
                      Controls="true"
                      :Autoplay="Autoplay"
                      :class="[lgImgFlag?'big-image':'lgImgFlag-small']"
                      :src="bigImageObj.video"
                      :poster="bigImageObj.picture"
                      muted="true"
                  >
                    您的浏览器不支持video
                  </video>
                  <div v-else >
                    <img :class="[lgImgFlag?'big-image':'lgImgFlag-small']" :src="bigImageObj.picture" alt="" @click="goActivityDetail(bigImageObj.id)">
                    <div class="image-tx" @click="goActivityDetail(bigImageObj.id)">
                      <el-button @click="goActivityDetail(bigImageObj.id)" class="image-btn" type="text">{{bigImageObj.title}}</el-button>
                    </div>
                  </div>
                </div>
              </el-col>
              <el-col :xs="{span:20,offset:2}" :sm="{span:14,offset:5}" :md="{span:6,offset:0}" >

                <div class="activity-list-box">
                  <!--                活动列表位置-->
                  <div :class="HamburgerBtnFlag?'MsgListTop-mini':'MsgListTop'" class="vertical-space-between">
                    <div>
                      <el-tag type="danger" effect="dark" size="mini">
                        HOT
                      </el-tag>
                      <span class="MsgListTop-tt">最新热门消息</span>
                    </div>
                    <div>
                      <el-button type="text" size="mini" @click="moreActivities()">更多 <i class="el-icon-d-arrow-right"></i></el-button>
                    </div>


                  </div>
                  <div v-if="activitiesDetail.length">
                    <!--                最热门活动-->
                    <div class="hot-activity">
                      <div class="hot-activity-tt" @click="goActivityDetail(activitiesDetail[0].id)">{{activitiesDetail[0].title}}</div>
                      <div class="vertical-space-between">
                        <div class="hot-activity-tx" @click="goActivityDetail(activitiesDetail[0].id)">{{activitiesDetail[0].description}}</div>
                        <div>
                          <el-link class="hot-activity-more" @click="goActivityDetail(activitiesDetail[0].id)">[详情]</el-link>
                        </div>
                      </div>
                    </div>
                    <!--                其他活动列表-->
                    <div class="other-activity-list">
                      <div class="other-activity-list-scroll">
                        <div class="activity-box" v-for="(item,index) in activitiesDetail" :key="index">
                          <div v-if="index != 0" class="activity-tt" @click="goActivityDetail(item.id)">
                              <div class="btn-one-line">
                                {{item.title}}
                              </div>
                              <div class="activity-time">
                                [{{item.time}}]
                              </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-else>
                    <div class="no-msg-front">暂无相关活动数据</div>
                  </div>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>

</template>

<script>
import ModuleTitle from "./ModuleTitle";
// import TabAssembly from "./TabAssembly";
export default {
  components:{
    ModuleTitle,
    // TabAssembly
  },
  name: "Activities",
  data() {
    return {
      tabList:[
        {
          label:'今日焦点',
          id:'new'
        },
        {
          label:'热门回顾',
          id:'hot'
        },
        {
          label:'内部活动',
          id:'huanyutong'
        },
        {
          label:'当地活动',
          id:'fujian'
        }
      ],
      activitiesDetail:[],
      activitiesRoughly:[
        {
          id:72,
          title:'我是活动标题',
          time:'2021-11-21',
          address:'环宇大楼会议厅',
        },
        {
          id:73,
          title:'我是活动标题',
          time:'2021-11-21',
          address:'环宇大楼会议厅',
        },
        {
          id:74,
          title:'我是活动标题我是活动标题',
          time:'2021-11-21',
          address:'环宇大楼会议厅',
        },
        {
          id:75,
          title:'我是活动标题我是活动标题',
          time:'2021-11-21',
          address:'环宇大楼会议厅',
        },
        {
          id:75,
          title:'我是活动标题我是活动标题',
          time:'2021-11-21',
          address:'环宇大楼会议厅',
        }
      ],
      activeIndex:52,
      smallImages:[],
      isActive:0,//缩略图的激活flag
      bigImageObj:{},
      Autoplay:false,//视频是否自动播放
      screenWidth: document.body.clientWidth,//浏览器宽度
      HamburgerBtnFlag:false,//是否显示汉堡按钮
      miniStyle:false,//手机样式
      lgImgFlag:false
    }
  },
  created() {
    this.getActivitySwiper();
    this.getActivityByKey('new');
    this.isActive = 0;
    this.monitoringScreenWidth();//监测屏幕大小变化
  },
  mounted() {
    const that = this
    window.addEventListener("resize", function() {
      return (() => {
        window.screenWidth= document.body.clientWidth;
        that.screenWidth= window.screenWidth;
      })();
    });
  },
  methods: {
    clickImg(index) {
      this.isActive = index;
      this.bigImageObj = this.smallImages[index];
      // document.getElementById("videoPlay").muted = true;
      // this.loadVideo()
    },
    //获取活动图片视频列表
    getActivitySwiper() {
      let url = 'web/activity/swiper?size=5';
      this.getRequest(url).then(res=>{
        this.smallImages = res;
        this.bigImageObj = this.smallImages[this.isActive];
      })
    },
    //跳转到活动详情页
    goActivityDetail(id) {
      this.$router.push({
        path: `/activities_detail/${id}`,
      })
    },
    //获取关键字活动列表
    getActivityByKey(data) {
      let url = 'web/activity/'+data+'?size=10'
      this.getRequest(url).then(res=>{
        this.activitiesDetail = res;
      })
    },
    //浏览器样式调整
    monitoringScreenWidth() {
      // xs 	<768px
      // sm 	≥768px
      // md 	≥992px
      // lg 	≥1200px
      // xl 	≥1920px
      // 974
      if(this.screenWidth > 1276) {
        this.lgImgFlag = true;
      } else {
        this.lgImgFlag = false;
      }
      if(this.screenWidth < 975) {
        this.HamburgerBtnFlag = true;
      }else {
        this.HamburgerBtnFlag = false;
      }
    },
    moreActivities() {
      this.$router.push({name:'ActivitiesPage'});
    }
  },
  watch:{
    //监听浏览器宽度
    screenWidth() {
      this.monitoringScreenWidth();
    }
  },
}
</script>

<style scoped>

.roughly-item:hover .roughly-tt {
  font-weight: bold;
  color: #2954A2;
}
.roughly-item:hover .active-mini-font {
  font-weight: bold;
}
.active{
  border-top: 10px solid #F47921;
  background-color: #2954A2;
}
.active .active-tt {
  color: #F47921;
}
.active .active-tx {
  color: #FFF;
}
/*新更改的活动详情*/
.hidden-scroll-box {
  width: 160px;
  height: 360px;
  position: relative;
  overflow: hidden;
}
.hidden-scroll-box-mini {
  /*height: 80px;*/
  width: 100%;
  position: relative;
  overflow: hidden;
}
.small-image-box {
  position: absolute;
  left: 0;
  top: 0;
  right: -17px;
  bottom: 0;
  overflow-x: hidden;
  overflow-y: scroll;
}
.small-image-box-mini {
  overflow-x: scroll;
  overflow-y: hidden;
  /*段落中不换行*/
  white-space: nowrap;
  /*隐藏滚动条*/
  /*position: absolute;*/
  /*left: 0;*/
  /*top: 0;*/
  /*right: 0;*/
  /*bottom:  -17px;*/
}
.big-image-box {
  text-align: center;
  width: 100%;
  cursor: pointer;
  height: 360px;
}
.big-image {
  /*width:550px;*/
  max-height:360px;
  max-width: 90%;

}
.lgImgFlag-small{
  max-width: 90%;
  position: relative;
  max-height: 360px;
  cursor: pointer;
}
.small-img-style {
  width: 150px;
  /*filter:blur(5px);*/
  position: relative;
  max-height: 90px;
  cursor: pointer;
}
.small-img-style-mini {
  width:80px;
  height:100%;
  max-height: 46px;
  /*filter:blur(5px);*/
  cursor: pointer;

  /*margin-right:10px;*/
}
.image-tx {
  position: relative;
  bottom:45px;
  width: 100%;
  background-color: rgba(0,0,0,0.6);
  color: #FFFFFF;
  line-height: 40px;
  text-align: center;
}

.activeImg {
  filter:blur(0)
}

/*活动列表*/

.MsgListTop {
  text-align: center;
}
.MsgListTop-mini {
  margin-top: 20px;
}
.MsgListTop-tt {
  margin-left: 10px;
}
.hot-activity {
  border-bottom: 1px solid #cccccc;
  padding-bottom: 10px;
  margin-bottom: 10px;
}
.hot-activity-tt {
  font-size: 18px;
  font-weight: bold;
  margin-top: 10px;
  color: #555;
  cursor: pointer;
}
.hot-activity-tx {
  font-size: 12px;
  color: #6F6F6F;
  width: 80%;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  word-break: break-all;
  margin-top: 4px;
  cursor: pointer;
}
.hot-activity-more {
  color: #2954A2 !important;
  font-size: 14px;
  cursor: pointer;
}
.other-activity-list {
  /*width: 255px;*/
  height: 260px;
  position: relative;
  overflow: hidden;
}
.other-activity-list-scroll {
  position: absolute;
  left: 0;
  top: 0;
  right: -17px;
  bottom: 0;
  overflow-x: hidden;
  overflow-y: scroll;
}
.activity-box {
  /*margin-bottom: 10px;*/
}
.activity-tt {
  font-size: 14px;
  color: #2F2F2F;
  cursor: pointer;
  /*display: -webkit-box;*/
  /*-webkit-box-orient: vertical;*/
  /*-webkit-line-clamp: 1;*/
  /*overflow: hidden;*/
  /*word-break: break-all;*/
}
.activity-time {
  color: #acacac;
  font-size: 12px;
  margin-bottom: 10px ;
  /*line-height: 12px;*/
}
.image-btn {
  color: #FFFFFF;
}
.no-msg-front {
  color: #888;
  background-color: #efefef;
  padding: 20px;
  margin-top: 20px;
  text-align: center;
  font-size: 12px;
}
.active-btn {
  color: #666;
  text-align: left;
  /*margin-top: 10px;*/
  /*line-height: 14px;*/
}
.video-mini {
  max-width: 90%;
  cursor: pointer;
  max-height: 235px;
}
.img-box {
  text-align: center;
}
.btn-one-line {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  word-break: break-all;
}
.activity-list-box {
  padding-left: 10px;
}
</style>
