<template>
  <div>
    <el-form :model="form" :rules="rules" ref="form" size="mini">
      <el-row>
        <el-col :span="24">
          <el-form-item label="需求标题" :label-width="formLabelWidth" placeholder="请输入需求标题" prop="title">
            <el-input type="textarea" v-model="form.title" autocomplete="off"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="内容描述" :label-width="formLabelWidth"  placeholder="请输入需求描述" prop="content">
            <el-input type="textarea" v-model="form.content" autocomplete="off"></el-input>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="13">
          <el-form-item label="截止时间" :label-width="formLabelWidth" prop="deadline">
            <el-date-picker clearable
                            v-model="form.deadline"
                            type="date"
                            value-format="yyyy-MM-dd"
                            placeholder="选择需求截止时间">
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col  :xs="24" :sm="11">
          <el-form-item label="需求类别" :label-width="formLabelWidth" prop="typeId">
            <el-cascader
                v-model="form.typeId"
                :options="typeDict"
                :props="{ checkStrictly: true }">
            </el-cascader>
          </el-form-item>
        </el-col>
        <el-col  :xs="24" :sm="12">
          <el-form-item label="需求预算" :label-width="formLabelWidth" prop="price">
            <el-input v-model="form.price" placeholder="请输入预算"></el-input>
            <div class="tip-font">面议请填 "-1"</div>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12">
          <el-form-item label="单位" prop="unit" :label-width="formLabelWidth">
            <el-select v-model="form.unit" placeholder="请选择单位">
              <el-option
                  v-for="dict in before_price_type"
                  :key="dict.dictValue"
                  :label="dict.dictLabel"
                  :value="parseInt(dict.dictValue)"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12">
          <el-form-item label="公司" :label-width="formLabelWidth">
            <el-select v-model="form.partnerId" placeholder="请选择公司">
              <el-option
                  v-for="dict in companyDict"
                  :key="dict.value"
                  :label="dict.label"
                  :value="parseInt(dict.value)"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12">
          <el-form-item label="学校" :label-width="formLabelWidth">
            <el-select v-model="form.schoolId" placeholder="请选择学校">
              <el-option
                  v-for="dict in schoolDict"
                  :key="dict.value"
                  :label="dict.label"
                  :value="parseInt(dict.value)"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12">
          <el-form-item label="联系人" :label-width="formLabelWidth">
            <el-input disabled v-model="user.name" autocomplete="off"></el-input>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12">
          <el-form-item label="联系方式" :label-width="formLabelWidth">
            <el-input disabled v-model="user.phone" autocomplete="off"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="handleCancel">取 消</el-button>
      <el-button type="primary" @click="handleSubmit">确 定</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "UploadDemand",
  props:['demandObj'],
  data() {
    return {
      formLabelWidth:'120px',
      form:{},
      //分类字典
      typeDict:[],
      //单位字典
      before_price_type:[],
      //公司字典
      companyDict:[],
      //学校字典
      schoolDict:[],
      user:{},
      rules: {
        title:[
          { required:true, message:'标题不能为空', trigger:'blur' },
          { min:1, max:22, message: '长度不能超过22个汉字', trigger: 'blur' }
        ],
        content:[
          { required:true, message:'需求内容不能为空', trigger:'blur' },
        ],
        deadline:[
          { required:true, message:'需求截止时间不能为空', trigger:'blur' },
        ],
        typeId:[
          { required:true, message:'需求类别不能为空', trigger:'blur' },
        ],
        price:[
          { required:true, message:'需求预算不能为空', trigger:'blur' },
        ],
        unit:[
          { required:true, message:'需求单位不能为空', trigger:'blur' },
        ]
      },
      screenWidth: document.body.clientWidth,//浏览器宽度
    }
  },
  created() {
    this.user = this.$cookies.get('user');
    // this.demandEditObj = this.demandObj;
    if(this.demandObj.id) {
      //编辑功能，赋值
      this.form.id = this.demandObj.id;
      //根据id请求到最新的需求数据
      this.getDemandById();
    }
    //获取分类字典
    this.getTypeDict();
    //获取单位字典
    this.getUnitDict();
    //获取公司字典
    this.getCompanyDict();
    //获取学校字典
    this.getSchoolDict();
    this.form.userId = this.user.id;
    //监控屏幕变化
    this.monitoringScreenWidth();
  },
  mounted() {
    const that = this
    window.addEventListener("resize", function() {
      return (() => {
        window.screenWidth= document.body.clientWidth;
        that.screenWidth= window.screenWidth;
      })();
    });
  },
  watch:{
    //监听浏览器宽度
    screenWidth() {
      this.monitoringScreenWidth();
    },
  },
  methods:{
    //提交取消操作
    handleCancel() {
      this.$emit('dialogCancel')
    },
    //提交确认操作
    handleSubmit() {
      //专利分类处理
      this.$refs["form"].validate(valid => {
        if (valid) {
          let typeArray= this.form.typeId;
          if (typeArray.length===2){
            this.form.typeId = typeArray[1];
          }else if(typeArray.length===1){
            this.form.typeId = typeArray[0];
          }else {
            this.form.typeId = typeArray;
          }
          //根据id判断是修改操作还是添加操作
          if(this.form.id) {
            //编辑操作
            this.editDemandReq();
          } else {
            this.addDemandReq();
          }
        }
      })
    },
    //根据id查询需求最新数据
    getDemandById() {
      this.getRequest('web/demand/' + this.form.id).then(res=>{
        this.form = res;
      })
    },
    //获取分类字典
    getTypeDict() {
      let url = 'web/type/dict';
      this.getRequest(url).then(res=>{
        this.typeDict = res;
      })
    },
    //获取单位字典
    getUnitDict() {
      this.before_price_type = this.$store.state.unitDict;
    },
    //获取所有公司字典
    getCompanyDict() {
      this.companyDict = this.$store.state.companyDict;
    },
    //获取学校字典
    getSchoolDict() {

      this.schoolDict = this.$store.state.schoolDict;
    },
    //修改请求
    editDemandReq() {
      this.putRequest('web/demand/update',this.form).then(res=>{
        if(res.code == 200) {
          this.$message({
            message: "编辑成功！",
            type: 'success'
          });
          //向父组件专递
          this.$emit('demandObjSubmit');
        }
      })
    },
    //添加请求
    addDemandReq() {
      this.postRequest('web/demand/add',this.form).then(res=>{
        if(res.code == 200) {
          this.$message({
            message: "添加成功！",
            type: 'success'
          });
          //向父组件专递
          this.$emit('demandObjSubmit');
        }
      })
    },
    //浏览器样式调整
    monitoringScreenWidth() {
      // xs 	<768px
      // sm 	≥768px
      // md 	≥992px
      // lg 	≥1200px
      // xl 	≥1920px
      // console.log(this.screenWidth)
      if(this.screenWidth < 565) {
        this.formLabelWidth = 0
      }else {
        this.formLabelWidth = '120px'
      }
    }
  }
}
</script>

<style scoped>
.dialog-footer {
  text-align: right;
}
.tip-font {
  font-size: 10px;
  color: #FD3A0C;
  position: absolute;
  top: 84%;
  right: 0%;
}
</style>