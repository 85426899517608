<template>
  <div>
    <el-form :model="patentObj" ref="patentObj" :rules="rules" size="mini">
      <el-row>
        <el-col :span="24">
          <el-form-item label="项目名称" :label-width="formLabelWidth" placeholder="请输入专利名称" prop="name">
            <el-input
                type="textarea"
                v-model="patentObj.name"
                autocomplete="off"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="项目描述" :label-width="formLabelWidth" prop="description">
            <el-input
                type="textarea"
                v-model="patentObj.description"
                placeholder="请输入描述"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="拥有者" :label-width="formLabelWidth" prop="beforeUserList">
<!--            展示所有以绑定用户-->
            <div>
              <el-tag
                  :key="item.id"
                  v-for="item in patentObj.beforeUserList"
                  closable
                  :disable-transitions="false"
                  @close="handleCloseUserTag(item)">
                {{item.name}}
              </el-tag>
            </div>
            <el-select
                style="width: 80%"
                v-model="selectUser"
                filterable
                remote
                reserve-keyword
                placeholder="请输入要添加绑定用户的手机号"
                :remote-method="remoteMethod"
                @change="remoteSelectHandle"
                :loading="remoteLoading">
<!--              展示根据手机号匹配到的用户名称-->
              <el-option
                  v-for="item in userOptions"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id+','+item.name">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12">
          <el-form-item label="项目类别" :label-width="formLabelWidth" prop="typeId">
            <el-cascader
                v-model="patentObj.typeId"
                :options="typeDict"
                :props="{ checkStrictly: true }">
            </el-cascader>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="项目图片" :label-width="formLabelWidth">
            <el-upload
                class="upload-demo"
                action="#"
                :on-preview="handlePreview"
                :on-remove="handleRemove"
                :before-upload="uploadFile"
                :auto-upload="true"
                :file-list="patentImgUrlList"
                list-type="picture">
              <el-button size="small" type="primary">点击上传</el-button>
              <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div>
            </el-upload>
            <el-dialog :visible.sync="uploadImgDialogVisible" :append-to-body="false">
              <img width="100%" :src="dialogImageUrl" alt="">
            </el-dialog>
          </el-form-item>
        </el-col>

        <el-col :xs="24" :sm="12">
          <el-form-item label="项目价值" :label-width="formLabelWidth" prop="price">
            <el-input v-model="patentObj.price" placeholder="请输入价格"></el-input>
            <div class="tip-font">面议请填 "-1"</div>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12">
          <el-form-item label="单位" prop="unit" :label-width="formLabelWidth">
            <el-select v-model="patentObj.unit" placeholder="请选择单位">
              <el-option
                  v-for="dict in before_price_type"
                  :key="dict.dictValue"
                  :label="dict.dictLabel"
                  :value="parseInt(dict.dictValue)"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :xs="24" :sm="12">
          <el-form-item label="项目等级" :label-width="formLabelWidth" prop="level">
            <el-select v-model="patentObj.level" placeholder="请选择项目级别">
              <el-option
                  v-for="dict in before_patent_level"
                  :key="dict.dictValue"
                  :label="dict.dictLabel"
                  :value="parseInt(dict.dictValue)"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12">
          <el-form-item label="是否市场化" :label-width="formLabelWidth" prop="isMarket">
            <el-select v-model="patentObj.isMarket" placeholder="请选择市场化">
              <el-option
                  v-for="dict in before_patent_is_market"
                  :key="dict.dictValue"
                  :label="dict.dictLabel"
                  :value="parseInt(dict.dictValue)"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12">
          <el-form-item label="成熟度" prop="maturity" :label-width="formLabelWidth">
            <el-select v-model="patentObj.maturity" placeholder="请选择成熟度">
              <el-option
                  v-for="dict in before_patent_maturity"
                  :key="dict.dictValue"
                  :label="dict.dictLabel"
                  :value="parseInt(dict.dictValue)"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12">
          <el-form-item label="合作方式" :label-width="formLabelWidth" prop="cooperationType">
            <el-select v-model="patentObj.cooperationType" placeholder="请选择合作方式">
              <el-option
                  v-for="dict in before_patent_cooperation_type"
                  :key="dict.dictValue"
                  :label="dict.dictLabel"
                  :value="parseInt(dict.dictValue)"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12">
          <el-form-item label="公司" :label-width="formLabelWidth">
            <el-select v-model="patentObj.partnerVoList" multiple placeholder="请选择所属公司">
              <el-option
                  v-for="item in companyDict"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12">
          <el-form-item label="学校" :label-width="formLabelWidth">
            <el-select v-model="patentObj.schoolVoList" multiple placeholder="请选择所属学校">
              <el-option
                  v-for="item in schoolDict"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12">
          <el-form-item label="项目年份" :label-width="formLabelWidth" prop="projYear">
            <el-date-picker clearable
                            v-model="patentObj.projYear"
                            type="date"
                            value-format="yyyy-MM-dd"
                            placeholder="选择专利年份">
            </el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="handleCancel">取 消</el-button>
      <el-button type="primary" @click="handleSubmit">确 定</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "UploadProject",
  props:['patentEditObj'],
  data() {
    return {
      formLabelWidth:'120px',
      disabled: false,
      dialogVisible: false,
      dialogFormVisible:false,
      patentObj:{},
      //分类字典
      typeDict: [],
      //图片上传
      uploadImgDialogVisible: false,
      dialogImageUrl : "",
      patentImgUrlList: [],
      //专利拥有者
      selectUser:"",
      remoteLoading: false,//显示是否在加载中
      userOptions:[],//根据手机号匹配出来的可绑定的用户列表
      userList:[],//请求到的所有的用户列表
      //专利单位字典
      before_price_type:[],
      //市场化字典
      before_patent_is_market:[],
      //成熟度字典
      before_patent_maturity:[],
      //合作方式字典
      before_patent_cooperation_type:[],
      //专利等级字典
      before_patent_level:[],
      //公司字典
      companyDict:[],
      //学校字典
      schoolDict:[],
      patentId:null,
      //表单验证规则
      rules: {
        name: [
          { required:true, message:'名称不能为空', trigger:'blur' },
          { min:1, max:22, message: '长度不能超过22个汉字', trigger: 'blur' }
        ],
        description: [
          { required:true, message:'专利的描述不能为空', trigger:'blur' }
        ],
        beforeUserList:[
          { required:true, message:'专利拥有者不能为空', trigger:'blur' }
        ],
        typeId:[
          { required:true, message:'专利类别不能为空', trigger:'blur' }
        ],
        price:[
          { required:true, message:'专利价值不能为空', trigger:'blur' }
        ],
        unit:[
          { required:true, message:'单位不能为空', trigger:'blur' }
        ],
        level:[
          { required:true, message:'专利级别不能为空', trigger:'blur' }
        ],
        isMarket:[
          { required:true, message:'是否市场化不能为空', trigger:'blur' }
        ],
        maturity:[
          { required:true, message:'成熟度不能为空', trigger:'blur' }
        ],
        cooperationType:[
          { required:true, message:'合作方式不能为空', trigger:'blur' }
        ],
        projYear:[
          { required:true, message:'专利时间不能为空', trigger:'blur' }
        ]
      },
      screenWidth: document.body.clientWidth,//浏览器宽度
    }
  },
  created() {
    //获取专利数据
    if(this.patentEditObj.id) {
      this.getPatentById();
    } else {
      this.reset();
    }
    //获取分类字典
    this.getTypeDict();
    //获取单位字典
    this.getUnitDict();
    //获取专利等级字典
    this.getPatentLevelDict();
    //获取市场化字典
    this.getIsMarketDict();
    //获取成熟度字典
    this.getMaturityDict();
    //获取合作方式字典
    this.getCooperationDict();
    //获取所有用户
    this.getAllUser();
    //获取公司字典
    this.getCompanyDict();
    //获取学校字典
    this.getSchoolDict();
    //监控屏幕变化
    this.monitoringScreenWidth();
  },
  mounted() {
    const that = this
    window.addEventListener("resize", function() {
      return (() => {
        window.screenWidth= document.body.clientWidth;
        that.screenWidth= window.screenWidth;
      })();
    });
  },
  watch:{
    //监听浏览器宽度
    screenWidth() {
      this.monitoringScreenWidth();
    },
  },
  methods:{
    //根据ID请求到对应项目的最新数据
    getPatentById() {
      this.patentId = this.patentEditObj.id;
      //请求对应id的最新数据
      let url = 'web/project/' + this.patentId;
      this.getRequest(url).then(res=>{
        this.patentObj = res;
        if(this.patentObj.partnerVoList) {
          this.patentObj.partnerVoList.forEach((item,index)=>{
            this.patentObj.partnerVoList[index] = item.value;
          })
        }else {
          this.patentObj.partnerVoList = [];
        }
        if(this.patentObj.schoolVoList) {
          this.patentObj.schoolVoList.forEach((item,index)=>{
            this.patentObj.schoolVoList[index] = item.value;
          })
        }else {
          this.patentObj.schoolVoList = [];
        }
        if(res.pictureVoList) {
          this.patentImgUrlList = res.pictureVoList;
        }else {
          this.patentImgUrlList = [];
        }
      })
    },
    //图片的方法
    handlePreview(file){
      this.dialogImageUrl = file.url;
      this.uploadImgDialogVisible = true;
    },
    handleRemove(file,fileList) {
      this.patentImgUrlList = fileList;
    },
    //图片上传
    uploadFile(file){
      if (file) {
        let that = this;
        this.getRequest('web/qiniu/token').then(response => {
          this.qiniu.upload(file, null, response, null, null).subscribe({
            error: (errResult) => {
              // this.msgError("文件上传错误")
              console.log(errResult)
            },
            complete: (result) => {
              // this.reverseImg = result.key;  //保存文件名
              //根据hashkey加载url
              that.getRequest('web/qiniu/url?hashkey=' + result.key).then(response=>{
                console.log(response)
                let imgItem = {"name" : result.key , "url" : response};
                that.patentImgUrlList.push(imgItem);
              })
            }
          })
        })
      }
    },
    //提交取消操作
    handleCancel() {
      this.$emit('dialogCancel')
    },
    //提交确认操作
    handleSubmit() {
      //专利公司处理
      this.patentObj.partnerVoList.forEach((item,index)=>{
        // this.patentObj.partnerVoList[index] = {'value':item}
        this.patentObj.partnerVoList[index] = this.companyDict.find(ele=>ele.value == item);
      })
      //学校处理
      this.patentObj.schoolVoList.forEach((item,index)=>{
        // this.patentObj.schoolVoList[index] = {'value':item}
        this.patentObj.schoolVoList[index] = this.schoolDict.find(ele=>ele.value == item);
      })
      this.$refs["patentObj"].validate(valid => {
        if (valid) {
          //专利分类处理
          let typeArray= this.patentObj.typeId;
          if (typeArray.length===2){
            this.patentObj.typeId = typeArray[1];
          }else if(typeArray.length===1){
            this.patentObj.typeId = typeArray[0];
          }else {
            this.patentObj.typeId = typeArray;
          }
          if (this.patentObj.id != null) {
            //处理多图片 以|分割图片hashkey
            let newPicture = '';
            for (let i = 0 ; i<this.patentImgUrlList.length ; i++){
              let item = this.patentImgUrlList[i];
              if (this.patentImgUrlList.length-i===1){
                newPicture += item.name;
              }else {
                newPicture += item.name+'|';
              }
            }
            this.patentObj.picture = newPicture;
            this.updatePatent();
          } else {
            //处理多图片 以|分割图片hashkey
            let newPicture = '';
            for (let i = 0 ; i < this.patentImgUrlList.length ; i++){
              let item = this.patentImgUrlList[i];
              if (this.patentImgUrlList.length-i===1){
                newPicture += item.name;
              }else {
                newPicture += item.name+'|';
              }
            }
            this.patentObj.picture = newPicture;
            this.addPatent();
          }
        }
      });

    },
    //获取分类字典
    getTypeDict() {
      // this.typeDict = this.$store.state.type;
      // console.log(this.typeDict)
      let url = 'web/type/dict';
      this.getRequest(url).then(res=>{
        this.typeDict = res;
      })
    },
    //获取单位字典
    getUnitDict() {
      this.before_price_type = this.$store.state.unitDict;
    },
    //获取专利等级字典
    getPatentLevelDict() {
      this.before_patent_level = this.$store.state.patentLevel;
    },
    //获取市场化字典
    getIsMarketDict() {
      this.before_patent_is_market = this.$store.state.marketDict;
    },
    //获取成熟度字典
    getMaturityDict() {
      this.before_patent_maturity = this.$store.state.maturity;
    },
    //获取合作方式字典
    getCooperationDict() {
      this.before_patent_cooperation_type = this.$store.state.cooperation;
    },
    //获取所有公司字典
    getCompanyDict() {
      this.companyDict = this.$store.state.companyDict;
    },
    //
    getSchoolDict() {
      this.schoolDict = this.$store.state.schoolDict;
    },
    ////获取全部的用户数据
    getAllUser() {
      this.getRequest('web/user/all').then(res=>{
        this.userList = res;
      })
    },
    //删除专利拥有者
    handleCloseUserTag(user){
      let index = this.patentObj.beforeUserList.findIndex(item =>{
        if(item.id===user.id){
          return true
        }
      })
      this.patentObj.beforeUserList.splice(index,1);
    },
    //远程方法=>根据用户输入的电话号码匹配绑定者
    remoteMethod(query){
      if (query !== '') {
        this.remoteLoading = true;
        setTimeout(() => {
          this.remoteLoading = false;
          this.userOptions = this.userList.filter(item => {
            return item.phone == query;
          });
        }, 400);
      } else {
        this.userOptions = [];
      }
    },
    //专利绑定拥有者
    remoteSelectHandle(idAndName){
      console.log(idAndName)
      let id = idAndName.split(',')[0];
      let name = idAndName.split(',')[1];
      let flag = this.patentObj.beforeUserList.find(function (item) {
        return item.id == id;
      });
      if (flag==null){
        //添加标签
        this.patentObj.beforeUserList.push({"id":id , "name":name});
      }else {
        this.$message({
          message: "请勿重复绑定",
          type: 'warning'
        });
      }
    },
    // 表单重置
    reset() {
      this.patentObj = {
        id: null,
        name: null,
        level: null,
        picture: null,
        ownerId: null,
        description: null,
        price: null,
        unit: null,
        typeId: null,
        assigneeId: null,
        status: 0,
        projYear: null,
        isHot: null,
        isMarket: null,
        maturity: null,
        cooperationType: null,
        beforeUserList:[]
      };
      // this.resetForm("form");
    },
    //修改专利请求
    updatePatent() {
      this.putRequest('web/project/update',this.patentObj).then(res=>{
        if(res.code == 200) {
          this.$message({
            message: "编辑成功！",
            type: 'success'
          });
          this.$emit('updateList')
        }
      })
    },
    //新增专利请求
    addPatent() {
      this.postRequest('web/project/add',this.patentObj).then(res=>{
        if(res.code == 200) {
          this.$message({
            message: "添加成功！",
            type: 'success'
          });
          this.$emit('updateList')
        }
      })
    },
    //浏览器样式调整
    monitoringScreenWidth() {
      // xs 	<768px
      // sm 	≥768px
      // md 	≥992px
      // lg 	≥1200px
      // xl 	≥1920px
      // console.log(this.screenWidth)
      if(this.screenWidth < 565) {
        this.formLabelWidth = 0
      }else {
        this.formLabelWidth = '120px'
      }
    }
  }
}
</script>

<style scoped>
.dialog-footer {
  text-align: right;
}
.tip-font {
  font-size: 10px;
  color: #FD3A0C;
  position: absolute;
  top: 84%;
  right: 0%;
}
</style>
