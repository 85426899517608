<template>
  <div>
    <!--    我的专利页面-->
    <el-row>
      <el-col :span="12" :offset="6">
        <ModuleTitle title="我的专利"></ModuleTitle>
      </el-col>
      <el-col :span="3">
        <div class="upLoad-btn-box">
          <el-button class="upLoad-btn" size="mini" type="warning" @click="dialogFormVisible = true">上传专利 <i class="el-icon-upload"></i></el-button>
        </div>
      </el-col>
      <el-col :xs="{span:22,offset:1}" :sm="{span:18,offset:3}">
        <div v-if="myPatentList.length">
          <PatentItem v-if="patentItemFlag" :patentList="myPatentList" @updatePatent="getMyPatent" edit-btn="true" no-just-detail="true"></PatentItem>
        </div>
        <div v-else class="noData-box">
          暂无专利数据，快来发布吧！
        </div>
      </el-col>
    </el-row>
<!--    分页-->
    <Paging
        :total="pageTotal"
        :pageSize="parameterObj.pageSize"
        :currentPage="parameterObj.pageNum"
        @changeCurrent="changeCurrent"
        @changeSize="changeSize"
    ></Paging>
<!--    专利上传对话框-->
    <el-dialog title="上传专利" :visible.sync="dialogFormVisible" :width="dialogWidth">
      <UploadPatent
          v-if="dialogFormVisible"
          :patentEditObj="patentUploadForm"
          @dialogCancel="dialogCancel()"
          @updateList="patentEditObjSubmit"
      ></UploadPatent>
    </el-dialog>
  </div>
</template>

<script>
import ModuleTitle from "@/components/HomePage/ModuleTitle";
import Paging from "@/components/Paging";
import PatentItem from "@/components/PatentPage/PatentItem";
import UploadPatent from "@/components/PatentPage/UploadPatent";
export default {
  name: "MyPatent",
  components:{
    ModuleTitle,
    Paging,
    PatentItem,
    UploadPatent
  },
  data() {
    return {
      myPatentList:[],
      //专利上传对话框展示标志
      dialogFormVisible: false,
      // 表单提示词宽度
      formLabelWidth:'118px',
      // 上传的专利表单
      patentUploadForm: {
        id: null,
        name: null,
        level: null,
        picture: null,
        ownerId: null,
        description: null,
        price: null,
        unit: null,
        typeId: null,
        assigneeId: null,
        status: 0,
        projYear: null,
        isHot: null,
        isMarket: null,
        maturity: null,
        cooperationType: null
      },
      userId:null,
      patentId:0,
      patentItemFlag:false,
      pageTotal:0,//专利总条数
      parameterObj:{
        pageNum:1,
        pageSize:5
      },
      screenWidth: document.body.clientWidth,//浏览器宽度
      dialogWidth:'50%',//控制编辑框宽度
    }
  },
  created() {
    this.userId = this.$cookies.get('user').id;
    this.getMyPatent();
    //监控屏幕变化
    this.monitoringScreenWidth();
  },
  mounted() {
    const that = this
    window.addEventListener("resize", function() {
      return (() => {
        window.screenWidth= document.body.clientWidth;
        that.screenWidth= window.screenWidth;
      })();
    });
  },
  watch:{
    //监听浏览器宽度
    screenWidth() {
      this.monitoringScreenWidth();
    },
  },
  methods:{
    //关闭编辑对话框
    dialogCancel() {
      this.dialogFormVisible = false;
    },
    //拿到已经编辑好的专利数据
    patentEditObjSubmit() {
      this.dialogFormVisible = false;
      this.patentItemFlag = false;
      //刷新数据列表
      this.getMyPatent();
    },
    //获取用户的专利
    getMyPatent() {
      let url = 'web/patent/user?id='+this.userId+'&pageNum='+this.parameterObj.pageNum+'&pageSize=' + this.parameterObj.pageSize;
      this.getRequest(url).then(res=>{
        this.patentItemFlag = true;
        this.myPatentList = res.row;
        this.pageTotal = res.totalCount;
        this.parameterObj.pageNum = res.pageNum;
        this.parameterObj.pageSize = res.pageSize;
      })
    },
    //请求另一页的数据
    changeCurrent(currentPage) {
      this.parameterObj.pageNum = currentPage;
      this.getMyPatent();
    },
    //改变当前每页的数量
    changeSize(value) {
      this.parameterObj.pageSize = value;
      this.parameterObj.pageNum = 1;
      this.getMyPatent();
    },
    //浏览器样式调整
    monitoringScreenWidth() {
      // xs 	<768px
      // sm 	≥768px
      // md 	≥992px
      // lg 	≥1200px
      // xl 	≥1920px
      // console.log(this.screenWidth)
      if(this.screenWidth < 1123) {
        this.dialogWidth ='90%';
      }else {
        this.dialogWidth = '50%';
      }
    }
  }
}
</script>

<style scoped>
.upLoad-btn-box {
  text-align: right;
}
.upLoad-btn {
  position: relative;
  top: 35%; /*偏移*/
  right: 20px;
  /*transform: translateY(100%);*/

}
.noData-box {
  text-align: center;
  margin-top: 20px;
  color: #666666;
  /*height: 200px;*/
  background-color: #efefef;
  padding: 20px;
}
</style>