import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import login from "../views/login";
import register from "../views/register";
import PatentList from "../views/PatentList";
import DemandPage from "@/views/DemandPage";
import AchievementsPage from "@/views/AchievementsPage";
import AchievementsDetail from "@/views/AchievementsDetail.page"
import PolicyPage from "@/views/PolicyPage";
import ActivitiesPage from "@/views/ActivitiesPage";
import ActivitiesDetail from "@/views/ActivitiesDetail";
import PatentDetail from "@/views/PatentDetail";
import AboutUsPage from "@/views/AboutUsPage";
import PartnerDetailPage from "@/views/PartnerDetailPage";
import ServiceHallPage from "@/views/ServiceHallPage";
import DemandDetail from "@/views/DemandDetail";
import PolicyDetail from "@/views/PolicyDetail";
import topicDetailsPage from "@/views/topicDetailsPage";
import IntelligentMatchDetailPage from "@/views/IntelligentMatchDetailPage";
import MyInfo from "@/views/MyInfo";
import MyPatent from "@/views/MyPatent";
import MyDemand from "@/views/MyDemand";
import MyProject from "@/views/MyProject";
import SearchResult from "../views/SearchResult";
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta:{
      title:'美一在线知识产权管理平台'
    }
  },
  {
    path:'/login',
    name:'login',
    component: login
  },
  {
    path:'/register',
    name:'register',
    component: register
  },
  {
    path:'/patent_list',
    name:'PatentList',
    component: PatentList
  },
  {
    path:'/demand_page',
    name:'DemandPage',
    component: DemandPage
  },
  {
    path:'/demand_page/demand_detail',
    name:'DemandDetail',
    component: DemandDetail
  },
  {
    path:'/achievements_page',
    name:'AchievementsPage',
    component: AchievementsPage
  },
  {
    path:'/achievements_page/detail',
    name:'AchievementsDetail',
    component: AchievementsDetail
  },
  {
    path:'/policy_page',
    name:'PolicyPage',
    component: PolicyPage
  },
  {
    path:'/policy_page/policy_detail/:id',
    name:'PolicyDetail',
    component: PolicyDetail
  },
  {
    path:'/activities_page',
    name:'ActivitiesPage',
    component: ActivitiesPage
  },
  {
    path:'/activities_detail/:id',
    name:'ActivitiesDetail',
    component: ActivitiesDetail
  },
  {
    path:'/patent_list/detail',
    name:'PatentDetail',
    component: PatentDetail
  },
  {
    path:'/about_us_page',
    name:'AboutUsPage',
    component: AboutUsPage
  },
  {
    path:'/partner_detailPage/:id/:type',
    name:'PartnerDetailPage',
    component: PartnerDetailPage,
    // meta: {
    //   keepAlive: true // 需要缓存
    // }
  },
  {
    path:'/service_hall_page',
    name:'ServiceHallPage',
    component: ServiceHallPage
  },
  {
    path:'/topic_details_page/:id/:name',
    name:'topicDetailsPage',
    component: topicDetailsPage
  },
    //智能匹配点击后的详情页
  {
    path:'/intelligent_match/detail',
    name:'IntelligentMatchDetailPage',
    component: IntelligentMatchDetailPage
  },
    // 个人资料页面
  {
    path:'/my_info',
    name:'MyInfo',
    component: MyInfo
  },
  // 个人需求页面
  {
    path:'/my_demand',
    name:'MyDemand',
    component: MyDemand
  },
    // 个人专利页面
  {
    path:'/my_patent',
    name:'MyPatent',
    component: MyPatent
  },
    //个人项目
  {
    path:'/my_project',
    name:'MyProject',
    component: MyProject
  },
  //搜索结果页面
  {
    path:'/search_result_page/:searchKey',
    name:'SearchResult',
    component: SearchResult
  },
]

const router = new VueRouter({
  routes
})

// router.beforeEach((to, from, next) => {
//   if (from.name == 'PatentList' || from.name == 'DemandPage' || from.name == 'AchievementsPage') {
//     next({ name: 'Home' })
//     next()
//     // this.$router.push('/register')
//   }
//   else next()
// })


export default router
