<template>
  <div>
    <div v-if="!HamburgerBtnFlag">
      <el-row>
        <el-col :span="18" :offset="3">
          <!--    六大分类-->
          <div class="main-class">
            <div class="big-font">选择分类</div>
            <div class="main-box">
              <el-row>
                <el-col :span="3">
                  <el-button class="main-all-btn" @click="checkAllClass()">全部</el-button>
                </el-col>
                <el-col :span="21">
                  <el-row>
                    <el-col
                        :class="{active:item.id == isTypeActive}"
                        :span="3"
                        v-for="(item,index) in mainClass"
                        :key="index" class="main-item-box"
                    >
                      <el-button type="text" class="main-item-btn" @click="checkClass(item.children,item.id)">{{item.name}}</el-button>
                    </el-col>
                  </el-row>
                </el-col>
              </el-row>
            </div>
          </div>
          <!--    六大分类的子分类-->
          <div class="detail-class" :class="[moreFlag?(arrowBtnFlag?'hideMore':''):'']">
            <div class="detail-box" ref="childBox">
              <el-row>
                <el-col :span="3">
                  <el-button class="main-all-btn" @click="clickAllChildClass(childClass[0].pid)">全部</el-button>
                </el-col>
                <el-col :span="21">
                  <el-row>
                    <el-col
                        :span="3"
                        v-for="(item,index) in childClass"
                        :key="index" class="main-item-box"
                        :class="[(index+1)%7==0?'right-item':'',index == isSmallTypeActive?'active':'']"
                    >
                      <el-button type="text" class="main-item-btn" @click="clickChildClass(item.id,index,item.pid)">{{item.name}}</el-button>
                    </el-col>
                  </el-row>
                </el-col>
              </el-row>
            </div>
          </div>
          <!--    下拉按钮-->
          <div class="more-class" @click="moreChildClass" v-if="arrowBtnFlag">
            <i class="el-icon-arrow-down" v-if="moreFlag"></i>
            <i class="el-icon-arrow-up" v-else></i>
          </div>
          <div class="more-class" style="height: 20px" v-if="!arrowBtnFlag">

          </div>
          <!--    成熟度-->
          <div class="lightBlue-bg" v-if="limitsFlag.maturity">
            <div>
              <el-row>
                <el-col :span="3">
                  <div class="rest-class-font">成熟度：</div>
                </el-col>
                <el-col :span="21">
                  <el-row>
                    <el-col
                        :span="3"
                        v-for="(item,index) in maturity"
                        :key="index" class="main-item-box"
                        :class="{active:index == isMaturityActive}">
                      <el-button type="text" class="main-item-btn" @click="setMaturity(item.dictValue,index)">{{item.dictLabel}}</el-button>
                    </el-col>
                  </el-row>
                </el-col>
              </el-row>
            </div>
          </div>
          <!--    合作方式-->
          <div class="darkBlue-bg" v-if="limitsFlag.cooperation">
            <div>
              <el-row>
                <el-col :span="3">
                  <div class="rest-class-font">合作方式：</div>
                </el-col>
                <el-col :span="21">
                  <el-row>
                    <el-col
                        :span="3"
                        v-for="(item,index) in cooperation"
                        :key="index" class="main-item-box"
                        :class="{active:index == isCooperationActive}">
                      <el-button type="text" class="main-item-btn" @click="setCooperation(item.dictValue,index)">{{item.dictLabel}}</el-button>
                    </el-col>
                  </el-row>
                </el-col>
              </el-row>
            </div>
          </div>
          <!--    交易价格-->
          <div class="lightBlue-bg" v-if="limitsFlag.price">
            <div>
              <el-row>
                <el-col :span="3">
                  <div class="rest-class-font">交易价格：</div>
                </el-col>
                <el-col :span="21">
                  <el-row>
                    <el-col
                        :span="3"
                        v-for="(item,index) in amount"
                        :key="index" class="main-item-box"
                        :class="{active:index == isPriceActive}">
                      <el-button type="text" class="main-item-btn" @click="setPrice(item.startPrice,item.endPrice,index)">{{item.name}}</el-button>
                    </el-col>
                  </el-row>
                </el-col>
              </el-row>
            </div>
          </div>
          <!--    时间-->
          <div class="darkBlue-bg" v-if="limitsFlag.price">
            {{before_patent_is_market}}
          </div>
        </el-col>
      </el-row>

    </div>
    <div v-else>
      <el-tabs v-model="activeName">
        <el-tab-pane label="分类" name="classification">
          <div>
<!--            六大分类-->
            <div class="mini-classification-box">
              <el-row>
                <el-col :span="3" class="main-item-box-mini">
<!--                  <el-button class="" @click="checkAllClass()">全部</el-button>-->
                  <el-button type="text" class="main-all-btn-mini" @click="checkAllClass()">全部</el-button>
                </el-col>
                <el-col
                    :class="{active:item.id == isTypeActive}"
                    :span="3"
                    v-for="(item,index) in mainClass"
                    :key="index"
                    class="main-item-box-mini"
                >
                  <el-button type="text" class="main-item-btn-mini" @click="checkClass(item.children,item.id)">{{item.name}}</el-button>
                </el-col>
              </el-row>
            </div>
<!--            大分类中的具体分类-->
            <!--    六大分类的子分类-->
            <div class="detail-class-mini" :class="[moreFlag?(arrowBtnFlag?'hideMore':''):'']">
              <div class="detail-box" ref="childBox">
                <el-row>
                  <el-col
                      :span="4"
                      v-for="(item,index) in childClass"
                      :key="index" class="main-item-box"
                      :class="[index == isSmallTypeActive?'active':'']"
                  >
                    <el-button type="text" class="main-item-btn-mini" @click="clickChildClass(item.id,index,item.pid)">{{item.name}}</el-button>
                  </el-col>
                </el-row>
              </div>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="合作方式" name="transaction">
          <div class="darkBlue-bg-mini" v-if="limitsFlag.cooperation">
            <el-row>
              <el-col
                  :span="4"
                  v-for="(item,index) in cooperation"
                  :key="index" class="main-item-box"
                  :class="{active:index == isCooperationActive}">
                <el-button type="text" class="main-item-btn-mini" @click="setCooperation(item.dictValue,index)">{{item.dictLabel}}</el-button>
              </el-col>
            </el-row>
          </div>
        </el-tab-pane>
        <el-tab-pane label="成熟度" name="maturity"  v-show="maturity.length>0">
          <div class="darkBlue-bg-mini" v-if="limitsFlag.maturity">
            <div>
              <el-row>
                <el-col
                    :span="4"
                    v-for="(item,index) in maturity"
                    :key="index" class="main-item-box"
                    :class="{active:index == isMaturityActive}">
                  <el-button type="text" class="main-item-btn-mini" @click="setMaturity(item.dictValue,index)">{{item.dictLabel}}</el-button>
                </el-col>
              </el-row>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="交易价格" name="price">
          <div class="darkBlue-bg-mini" v-if="limitsFlag.price">
            <el-row>
              <el-col
                  :span="6"
                  v-for="(item,index) in amount"
                  :key="index" class="main-item-box"
                  :class="{active:index == isPriceActive}">
                <el-button type="text" class="main-item-btn-mini" @click="setPrice(item.startPrice,item.endPrice,index)">{{item.name}}</el-button>
              </el-col>
            </el-row>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>

export default {
  name: "Classification",
  props:['classType'],
  data() {
    return {
      moreFlag:true,
      mainClass:[],
      // 子分类
      childClass:[],
      arrowBtnFlag:false,
      maturity:[],
      cooperation:[],

      amount:[],
      //分类权限
      limitsFlag:{
        maturity:true,//成熟度
        cooperation:true,//合作方式
        price:true//交易价格
      },
      before_patent_is_market:'',
      isMaturityActive:-1,//成熟度选中样式flag
      isCooperationActive:-1,//合作方式选中样式flag
      isPriceActive:-1,//预算范围选中样式flag
      isTypeActive:-1,//大分类选中样式Flag
      isSmallTypeActive:-1,//小分类选中样式Flag
      RequestParameter:{
        pageSize:5,//页大小
        pageNum:1,//当前页
        // maturity:0,//成熟度
        // cooperation:0,//合作方式
        // startPrice:0,//起始价格
        // endPrice:99999999//结束价格
      },
      screenWidth: document.body.clientWidth,//浏览器宽度
      HamburgerBtnFlag:false,//是否显示汉堡按钮
      miniStyle:false,//手机样式
      activeName:'classification',
    }
  },
  beforeCreate() {
    // this.$nextTick(()=>{
    //   //获取本地交易价格字典
    //   this.amount = this.$store.state.amount;
    //   //获取合作方式子分类
    //   this.cooperation = this.$store.state.cooperation;
    //   //获取研发进度
    //   this.maturity = this.$store.state.maturity;
    // })
  },
  async created() {
    //获取所有的分类
    await this.getClassRequest();
    //如果是服务大厅
    if(this.classType == 'service') {
      this.limitsFlag.maturity = false;
      this.limitsFlag.cooperation = false;
      this.limitsFlag.price = false;
    }
    //如果是需求大厅
    if(this.classType == 'demand') {
      this.limitsFlag.maturity = false;
      this.limitsFlag.cooperation = false;
    }
    //获取本地交易价格字典
    this.amount = this.$store.state.amount;
    //获取合作方式子分类
    this.cooperation = this.$store.state.cooperation;
    //获取研发进度
    this.maturity = this.$store.state.maturity;

    //分类数据未准备好
    if(this.mainClass.length <= 0) {
      await this.$router.push('/');
    }
    //监控屏幕变化
    this.monitoringScreenWidth();
  },
  mounted() {
    const that = this
    window.addEventListener("resize", function() {
      return (() => {
        window.screenWidth= document.body.clientWidth;
        that.screenWidth= window.screenWidth;
      })();
    });
  },
  watch:{
    //监听浏览器宽度
    screenWidth() {
      this.monitoringScreenWidth();
    },
  },
  methods:{
    moreChildClass() {
      this.moreFlag = !this.moreFlag;
    },
    checkClass(childClass,id) {
      if(id == this.isTypeActive) {
        this.isTypeActive = -1;
        this.getAllChildClass();
        //移除属性
        this.$delete(this.RequestParameter,'typeId')
      }else {
        this.isTypeActive = id;
        this.childClass = childClass;
        this.getArrowFlag();
        //修改请求参数
        this.RequestParameter.typeId = id;
      }

      //请求专利列表
      this.getPatentList();
    },
    getAllChildClass() {
      this.childClass = [];
      this.mainClass.forEach(item => {
        this.childClass = this.childClass.concat(item.children)
      })
    },
    checkAllClass() {
      this.moreFlag = true;
      this.getAllChildClass();
      this.getArrowFlag();
      //所有选中的样式全部取消
      this.isMaturityActive = -1;
      this.isCooperationActive = -1;
      this.isPriceActive = -1;
      this.isTypeActive=-1;//大分类选中样式Flag
      this.isSmallTypeActive= -1;//小分类选中样式Flag
      //请求专利列表
      //参数回复为默认状态
      this.RequestParameter = {
        pageSize:5,//页大小
        pageNum:1,//当前页
      }
      this.getPatentList();
    },
    // 样式调整flag
    getArrowFlag() {
      if(this.childClass.length > 14) {
        this.arrowBtnFlag = true;
      }else {
        this.arrowBtnFlag = false;
      }
    },
    //设置搜索类型
    setCooperation(dictValue,index) {
      // console.log('选择的合作方式分类为' + dictValue)
      if(dictValue == this.RequestParameter.cooperation) {
        this.isCooperationActive = -1;
        //移除属性
        this.$delete(this.RequestParameter,'cooperation')
      }else {
        this.isCooperationActive = index;
        this.RequestParameter.cooperation = dictValue;
        //修改合作方式类型
        // if(dictValue == 0) {
        //   //点击了不限
        //   this.$delete(this.RequestParameter,'cooperation')
        // } else {
        //   this.RequestParameter.cooperation = dictValue;
        // }
      }
      // 触发分类请求
      this.getPatentList();
    },
    setMaturity(dictValue,index){
      if(dictValue == this.RequestParameter.maturity) {
        this.isMaturityActive = -1;
        //移除属性
        this.$delete(this.RequestParameter,'maturity')
      }else{
        // console.log('选择的成熟度分类为' + dictValue)
        this.isMaturityActive = index;
        this.RequestParameter.maturity = dictValue;
        // if(dictValue == 0) {
        //   this.$delete(this.RequestParameter,'maturity')
        // } else {
        //   this.RequestParameter.maturity = dictValue;
        // }
      }
      // 触发分类请求
      this.getPatentList();
    },
    setPrice(startPrice,endPrice,index) {
      if(index == this.isPriceActive) {
        this.isPriceActive = -1;
        //移除属性
        this.$delete(this.RequestParameter,'startPrice')
        this.$delete(this.RequestParameter,'endPrice')
      }else {
        this.isPriceActive = index
        if(index == 0) {
          //移除属性
          this.$delete(this.RequestParameter,'startPrice')
          this.$delete(this.RequestParameter,'endPrice')
        } else {
          this.RequestParameter.startPrice = startPrice;
          this.RequestParameter.endPrice = endPrice;
        }
      }
      // 触发分类请求
      this.getPatentList();
    },
    //向父组件申请触发分类请求函数
    getPatentList() {
      // console.log(this.RequestParameter)
      this.$emit('setRequestParameter',this.RequestParameter)
    },
    // 获取所有分类请求
    getClassRequest() {
        this.mainClass =  this.$store.state.untreatedType;
        this.getAllChildClass();
        this.getArrowFlag();

    },
    //点击子分类中的全部按钮
    clickAllChildClass(pid) {
      // console.log(pid)
      this.isSmallTypeActive= -1;//小分类选中样式Flag
      this.RequestParameter.typeId = pid;
      this.getPatentList();
    },
    //点击子分类中的某个分类按钮
    clickChildClass(id,index,pid) {
      if(index == this.isSmallTypeActive) {
        this.isSmallTypeActive = -1;
        //移除属性
        this.RequestParameter.typeId = pid;
        this.isTypeActive = pid;
      }else {
        this.isTypeActive = pid;
        this.isSmallTypeActive = index
        this.RequestParameter.typeId = id;
      }
      this.getPatentList();
    },
    //浏览器样式调整
    monitoringScreenWidth() {
      if(this.screenWidth < 1173) {
        this.HamburgerBtnFlag = true;
        if(this.screenWidth < 730) {
          this.miniStyle = true;
        }else {
          this.miniStyle = false;
        }
      }else {
        this.HamburgerBtnFlag = false;
        this.miniStyle = false;
      }
    }
  }
}
</script>

<style scoped>
  .main-class {
    background-color: #1C365F;
    color: #FFFFFF;
    padding: 20px;
    margin-top: 20px;
  }
  .mini-classification-box {
    background-color: #1C365F;
    font-size: 12px;
  }
  .big-font {
    font-size: 18px;
  }
  .main-box {
    margin-top: 8px;
  }
  .main-all-btn {
    background-color: #0F223B;
    color: #FFFFFF;
    border: none;
    border-radius: 0;
    font-size: 14px;
    width: 80%;
  }
  .main-all-btn-mini {
    background-color: #0F223B;
    color: #FFFFFF;
    border-radius: 0;
    text-align: center;
    font-size: 12px;
    width: 100%;
  }
  .main-item-box {
    text-align: center;
  }
  .main-item-box-mini {
    text-align: center;
  }
  .main-item-btn {
    color: #FFFFFF;
    font-size: 14px;
  }
  .main-item-btn-mini {
    color: #FFFFFF;
    font-size: 12px;
    text-align: center;
  }
  .detail-class {
    background-color: #2954A2;
    color: #FFFFFF;
    padding: 10px;
    padding-left: 20px;
  }
  .detail-class-mini {
    background-color: #2954A2;
    color: #FFFFFF;
  }
  .right-item {
    margin-right: 10px;
  }
  .more-class {
    background-color: #1C365F;
    color: #FFFFFF;
    font-size:16px;
    font-weight: bold;
    text-align: center;
  }

  .hideMore{
    overflow: hidden;
    height: 35px;
    /*width: 100%;*/
  }
  .lightBlue-bg {
    background-color: #2954A2;
    color: #FFFFFF;
    padding: 8px;
    /*margin-top: 30px;*/
  }

  .darkBlue-bg {
    background-color: #1C365F;
    color: #FFFFFF;
    padding: 10px;
  }
  .darkBlue-bg-mini {
    background-color: #1C365F;
    color: #FFFFFF;
  }
  .rest-class-font {
    font-size: 16px;
    line-height: 40px;
    margin-left: 10px;

  }
  .active {
    background-color: #0F223B;
    border-radius: 0px;
    color: #FFFFFF;
  }
</style>
