<template>
  <div>
<!--    我是服务大厅页面-->
    <el-row>
      <el-col :span="18" :offset="3">
<!--        问答列表-->
<!--        <AandQItem v-if="servicesList.length" :servicesList="servicesList" @updateList="getServicesList"></AandQItem>-->
        <AandQItem :servicesList="servicesList" @updateList="getServicesList"></AandQItem>
<!--        分页-->
        <Paging
            :total="pageTotal"
            :pageSize="parameterObj.pageSize"
            :currentPage="parameterObj.pageNum"
            @changeCurrent="changeCurrent"
            @changeSize="changeSize"
        ></Paging>
      </el-col>
    </el-row>
  </div>
</template>

<script>
// import Classification from "@/components/PatentPage/Classification";
import AandQItem from "@/components/ServiceHallPage/AandQItem";
import Paging from "@/components/Paging";
export default {
  name: "ServiceHallPage",
  components:{
    // Classification,
    AandQItem,
    Paging
  },
  data() {
    return {
      titleFront:'服务大厅',
      servicesList:[],
      pageTotal:0,//专利总条数
      parameterObj:{
        pageNum:1,
        pageSize:5
      }
    }
  },
  created() {
    this.getServicesList();
  },
  methods:{
    getServicesList() {
      this.getRequest('web/comment/list?pageNum='+this.parameterObj.pageNum+'&pageSize=' + this.parameterObj.pageSize).then(res=>{
        if(res.row.length) {
          res.row.forEach(item=>{
            item.addTime = this.parseTime( new Date(parseInt(item.addTime) * 1000),'{y}-{m}-{d}')
            if(item.childrenList.length) {
              item.childrenList.forEach(sub_Item=>{
                sub_Item.addTime = this.parseTime(new Date(parseInt(sub_Item.addTime) * 1000),'{y}-{m}-{d}')
              })
            }
          })
          this.servicesList = res.row;
          this.pageTotal = res.totalCount;
          this.parameterObj.pageNum = res.pageNum;
          this.parameterObj.pageSize = res.pageSize;
        }
      })
    },
    //请求另一页的数据
    changeCurrent(currentPage) {
      this.parameterObj.pageNum = currentPage;
      this.getServicesList();
    },
    //改变当前每页的数量
    changeSize(value) {
      this.parameterObj.pageSize = value;
      this.parameterObj.pageNum = 1;
      this.getServicesList();
    },
    //时间转化
    parseTime(time, pattern) {
      if (arguments.length === 0 || !time) {
        return null
      }
      const format = pattern || '{y}-{m}-{d} {h}:{i}:{s}'
      let date
      if (typeof time === 'object') {
        date = time
      } else {
        if ((typeof time === 'string') && (/^[0-9]+$/.test(time))) {
          time = parseInt(time)
        } else if (typeof time === 'string') {
          time = time.replace(new RegExp(/-/gm), '/').replace('T', ' ').replace(new RegExp(/\.[\d]{3}/gm),'');
        }
        if ((typeof time === 'number') && (time.toString().length === 10)) {
          time = time * 1000
        }
        date = new Date(time)
      }
      const formatObj = {
        y: date.getFullYear(),
        m: date.getMonth() + 1,
        d: date.getDate(),
        h: date.getHours(),
        i: date.getMinutes(),
        s: date.getSeconds(),
        a: date.getDay()
      }
      const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
        let value = formatObj[key]
        // Note: getDay() returns 0 on Sunday
        if (key === 'a') { return ['日', '一', '二', '三', '四', '五', '六'][value] }
        if (result.length > 0 && value < 10) {
          value = '0' + value
        }
        return value || 0
      })
      return time_str
    }
  }
}
</script>

<style scoped>

</style>
