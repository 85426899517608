import Vue from "vue"
import Vuex from "vuex"

Vue.use(Vuex);

export default new Vuex.Store({
    //state是自定义的一些变量，需要用来保存数据
    state:{
        cooperation: [
            {
                searchValue: null,
                createBy: null,
                createTime: null,
                updateBy: null,
                updateTime: null,
                remark: null,
                params: {},
                dictCode: 111,
                dictSort: 0,
                dictLabel: "不限",
                dictValue: "0",
                dictType: "before_patent_cooperation_type",
                cssClass: null,
                listClass: "default",
                isDefault: "N",
                status: "0"
            },
            {
                searchValue: null,
                createBy: null,
                createTime: null,
                updateBy: null,
                updateTime: null,
                remark: null,
                params: {},
                dictCode: 112,
                dictSort: 1,
                dictLabel: "完全转让",
                dictValue: "1",
                dictType: "before_patent_cooperation_type",
                cssClass: null,
                listClass: "default",
                isDefault: "N",
                status: "0"
            },
            {
                searchValue: null,
                createBy: null,
                createTime: null,
                updateBy: null,
                updateTime: null,
                remark: null,
                params: {},
                dictCode: 113,
                dictSort: 2,
                dictLabel: "许可转让",
                dictValue: "2",
                dictType: "before_patent_cooperation_type",
                cssClass: null,
                listClass: "default",
                isDefault: "N",
                status: "0"
            },
            {
                searchValue: null,
                createBy: null,
                createTime: null,
                updateBy: null,
                updateTime: null,
                remark: null,
                params: {},
                dictCode: 114,
                dictSort: 3,
                dictLabel: "技术入股",
                dictValue: "3",
                dictType: "before_patent_cooperation_type",
                cssClass: null,
                listClass: "default",
                isDefault: "N",
                status: "0"
            },
            {
                searchValue: null,
                createBy: null,
                createTime: null,
                updateBy: null,
                updateTime: null,
                remark: null,
                params: {},
                dictCode: 115,
                dictSort: 4,
                dictLabel: "合作生产",
                dictValue: "4",
                dictType: "before_patent_cooperation_type",
                cssClass: null,
                listClass: "default",
                isDefault: "N",
                status: "0"
            }
        ],//合作方式字典
        maturity: [
            // {
            //     searchValue: null,
            //     createBy: null,
            //     createTime: null,
            //     updateBy: null,
            //     updateTime: null,
            //     remark: null,
            //     params: {},
            //     dictCode: 107,
            //     dictSort: 0,
            //     dictLabel: "不限",
            //     dictValue: "0",
            //     dictType: "before_patent_maturity",
            //     cssClass: null,
            //     listClass: "default",
            //     isDefault: "N",
            //     status: "0"
            // },
            // {
            //     searchValue: null,
            //     createBy: null,
            //     createTime: null,
            //     updateBy: null,
            //     updateTime: null,
            //     remark: null,
            //     params: {},
            //     dictCode: 108,
            //     dictSort: 1,
            //     dictLabel: "正在研发",
            //     dictValue: "1",
            //     dictType: "before_patent_maturity",
            //     cssClass: null,
            //     listClass: "default",
            //     isDefault: "N",
            //     status: "0"
            // },
            // {
            //     searchValue: null,
            //     createBy: null,
            //     createTime: null,
            //     updateBy: null,
            //     updateTime: null,
            //     remark: null,
            //     params: {},
            //     dictCode: 109,
            //     dictSort: 2,
            //     dictLabel: "已有样品",
            //     dictValue: "2",
            //     dictType: "before_patent_maturity",
            //     cssClass: null,
            //     listClass: "default",
            //     isDefault: "N",
            //     status: "0"
            // },
            // {
            //     searchValue: null,
            //     createBy: null,
            //     createTime: null,
            //     updateBy: null,
            //     updateTime: null,
            //     remark: null,
            //     params: {},
            //     dictCode: 110,
            //     dictSort: 3,
            //     dictLabel: "可以量产",
            //     dictValue: "3",
            //     dictType: "before_patent_maturity",
            //     cssClass: null,
            //     listClass: "default",
            //     isDefault: "N",
            //     status: "0"
            // }
        ],//成熟度字典
        amount:[
            {
                name:'不限',
                startPrice:0,
                endPrice:99999999
            },
            {
                name:'面议',
                startPrice:-1,
                endPrice:-1
            },
            {
                name:'1-10万',
                startPrice:1,
                endPrice:10
            },
            {
                name:'10-20万',
                startPrice:10,
                endPrice:20
            },
            {
                name:'20-100万',
                startPrice:20,
                endPrice:100
            },
            {
                name:'100-500万',
                startPrice:100,
                endPrice:500
            },
            {
                name:'500万-1000万',
                startPrice:500,
                endPrice:1000
            },
            {
                name:'1000万以上',
                startPrice:1000,
                endPrice:99999999
            }
        ],//交易价格字典
        type:[],//分类字典
        untreatedType:[],//未处理的分类字典
        patentLevel:[],//专利级别字典
        user:{},//用户对象数据
        unitDict:[],//单位字典
        marketDict:[],//是否市场化字典
        genderDict:[],//性别字典
        companyDict:[],//合作公司字典
        schoolDict:[],//学校字典
        patentTypeDict:[],//专利类型字典

    },
    //mutations是用来触发事件，相当于方法，用户需要通过触发这个方法，借此来保存数据，
    // 参数:第二个参数就是用户传入的值，然后在方法中赋值给state中的变量
    mutations:{
        // 保存合作方式字典
        saveCooperation(state,cooperation){
            state.cooperation = cooperation;
        },
        // 保存成熟度字典
        saveMaturity(state,maturity){
            state.maturity = maturity;
        },
        // 保存交易价格字典
        saveAmount(state,amount){
            state.amount = amount;
        },
        //保存分类字典
        saveType(state,type) {
            state.type = type;
        },
        //保存未处理的分类字典
        saveUntreatedType(state,untreatedType) {
            state.untreatedType = untreatedType;
        },
        savePatenLevel(state,patentLevel) {
            state.patentLevel = patentLevel;
        },
        //保存用户数据
        saveUser(state,user) {
            state.user = user;
        },
        //删除user
        deleteUser(state) {
            delete state.user
        },
        //单位字典
        saveUnitDict(state,unitDict) {
            state.unitDict = unitDict;
        },
        //保存是否市场化字典
        saveIsMarketDict(state,marketDict) {
            state.marketDict = marketDict;
        },
        //保存性别字典
        saveGenderDict(state,genderDict){
            state.genderDict = genderDict;
        },
        //合作公司字典
        saveCompanyDict(state,companyDict){
            state.companyDict = companyDict;
        },
        //学校字典
        saveSchoolDict(state,schoolDict) {
            state.schoolDict = schoolDict;
        },
        //专利类型字典
        savePatentTypeDict(state,patentTypeDict) {
            state.patentTypeDict = patentTypeDict;
        }
    }
})
