import axios from 'axios';
import {Message} from "element-ui";
import router from "../router";
/**
 * 错误消息统一显示方法
 * 封装请求方法，只负责提示错误信息，如果失败返回空值null，如果成功，返回后端接口传输的数据
 */
// const that = this;
axios.interceptors.response.use(success=>{
    //如果返回服务端自定义异常
    if (success.status && success.status === 200 && success.data.status === 500) {
        Message.error({message: success.data.message})
        return;
    }
    //如果存在自定义属性message，则打印出来
    if (success.data.message) {
        Message.success({message: success.data.message})
    }
    //请求200 ， 服务端自定义属性status200 , 没有相应信息 , 则直接返回数据
    return success.data;

} , error => {
    if (error.response.status===504||error.response.status===404){
        Message.error({message: "服务器被吃了( ╯□╰ )"})
    }else if (error.response.status === 403) {
        Message.error({message: "权限不足哦"})
    }else if (error.response.status === 401) {
        //注销登录
        logoutReq();
        Message.error({message: "您的登录已过期，请重新登录哦亲"});
        router.replace("/login");
        //未知的请求错误
    }else {
        if (error.response.data.message) {
            Message.error({message: error.response.data.message})
        } else {
            Message.error({message: '未知错误!'})
        }
    }
    //返回空值代表有错误
    return ;
});

//注销登录请求
function logoutReq() {
    this.getRequest('web/logout').then(res=>{
        if(res.code == 200) {
            this.$message({
                message:res.msg,
                type:'info'
            })
        }
    })
}
//定义url前缀
let base = '';

//采用key_value形式传递参数
export const postKeyValueRequest = (url, params)=>{
    return axios({
        method: 'post',
        url: `${base}${url}`,
        data: params,
        //请求参数转化
        transformRequest: [function (data) {
            let ret = '';//ret存储，url所有的参数
            for (let i in data) {//i代表每个键
                ret += encodeURIComponent(i) + '=' + encodeURIComponent(data[i]) + '&'
            }
            //
            return ret;
        }],
        headers: {
            'Content-Type':'application/x-www-form-urlencoded'
        }
    });
};

export const  postRequest = (url,params)=>{
    return axios({
        method: 'post',
        url : `${base}${url}`,
        data: params
    });
};

export const putRequest = (url,params)=>{
    return axios({
        method: 'put',
        url : `${base}${url}`,
        data: params
    });
};

export const  getRequest = (url,params)=>{
    return axios({
        method: 'get',
        url : `${base}${url}`,
        data: params
    });
};

export const  getRequestJson = (url,params)=>{
    return axios({
        method: 'get',
        url : `${base}${url}`,
        data: params,
        responseType:'json'

    });
};
export const  deleteRequest = (url,params)=>{
    return axios({
        method: 'delete',
        url : `${base}${url}`,
        data: params
    });
};
//所属分类名称请求封装
export function getTypeName(typeId) {
    let typeName = '';
    let typeList = this.$store.state.type;
    let typeObj = typeList.find(function(ele){
        return ele.id == typeId
    },typeId)
   if(typeObj) {
       typeName = typeObj.name;
       if(typeObj.pid != 0) {
           let typeSubObj = typeList.find(function(ele){
               return ele.id == typeObj.pid
           },typeObj.pid)
           typeName = typeSubObj.name + '/' + typeName;
       }
   }
    return typeName
}
//获取成熟度名称
export function getMaturityName(maturityId) {
    let maturityList = this.$store.state.maturity;
    let maturityObj = maturityList.find(function(ele){
        return ele.dictValue == maturityId
    },maturityId)
    if(maturityObj) {
        return maturityObj.dictLabel
    }
}
//获取合作方式名称
export function geCooperationName(cooperationId) {
    let cooperationList = this.$store.state.cooperation;
    let cooperationObj = cooperationList.find(function(ele){
        return ele.dictValue == cooperationId
    },cooperationId)
    if(cooperationObj) {
        return cooperationObj.dictLabel
    }
}
//获取合作方式名称
export function getPatentLevelName(patentLevelId) {
    let patentLevelList = this.$store.state.patentLevel;
    let patentLevelObj = patentLevelList.find(function(ele){
        return ele.dictValue == patentLevelId
    },patentLevelId)
    if(patentLevelObj) {
        return patentLevelObj.dictLabel
    }
}
//获取专利类型名称
export function getPatentTypeName(patentType) {
    let patentTypeList = this.$store.state.patentTypeDict;
    let patentTypeObj = patentTypeList.find(function(ele){
        return ele.dictValue == patentType
    },patentType)
    if(patentTypeObj) {
        return patentTypeObj.dictLabel
    }
}
//封装关于cookie的方法
// //获取cookie、
// export function getCookie(name) {
//     let arr,reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
//     if (arr == document.cookie.match(reg))
//         return (arr[2]);
//     else
//         return null;
// }
//
// //设置cookie,增加到vue实例方便全局调用
// export function setCookie (c_name, value, expiredays) {
//     let exdate = new Date();
//     exdate.setDate(exdate.getDate() + expiredays);
//     document.cookie = c_name + "=" + escape(value) + ((expiredays == null) ? "" : ";expires=" + exdate.toGMTString());
// }
//
// //删除cookie
// export function delCookie (name) {
//     let exp = new Date();
//     exp.setTime(exp.getTime() - 1);
//     let cval = getCookie(name);
//     if (cval != null)
//         document.cookie = name + "=" + cval + ";expires=" + exp.toGMTString();
// }


