<template>
  <div class="bg-color">
<!--    顶部图片-->
    <img :class="[miniStyle?'topImage-mini':'topImage']" src="../assets/page_top_bg.png" alt="">
<!--    主题名称-->
    <div :class="[miniStyle?'topic-tt-mini':'topic-tt']">
      {{ title }}
    </div>
    <div>
      <el-row>
        <el-col :xs="{span:22,offset:1}" :sm="{span:18,offset:4}">
          <!--    主题导读-->
          <el-card class="guide-card">
            <el-row>
              <el-col :span="2">
                <!--      导读标签-->
                <div class="guide-card-tag">
                  <el-tag type="warning" v-if="!miniStyle" effect="dark">导读</el-tag>
                  <el-tag type="warning" v-else size="mini" effect="dark">导读</el-tag>
<!--                  导读-->
                </div>
              </el-col>
              <el-col :span="22">
                <!--      导读内容-->
                <div :class="[miniStyle?'guide-card-tx-mini':'guide-card-tx']">
                  {{topicIntoMsg.guideText}}
                </div>
              </el-col>
            </el-row>
          </el-card>
<!--          主题介绍-->
          <TopicIntroduce :topicIntoMsg="topicIntoMsg"></TopicIntroduce>
<!--          主题相关专利-->
<!--          标题-->
          <ModuleTitle :title="title + '专利推荐'"></ModuleTitle>
          <TopicPatentRecommend :patentList="patentList"></TopicPatentRecommend>
<!--          主题相关需求-->
<!--          标题-->
          <ModuleTitle :title="title + '需求推荐'"></ModuleTitle>
          <TopicDemandRecommend :demandList="demandList"></TopicDemandRecommend>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import TopicIntroduce from "@/components/TopicDetailPage/TopicIntroduce";
import ModuleTitle from "@/components/HomePage/ModuleTitle";
import TopicPatentRecommend from "@/components/TopicDetailPage/TopicPatentRecommend";
import TopicDemandRecommend from "@/components/TopicDetailPage/TopicDemandRecommend";
// import Security01 from "../assets/special/Security01.png"
// import Security02 from "../assets/special/Security02.png"
// import communication01 from "../assets/special/communication01.png"
// import communication02 from "../assets/special/communication02.png"
// import Provide01 from "../assets/special/Provide01.png"
// import Provide02 from "../assets/special/Provide02.png"
// import chip01 from "../assets/special/chip01.png"
// import chip02 from "../assets/special/chip02.png"
// import medicalCare01 from "../assets/special/medicalCare01.png"
// import medicalCare02 from "../assets/special/medicalCare02.png"
// import intelligence01 from "../assets/special/intelligence01.png"
// import intelligence02 from "../assets/special/intelligence02.png"
export default {
  name: "topicDetailsPage",
  components:{
    TopicIntroduce,
    ModuleTitle,
    TopicPatentRecommend,
    TopicDemandRecommend
  },
  data() {
    return {
      title:'',
      topicIntoMsg:{
        guideText:'',
        summaryText:'',
      },
      typeId:null,
      patentList:[],//专利推荐列表
      demandList:[],//需求推荐列表
      topicMsgList:[
        {
          id:1,
          name:'智能设备',
          // img_1:intelligence01,
          // img_2:intelligence02,
          guideText:'智能设备（intelligent device）是指任何一种具有计算处理能力的设备、器械或者机器。功能完备的智能设备必须具备灵敏准确的感知功能、正确的思维与判断功能以及行之有效的执行功能。',
          summaryText:'智能设备是传统电气设备与计算机技术、数据处理技术、控制理论、传感器技术、网络通信技术、电力电子技术等相结合的产物。智能设备主要包括两方面的关键内容：自我检测是智能设备的基础；自我诊断是智能设备的核心。智能设备是一种高度自动化的机电一体化设备，由于其结构复杂，在系统中的作用十分重要，因此对智能设备的可靠性有很高的要求。元器件的可靠性、技术设计、工艺水平和技术管理等共同决定了电子产品的可靠性指标。提高产品的可靠性',
          difficulties:'人工智能虽然近年取得快速发展，但是在不少方面上距离成熟应用尚存在技术瓶颈，过高的期待和不负责任的炒作导致产业泡沫。滥用人工智能应用也带来道德、伦理上风险。人工智能没有意识更不会有道德判断。人工智能技术的垄断造成新的“数字鸿沟”。'
        },
        {
          id:2,
          name:'芯片设备',
          // img_2: chip02,
          // img_1: chip01,
          guideText:'半导体元件产品的统称',
          summaryText:'芯片技术是一项新兴产业，主要分有基因芯片技术、倒装芯片技术、生物芯片技术、组织芯片技术、蛋白质芯片技术、蛋白芯片技术、DNA芯片技术、液相芯片技术、芯片封装技术。',
          difficulties:'中国芯要真正崛起，至少还有三个方面还是需要“补补课”，这也是中国必须重视且崛起的重要技术。第一是架构，目前基本上所有的芯片都是基于英特尔的X86和AMR的架构来开发的，中国芯也基本上按照这两个架构指定的路在走。中国想要拥有自主研发的芯片且不受国外厂商的遏制，拥有独立知识产权的自主构架将十分关键。这也是目前开源的RISC-V架构火爆的原因，因为这个开源的架构摆脱了英特尔和ARM指定的路。第二是材料课，别看芯片似乎只涉及到设计、制造、封测等环节，但在制造的过程中，需要用到的高纯度的硅，靶材等基本材料。在材料方面，中国技术还是很初级，很多高精材料完全依靠进口，中国芯要崛起，材料行业必须抓起来，这是制造之根本。第三课是关键设备，这个最简单的例子就是光刻机了，目前国内能生产5nm的刻蚀机，但光刻机的技术还停留在28nm阶段，高精度的光刻机必须要进口，而全球最牛的ASML的光刻机不是你想买就有得买的。'
        },
        {
          id:3,
          name:'医疗设备',
          // img_2: medicalCare02,
          // img_1: medicalCare01,
          guideText:'医疗技术，是指医疗机构及其医务人员以诊断和治疗疾病为目的，对疾病作出判断和消除疾病、缓解病情、减轻痛苦、改善功能、延长生命、帮助患者恢复健康而采取的诊断、治疗措施。',
          summaryText:'第一类医疗技术是指安全性、有效性确切，医疗机构通过常规管理在临床应用中能确保其安全性、有效性的技术。\n' +
              '第二类医疗技术是指安全性、有效性确切，涉及一定伦理问题或者风险较高，卫生行政部门应当加以控制管理的医疗技术。\n' +
              '第三类医疗技术是指具有下列情形之一，需要卫生行政部门加以严格控制管理的医疗技术：\n' +
              '（一）涉及重大伦理问题；\n' +
              '（二）高风险；\n' +
              '（三）安全性、有效性尚需经规范的临床试验研究进一步验证；\n' +
              '（四）需要使用稀缺资源；\n' +
              '（五）卫生部规定的其他需要特殊管理的医疗技术。',
          difficulties:'医疗这个门槛高、环节多、问题复杂的行业，也是与人工智能的结合、应用中最为特殊的领域。医生的角色、医院的管理、医疗数据的获取与使用等都是在技术推进中存在着难题。'
        },
        {
          id:4,
          name:'通讯设备',
          // img_1: communication01,
          // img_2: communication02,
          guideText:'移动通信网络发展迅速，它从各个领域为人们提供了便捷的通讯服务，也不断地在改变着人们的生活方式。',
          summaryText:'通信技术，又称通信工程（也作信息工程、电信工程，旧称远距离通信工程、弱电工程）是电子工程的重要分支，同时也是其中一个基础学科。该学科关注的是通信过程中的信息传输和信号处理的原理和应用。通信工程研究的是，以电磁波、声波或光波的形式把信息通过电脉冲，从发送端 （信源）传输到一个或多个接受端（信宿）。接受端能否正确辨认信息，取决于传输中的损耗功率高低。信号处理是通信工程中一个重要环节，其包括过滤，编码和解码等。专业课程包括计算机网络基础、电路基础、通信系统原理、交换技术、无线技术、计算机通信网、通信电子线路、数字电子技术、光纤通信等。',
          difficulties:'通信工程研究的是，以电磁波、声波或光波的形式把信息通过电脉冲，从发送端 （信源）传输到一个或多个接受端（信宿）。接受端能否正确辨认信息，取决于传输中的损耗功率高低。信号处理是通信工程中一个重要环节，其包括过滤，编码和解码等。'
        },
        {
          id:5,
          name:'安防设备',
          // img_1:Security01,
          // img_2:Security02,
          guideText:'安全防范技术主要学习安全管理、安防工程、安防设备使用与维护等方面的基本知识和技能，主要包括视频监控技术，报警信息传输技术，移动目标反劫、防盗报警技术，防爆案件技术、实体防护技术、 入侵探测与防盗报警技术等。',
          summaryText:'安全技术防范是以安全防范技术为先导，以人力防范为基础，以技术防范和实体防范为手段，为建立具有探测、延迟、反应基本功能并使其有效结合的综合安全防范服务保障体系而进行的活动。它是预防损失和预防犯罪为目的的一项公安业务和社会经济产业。预防损失和预防犯罪的具体内容主要包括：预防入侵、盗窃、抢劫、破坏、爆炸违法犯罪活动和重大治安事故。',
          difficulties:'数据壁垒导致的信息孤岛仍然存在。为了方便追踪目标，智能安防系统还要提高平台的开放性，打通平台之间的数据壁垒，进行数据和信息共享。对于不同应用场景的理解，制约垂直领域的深度探索。一些以前无法落地的技术已经实现了应用落地，并孵化除了成熟的产品与解决方案。但这也是建立在对行业应用有深度理解的基础之上的，通过对应用场景的不断研究，才能实现相关技术的发展。业内今后将更加关注如何实现技术与应用场景的结合。依托算法等技术进入智能安防领域已经是一种潮流。这促进了整个行业技术的发展与产品的变革，但怎样才能将多种单点技术融合成为满足用户需求的落地产品，提高产业应用效能及价值，是行业内的众多企业亟待解决的问题。'
        },
        {
          id:6,
          name:'养老设备',
          // img_1: Provide01,
          // img_2: Provide02,
          guideText:'智慧养老是面向居家老人、社区及养老机构的传感网系统与信息平台，并在此基础上提供实时、快捷、高效、低成本的，物联化、互联化、智能化的养老服务。',
          summaryText:'随着科技进步，新型养老方式日趋流行,社会上也涌现出一系列如只为父母设计的电视盒子等高科技产品，提升老人的晚年生活质量，最大程度的解决空巢老人寂寞的问题，是智慧养老，候鸟式养老，信息化养老，中国式养老的新形式。智慧养老经过一年多的良好运营与快速成长，获得了政府、行业、公众及媒体的广泛关注与认可。让老人充分享受物联网带来的便捷和舒适。',
          difficulties:'截至去年底，我国60岁以上老年人口有2.49亿。面对庞大的老年人群体，我国在养老资源的供给上呈现不足态势，难以满足人们对养老日益增长的需求。中国老龄协会副会长吴玉韶认为，互联网、人工智能、5G等技术的发展，为传统养老转型升级带来机遇。智慧养老将突破传统养老在居家照顾、出行、安全保护、健康管理、精神关爱五方面的难点。吴玉韶说：“居家照顾用的人力成本最高。通过智慧养老之后，一部分的居家照顾能够通过智慧养老实现替代。未来，无人驾驶和智能辅具实现以后，老年人出行便利度和无障碍的程度将大幅度提高。因为独居空巢是未来大趋势，智慧养老特别是VR技术、远程陪伴等，应当可以部分缓解精神关爱的问题。”'
        },
      ],
      screenWidth: document.body.clientWidth,//浏览器宽度
      miniBtnFlag:false,//专利操作按钮
      HamburgerBtnFlag:false,//是否显示汉堡按钮
      miniStyle:false,//手机样式
     }
  },
  created() {
    let that  = this;
    this.typeId = this.$route.params.id;
    this.topicIntoMsg = this.topicMsgList.find(function(ele){
     return  ele.id == that.typeId
    })
    this.getPatentRecommendList();
    this.getDemandRecommendList();
    //监控屏幕变化
    this.monitoringScreenWidth();
  },
  mounted() {
    this.title = this.$route.params.name + '设备';
    const that = this
    window.addEventListener("resize", function() {
      return (() => {
        window.screenWidth= document.body.clientWidth;
        that.screenWidth= window.screenWidth;
      })();
    });
  },
  watch:{
    //监听浏览器宽度
    screenWidth() {
      this.monitoringScreenWidth();
    }
  },
  methods:{
    //查询专利推荐
    getPatentRecommendList() {
      let url = 'web/patent/list?pageSize=4&pageNum=1&typeId=' + this.typeId;
      this.getRequest(url).then(res=>{
        if(res.row.length) {
          this.patentList = res.row;
        }
      })
    },
    getDemandRecommendList() {
      let url = 'web/demand/list?pageSize=4&pageNum=1&typeId=' + this.typeId;
      this.getRequest(url).then(res=>{
        if(res.row.length) {
          this.demandList = res.row;
        }
      })
    },
    //浏览器样式调整
    monitoringScreenWidth() {
      // xs 	<768px
      // sm 	≥768px
      // md 	≥992px
      // lg 	≥1200px
      // xl 	≥1920px
      if(this.screenWidth < 1058) {
        this.HamburgerBtnFlag = true;
      }else {
        this.HamburgerBtnFlag = false;
      }
      if(this.screenWidth < 944) {
        this.miniStyle = true;
      }else {
        this.miniStyle = false;
      }
    }
  }
}

</script>

<style scoped>
.bg-color {
  background-color: #efefef;
}
.topImage {
  width: 100%;
  height: 160px;
  position: relative;
}
.topImage-mini {
  width: 100%;
  height: 130px;
  position: relative;
  top: -22px;
}
.topic-tt {
  font-size: 30px;
  font-family: 微软雅黑;
  color: #DCD6D6;
  font-weight: bold;
  position: absolute;
  top: 180px;
  left: 50%;
  transform: translate(-50%);
}
.topic-tt-mini {
  font-size: 20px;
  font-family: 微软雅黑;
  color: #DCD6D6;
  font-weight: bold;
  position: absolute;
  top: 150px;
  left: 50%;
  transform: translate(-50%);
}
.guide-card {
  transform: translateY(-50%);
}
.guide-card-tag {
}
.guide-card-tx {
  background-color: #efefef;
  color: #666666;
  font-family: 微软雅黑;
  font-size: 14px;
  padding: 10px;
}
.guide-card-tx-mini {
  background-color: #efefef;
  color: #666666;
  font-family: 微软雅黑;
  font-size: 12px;
  padding: 8px;
  margin-left: 12px;
}


</style>
