<template>
  <div>
<!--    专利政策页面-->
    <!--        顶部大图-->
    <TopImage :titleFront="titleFront" :titleAfter="titleAfter"></TopImage>
    <el-row>
      <el-col :span="18" :offset="3">
<!--        政策法规-->
        <ModuleTitle title="政策法规"></ModuleTitle>
        <PolicyRegulations :policyRegulationData="policyRegulationData"></PolicyRegulations>
<!--        分页-->
        <Paging
            :total="pageTotal"
            :pageSize="parameterObj.pageSize"
            :currentPage="parameterObj.pageNum"
            @changeCurrent="changeCurrent"
            @changeSize="changeSize"
        ></Paging>
<!--        政策解读-->
<!--        <ModuleTitle title="政策解读"></ModuleTitle>-->
<!--        <PolicyInterpretation :policyInterpretationData="policyInterpretationData"></PolicyInterpretation>-->
      </el-col>
    </el-row>

  </div>
</template>

<script>
import TopImage from "@/components/PolicyPage/TopImage";
import ModuleTitle from "@/components/HomePage/ModuleTitle";
import PolicyRegulations from "@/components/PolicyPage/PolicyRegulations";
// import PolicyInterpretation from "@/components/PolicyPage/PolicyInterpretation";
import Paging from "@/components/Paging";
export default {
  name: "PolicyPage",
  components:{
    TopImage,
    ModuleTitle,
    PolicyRegulations,
    // PolicyInterpretation,
    Paging
  },
  data() {
    return {
      titleFront:'政策精准对接',
      titleAfter:'倾力专利服务',
      policyRegulationData:[],
      pageTotal:0,//专利总条数
      parameterObj:{
        pageNum:1,
        pageSize:5
      }
    }
  },
  created() {
    this.getPolicyList();
  },
  methods:{
    getPolicyList() {
      this.getRequest('web/policy/list?pageNum='+this.parameterObj.pageNum+'&pageSize='+this.parameterObj.pageSize).then(res=>{
        this.policyRegulationData = res.row;
        this.pageTotal = res.totalCount;
        this.parameterObj.pageNum = res.pageNum;
        this.parameterObj.pageSize = res.pageSize;
      })
    },
    //请求另一页的数据
    changeCurrent(currentPage) {
      this.parameterObj.pageNum = currentPage;
      this.getPolicyList();
    },
    //改变当前每页的数量
    changeSize(value) {
      this.parameterObj.pageSize = value;
      this.parameterObj.pageNum = 1;
      this.getPolicyList();
    },
  }
}
</script>

<style scoped>
</style>
