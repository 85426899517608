<template>
  <div class="rotationChart-box">
<!--    轮播图-->
    <el-carousel class="rotation-box" indicator-position="none">
      <el-carousel-item>
        <el-image
            :class="[miniStyle?'rotation-img-small':'rotation-img-big']"
            :src="require('@/assets/earth.png')"
        ></el-image>
      </el-carousel-item>
      <el-carousel-item>
        <el-image
            :class="[miniStyle?'rotation-img-small':'rotation-img-big']"
            :src="require('@/assets/code.png')"
        ></el-image>
      </el-carousel-item>
      <el-carousel-item>
        <el-image
            :class="[miniStyle?'rotation-img-small':'rotation-img-big']"
            :src="require('@/assets/light.png')"
        ></el-image>
      </el-carousel-item>
    </el-carousel>
<!--    六大模块极其图标-->
    <div class="topic-icon-box" v-if="classification.length" >
      <el-row>
        <el-col :xs="{span:24,offset:0}" :sm="{span:18,offset:3}">
          <ul class="classification-ul">
            <el-row>
              <el-col :span="4" v-for="(item,index) in classification" :key="index">
                <li
                    :class="[HamburgerBtnFlag?'classification-li-mini':'classification-li',index != classification.length-1 ? 'border-right':'']"
                    @click="goTopicPage(item)">
                  <div>
                    <i :class="[item.icon,HamburgerBtnFlag?'classification-icon-mini':'classification-icon']"></i>
                    <div :class="[HamburgerBtnFlag?'classification-tx-mini':'classification-tx']">{{item.name}}</div>
                  </div>
                </li>
              </el-col>
            </el-row>
          </ul>
        </el-col>
      </el-row>
    </div>
    <div v-if="miniStyle" class="search-btn-box">
      <el-input class="search-input"  placeholder="请输入关键字" v-model="searchKey" prefix-icon="el-icon-search" clearable>
        <el-button class="search-btn" slot="append"  icon="el-icon-search" @click="searchRelevant()">搜索</el-button>
      </el-input>
    </div>

  </div>
</template>

<script>
export default {
  name: "RotationChart",
  data() {
    return {
      classification:[
        {
          icon:'el-icon-setting',
          name:'智能',
          id:1
        },
        {
          icon:'el-icon-set-up',
          name:'芯片',
          id:2
        },
        {
          icon:'el-icon-first-aid-kit',
          name:'医疗',
          id:3
        },
        {
          icon:'el-icon-mobile-phone',
          name:'通讯',
          id:4
        },
        {
          icon:'el-icon-help',
          name:'安防',
          id:5
        },
        {
          icon:'el-icon-user',
          name:'养老',
          id:6
        },
      ],
      screenWidth: document.body.clientWidth,//浏览器宽度
      HamburgerBtnFlag:false,//是否显示汉堡按钮
      miniStyle:false,//手机样式
      miniClassification:false,//最小样式，控制手机分类
      searchKey:"",
      imgWidth:''

    }
  },
  created() {
    this.monitoringScreenWidth();
  },
  mounted() {
    let obj = this.$store.state.untreatedType;
    obj.forEach((item,index)=>{
      if(index < 6) {
        this.classification[index] = item;
      }
    })
    const that = this
    window.addEventListener("resize", function() {
      return (() => {
        window.screenWidth= document.body.clientWidth;
        that.screenWidth= window.screenWidth;
      })();
    });
  },
  methods: {
    handleSelect() {

    },
    goTopicPage(item) {
      this.$router.push({
        path: `/topic_details_page/${item.id}/${item.name}`,
      })
    },
    //浏览器样式调整
    monitoringScreenWidth() {
      // console.log(this.screenWidth)
      if(this.screenWidth < 1173) {
        this.HamburgerBtnFlag = true;
        if(this.screenWidth < 730) {
          this.miniStyle = true;
        }else {
          this.miniStyle = false;
        }
      }else {
        this.HamburgerBtnFlag = false;
        this.miniStyle = false;
      }
      if(this.screenWidth<469) {
        this.miniClassification = true;
      }else {
        this.miniClassification = false;
      }
    },
    // 根据关键字搜索
    searchRelevant() {
      if(this.searchKey) {
        // console.log(this.searchKey)
        this.$router.push({
          path: `/search_result_page/${this.searchKey}`,
        })
      } else {
        alert('输入框不可为空')
      }

    }
  },
  watch:{
    //监听浏览器宽度
    screenWidth() {
      this.monitoringScreenWidth();
    }
  },
}
</script>

<style scoped>
.rotationChart-box {
  height: 330px;
}
.topic-icon-box {
  position: absolute;
  width: 100%;
}
.classification-ul {
  list-style-type: none;
  width: 100%;
}
.classification-li {
  float: left;
  height: 120px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  background-color: rgba(0,0,0,0.4);
  position: relative;
  /*top: -190px;*/
  top: -206px;
  z-index: 2;
  cursor: pointer;
}
.classification-li-mini {
  float: left;
  height: 100px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  background-color: rgba(0,0,0,0.4);
  position: relative;
  top: -170px;
  /*top:-180%;*/
  z-index: 2;
  cursor: pointer;
}
.x-classification-li-mini {
  float: left;
  height: 70px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  top: -110px;
  color: #FFFFFF;
  z-index: 2;
  background-color: rgba(0,0,0,0.4);
  cursor: pointer;
}
.border-right {
  border-right: 2px solid #fff;
}
.classification-icon {
  font-size: 60px;
}
.classification-icon-mini {
  font-size: 40px;
}
.x-classification-icon-mini {
  font-size: 30px;
}
.classification-tx {
  font-size: 18px;
  text-align: center;
  margin-top: 10px;
}
.classification-tx-mini {
  font-size: 16px;
  text-align: center;
  margin-top: 10px;
}
.x-classification-tx-mini {
  font-size: 14px;
  text-align: center;
  margin-top: 4px;
}
.rotation-box {
  top: -70px;
  /*top: -84px;*/
}
/*轮播图图片样式*/
.rotation-img-big {
  max-width: 100%;
  height: 100%;
  /*position: relative;*/
  /*top: 50%;*/
  /*left: 50%;*/
  /*transform: translate(-50%,-50%);*/
}
.rotation-img-small {
  width: 100%;
  height: 300px;
}
/*手机端六大分类样式*/
.phone-classification-box {
  /*background-color: pink;*/
}
.phone-classification {
  color: #FD3A0C;
  text-align: center;
}
.phone-classification-icon {

}
.phone-classification-tx {
  color: #2954A2;
}
.search-btn-box {
  position: relative;
  top: -40px;
  z-index: 3;
  margin: auto 20px auto 20px;
}
.search-input {
  border-radius: 5px 0 0 5px !important;
  border-right: none;
}
.search-btn {
  background-color: #2954A2 !important;
  color: #FFFFFF!important;
  border-radius: 0 5px 5px 0;
  /*border: 1px solid #2954A2;*/
}
</style>
