<template>
  <div>
<!--    活动列表组件-->
    <el-card class="activities-card" v-for="(item,index) in activitiesList" :key="index" >
      <el-row>
        <el-col :xs="24" :sm="7" :md="6" :lg="5">
          <div class="activities-img-box">
            <img :class="[miniStyle?'activities-img-mini':'activities-img']" :src="item.picture" alt="">
          </div>
        </el-col>
        <el-col :xs="24" :sm="17" :md="18" :lg="19">
          <div :class="[miniStyle?'activities-msg-box-mini':'activities-msg-box']">
            <div :class="[miniStyle?'activities-tt-mini':'activities-tt']"><span v-if="item.isTop"><el-tag class="tag-box" size="mini">TOP</el-tag></span>{{item.title}}</div>
            <div :class="[miniStyle?'company-tx-mini':'company-tx']">
<!--              <i class="el-icon-office-building"></i>-->
<!--              <span>发布公司:{{item.company}}</span>-->
              <i class="el-icon-location" v-if="!miniStyle"></i>
              <span>活动地点:{{item.address}}</span>
            </div>
            <div :class="[miniStyle?'activities-tx-mini':'activities-tx']">{{item.description}}</div>
            <div :class="[miniStyle?'align-right':'vertical-space-between',miniStyle?'':'activities-btn-box']" >
              <div>
                <i class="el-icon-alarm-clock" v-if="!miniStyle" ></i>
                <span>发布时间:{{item.time}}</span>
              </div>
              <div>
<!--                <i class="el-icon-location" v-if="!miniStyle"></i>-->
<!--                <span>活动地点:{{item.address}}</span>-->
                <i class="el-icon-office-building" v-if="!miniStyle"></i>
                <span>发布公司:{{item.company}}</span>
              </div>
              <div v-if="!miniStyle">
                <el-button type="primary" size="mini" @click="goActivitiesDetail(item.id)">了解详情</el-button>
              </div>
            </div>
            <div v-if="miniStyle" class="mini-detail-box">
              <el-button class="mini-detail-btn" size="mini" @click="goActivitiesDetail(item.id)">了解详情</el-button>
            </div>
          </div>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "ActivitiesItem",
  props:['activitiesList'],
  data() {
    return {
      screenWidth: document.body.clientWidth,//浏览器宽度
      HamburgerBtnFlag:false,//是否显示汉堡按钮
      miniStyle:false,//手机样式
      activities_list:[]
    }
  },
  created() {
    this.monitoringScreenWidth();
    //获取图片URl

  },
  mounted() {
    const that = this
    window.addEventListener("resize", function() {
      return (() => {
        window.screenWidth= document.body.clientWidth;
        that.screenWidth= window.screenWidth;
      })();
    });
  },
  watch:{
    //监听浏览器宽度
    screenWidth() {
      this.monitoringScreenWidth();
    }
  },
  methods:{
    goActivitiesDetail(id) {
      this.$router.push({
        path: `/activities_detail/${id}`,
      })
      // if(this.$cookies.get('user')){
      //   this.$router.push({name:'ActivitiesDetail',query: {id:id}})
      // }else {
      //   this.$message({
      //     message:'登录成为会员才能查看更多消息！',
      //     type:'warning'
      //   })
      // }
    },
    //浏览器样式调整
    monitoringScreenWidth() {
      // xs 	<768px
      // sm 	≥768px
      // md 	≥992px
      // lg 	≥1200px
      // xl 	≥1920px
      // console.log(this.screenWidth)
      if(this.screenWidth < 1173) {
        this.HamburgerBtnFlag = true;
        if(this.screenWidth < 820) {
          this.miniStyle = true;
        }else {
          this.miniStyle = false;
        }
      }else {
        this.HamburgerBtnFlag = false;
        this.miniStyle = false;
      }
    }
  }
}
</script>

<style scoped>
.activities-card {
  margin-bottom: 6px;
}
.activities-img-box {
  text-align: center;
}
.activities-img {
  max-width: 90%;
  max-height: 140px;
  width: auto;
  height: auto
}
.activities-img-mini {
  width: 80%;
}
.activities-msg-box {
  padding: 8px;
  color: #888;
  font-size: 14px;
}
.activities-msg-box-mini {
  padding: 5px;
  color: #888;
  font-size: 12px;
}
.activities-tt {
  font-size: 20px;
  font-family: 方正大黑简体;
  color: #555;
}
.activities-tt-mini {
  font-size: 16px;
  font-family: 方正大黑简体;
  color: #555;
}
.activities-tx {
  margin-top: 6px;
  margin-bottom: 6px;
  color: #333;
  font-size: 14px;
}
.activities-tx-mini {
  margin-top: 6px;
  margin-bottom: 6px;
  color: #333;
  font-size: 14px;
}
.company-tx {
  font-size: 14px;
  margin-top: 6px;
}
.company-tx-mini {
  font-size: 12px;
  margin-top: 6px;
}
.activities-btn-box {
  line-height: 28px;
}
.mini-detail-box {
  text-align: center;
}
.mini-detail-btn {
  width: 90%;
  background-color: #2954A2;
  color: #FFFFFF;
  margin-top: 8px;
}
.align-right {
  text-align: right;
}
.tag-box {
  position: absolute;
  /*background: #92b7bd;*/
  background-color: rgb(245,108,108);
  z-index: 999;
  border-radius: 3px;
  border: none;
  color: #fff;
  font-size: 70%;
  padding: 2px 4px 0;
  top: -15px;
  left: -17px;
  line-height: 1.4;
  -ms-transform: rotate(-7deg);
  -webkit-transform: rotate(-7deg);
  transform: rotate(-7deg);
  transition: transform 0.1s ease-in;
}
</style>
