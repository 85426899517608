<template>
  <div>
<!--    公司荣誉墙页面-->
<!--    标题-->
    <!--    标题-->
    <div class="tt-box">
      <div :class="[miniStyle?'chinese-tt-mini':'chinese-tt']">环宇通印象</div>
      <div :class="[miniStyle?'english-tt-mini':'english-tt']">impression</div>
    </div>
<!--    图片-->
    <div v-if="companyInfo.pictureVoList">
      <el-row>
        <el-col :span="12"  v-for="(item,index) in companyInfo.pictureVoList" :key="index">
          <div :class="[miniStyle?'honor-box-mini':'honor-box']">
            <img :src="item.url" alt="" class="honor-pic">
<!--            <div class="honor-tx">{{item.name}}</div>-->
          </div>
        </el-col>
      </el-row>

    </div>
  </div>
</template>

<script>
export default {
  name: "Honor",
  props:['companyInfo'],
  data() {
    return {
      honorList:[],
      screenWidth: document.body.clientWidth,//浏览器宽度
      // HamburgerBtnFlag:false,//是否显示汉堡按钮
      miniStyle:false,//手机样式
    }
  },
  created() {
    this.monitoringScreenWidth();
  },
  mounted() {
    const that = this
    window.addEventListener("resize", function() {
      return (() => {
        window.screenWidth= document.body.clientWidth;
        that.screenWidth= window.screenWidth;
      })();
    });
  },
  watch:{
    //监听浏览器宽度
    screenWidth() {
      this.monitoringScreenWidth();
    }
  },
  methods: {
    //浏览器样式调整
    monitoringScreenWidth() {
      // xs 	<768px
      // sm 	≥768px
      // md 	≥992px
      // lg 	≥1200px
      // xl 	≥1920px
      // console.log(this.screenWidth)
      // if(this.screenWidth < 1173) {
      //   this.HamburgerBtnFlag = true;
      // }else {
      //   this.HamburgerBtnFlag = false;
      // }
      if(this.screenWidth < 620) {
        this.miniStyle = true;
      }else {
        this.miniStyle = false;
      }
    }
  }
}
</script>

<style scoped>
.tt-box {
  text-align: center;
  margin-top: 20px;
}
.chinese-tt {
  font-size: 24px;
  font-weight: bold;
  font-family: 微软雅黑;
  color: #343434;
}
.chinese-tt-mini {
  font-size: 18px;
  font-weight: bold;
  font-family: 微软雅黑;
  color: #343434;
}
.english-tt {
  color: #ADADAD;
  font-size: 20px;
  letter-spacing: 5px;
  font-weight: bold;
  margin-top: 10px;
}
.english-tt-mini {
  color: #ADADAD;
  font-size: 16px;
  letter-spacing: 5px;
  font-weight: bold;
  margin-top: 5px;
}
.honor-box {
  text-align: center;
  padding: 20px;
  font-size: 14px;
  font-family: 微软雅黑;
  color: #F86700;
  font-weight: bold;
}
.honor-box-mini {
  text-align: center;
  padding: 20px;
  font-size: 12px;
  font-family: 微软雅黑;
  color: #F86700;
}
.honor-pic {
  max-width: 100%;
  max-height: 100%;
  margin-bottom: 10px;
}
</style>