<template>
  <div>
    <el-row>
      <el-col :xs="{span:24,offset:0}" :md="{span:18,offset:3}">
        <div>
          <!--    我是最新活动-->
          <!--          标题-->
          <ModuleTitle title="专利平台"></ModuleTitle>
          <!--          tab标签页-->
          <TabAssembly
              :activeName="tabList[0].id"
              :tabList="tabList"
              :more-btn="true"
              :patentFlag="true"
              @getPatentByKey="getPatentByKey"
          ></TabAssembly>
          <!--          内容区域-->
          <div class="market-bg">
            <div class="market-white-board">
              <el-row>
                <el-col
                        :xs="{span:24,offset:0}"
                        :sm="{span:12,offset:0}"
                        :md="{span:8,offset:0}"
                        v-for="(item,index) in marketList"
                        :key="index">
                  <div class="market-item" @click="goDetailPage(item.id)">
                    <el-row>
                      <el-col :span="18">
                        <div>
                          <div class="market-tt">{{item.name}}</div>
                          <div>
                            <el-row>
                              <el-col :span="3">
                                <i class="el-icon-info transferor-icon"></i>
                              </el-col>
                              <el-col :span="21">
                                <span class="market-small-font">{{item.description}}</span>
                              </el-col>
                            </el-row>
                          </div>
                        </div>
                      </el-col>
                      <el-col :span="6">
                        <div class="market-state blue-bg vertical-center">
                          <span v-if="item.type == 0">专利</span>
                          <span v-if="item.type == 1">软著</span>
                          <span v-if="item.type == 2" class="blue-bg-small-font">实用新型</span>
                          <span v-if="item.type == 3">外观</span>
                        </div>
                      </el-col>
                    </el-row>
                  </div>
                </el-col>
              </el-row>
            </div>
          </div>

        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import ModuleTitle from "./ModuleTitle";
import TabAssembly from "./TabAssembly";
export default {
  name: "TradingMarket",
  components:{
    ModuleTitle,
    TabAssembly
  },
  data() {
    return {
      tabList:[
        {
          label:'会员技术',
          id:'all'
        },
        {
          label:'研发技术',
          id:'maturity'
        },
        {
          label:'市场技术',
          id:'isMarket'
        },
        {
          label:'合作技术',
          id:'cooperationType'
        }
      ],
      marketList:[],
      size:9,
      screenWidth: document.body.clientWidth,//浏览器宽度
      HamburgerBtnFlag:false,//是否显示汉堡按钮
      miniStyle:false,//手机样式
    }
  },
  created() {
    this.getPatentList();
    this.monitoringScreenWidth();
  },
  mounted() {
    const that = this
    window.addEventListener("resize", function() {
      return (() => {
        window.screenWidth= document.body.clientWidth;
        that.screenWidth= window.screenWidth;
      })();
    });
  },
  methods:{
    //获取默认会员专利技术
    getPatentList() {
      let url = 'web/patent/simple?size=' + this.size;
      this.getRequest(url).then(res=>{
        this.marketList = res;
      })
    },
    //获取指定专利技术
    getPatentByKey(data) {
      let url = ''
      switch(data){
        case "all":
          url = 'web/patent/simple?size=' + this.size;
          break
        case "maturity":
          url = 'web/patent/simple?maturity=3&size=' + this.size;
          break
        case "isMarket":
          url = 'web/patent/simple?isMarket=0&size=' + this.size;
          break
        case "cooperationType":
          url = 'web/patent/simple?cooperationType=0&size=' + this.size;
          break
      }
      this.getRequest(url).then(res=>{
        this.marketList = res;
      })
    },
    //跳转到专利详情页面
    goDetailPage(id){
      if(this.$cookies.get('user')){
        this.$router.push({name:'PatentDetail',query: {id:id}})
      }else {
        this.$message({
          message:'登录成为会员才能查看更多信息哦！',
          type:'warning'
        })
      }
    },
    //浏览器样式调整
    monitoringScreenWidth() {
      if(this.screenWidth <= 768) {
        this.size = 5;
      }else if(this.screenWidth < 992 && this.screenWidth > 768) {
        this.size = 6;
      }else if(this.screenWidth >= 992) {
        this.size = 9;
      }
    }
  },
  watch:{
    //监听浏览器宽度
    screenWidth() {
      this.monitoringScreenWidth();
    },
    size() {
      this.getPatentList();
    }

  },
}
</script>

<style scoped>
.market-bg {
  background:url("../../assets/market_bg.png");
  /*width: 100%;*/
  background-size:100% 100%;
  padding: 30px;
}
.market-white-board {
  background-color: #FFFFFF;
  padding: 10px;
  cursor: pointer;
  /*margin: 50px;*/
}
.market-item {
  background-color: #F7F8FC;
  padding: 5px;
  margin: 5px;
}
.market-tt {
  color: #1B1B1B;
  font-size: 16px;
  margin: 10px 0;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  word-break: break-all;
}
.transferor-icon {
  color: #F47921;
  display: flex;
  vertical-align: center;
}
.market-small-font {
  color: #6F6F6F;
  font-size: 12px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  word-break: break-all;
  margin-right: 10px;
}
.market-state{
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  width: 60px;
  height: 60px;
  border-radius: 50%;
}
.blue-bg {
  background-color: #2954A2;
}
.blue-bg-small-font {
  font-size: 14px;
}
.orange-bg {
  background-color: #F47921;
}
</style>
