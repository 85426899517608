<template>
  <div>
    <el-row>
      <el-col :xs="{span:24,offset:0}" :sm="{span:22,offset:1}" :md="{span:18,offset:3}">
        <div :class="[topImgMove?'top-image-box-mini':'top-image-box']">
          <img src="../assets/page_top_bg.png" alt="" :class="[topImgMove?'top-img-mini':'top-img']">
          <div :class="[topImgMove?'tt-box-mini':'tt-box']">
            <!--        项目名称-->
            <div  :class="[topImgMove?'small-tx-font-mini':'small-tx-font']">项目名称：</div>
            <div>
              {{projectMsg.name}}
            </div>
          </div>
        </div>
        <!--        基本属性-->
        <div :class="[topImgMove?'basic-properties-box-mini':'basic-properties-box']">
          <el-row>
            <el-col :span="6">
              <!--领域分类-->
              <div>
                <el-row>
                  <el-col :xs="0" :sm="3"><i class="el-icon-menu"></i></el-col>
                  <el-col :xs="24" :sm="21">
                    <div>
                      领域分类
                    </div>
                    <div :class="[topImgMove?'properties-border-mini':'properties-border']">
                      {{projectMsg.typeName}}
                    </div>
                  </el-col>
                </el-row>
              </div>
            </el-col>
            <el-col :span="4">
              <!--成熟度-->
              <div>
                <el-row>
                  <el-col :xs="0" :sm="3"><i class="el-icon-set-up"></i></el-col>
                  <el-col :xs="24" :sm="21">
                    <div>
                      成熟度
                    </div>
                    <div  :class="[topImgMove?'properties-border-mini':'properties-border']">
                      {{projectMsg.maturityName}}
                    </div>
                  </el-col>
                </el-row>
              </div>
            </el-col>
            <el-col :span="4">
              <!--合作方式-->
              <div>
                <el-row>
                  <el-col :xs="0" :sm="3"><i class="el-icon-s-check"></i></el-col>
                  <el-col :xs="24" :sm="21">
                    <div>
                      合作方式
                    </div>
                    <div  :class="[topImgMove?'properties-border-mini':'properties-border']">
                      {{projectMsg.cooperationName}}
                    </div>
                  </el-col>
                </el-row>
              </div>
            </el-col>
            <el-col :span="3">
              <!--专利级别-->
              <div>
                <el-row>
                  <el-col :xs="0" :sm="3"><i class="el-icon-location-outline"></i></el-col>
                  <el-col :xs="24" :sm="21">
                    <div>
                      级别
                    </div>
                    <div  :class="[topImgMove?'properties-border-mini':'properties-border']">
                      {{projectMsg.patentLevelName}}
                    </div>
                  </el-col>
                </el-row>
              </div>
            </el-col>
            <el-col :span="3">
              <!--专利状态-->
              <div>
                <el-row>
                  <el-col :xs="0" :sm="3"><i class="el-icon-location-outline"></i></el-col>
                  <el-col :xs="24" :sm="21">
                    <div>
                      热门
                    </div>
                    <div  :class="[topImgMove?'properties-border-mini':'properties-border']" v-if="projectMsg.isHot">
                      是
                    </div>
                    <div  :class="[topImgMove?'properties-border-mini':'properties-border']" v-else>
                      否
                    </div>
                  </el-col>
                </el-row>
              </div>
            </el-col>
            <el-col :span="4">
              <!--项目日期-->
              <div>
                <el-row>
                  <el-col :xs="0" :sm="3"><i class="el-icon-date"></i></el-col>
                  <el-col :xs="24" :sm="21">
                    <div>
                      项目日期
                    </div>
                    <div  :class="[topImgMove?'properties-border-mini':'properties-border']">
                      {{projectMsg.projYear}}
                    </div>
                  </el-col>
                </el-row>
              </div>
            </el-col>
          </el-row>
        </div>
        <!--        参考价格-->
        <div>
          <div :class="[topImgMove?'price-box-mini':'price-box']">
            参考价格：
            <span v-if="projectMsg.price == -1">
              <span :class="[topImgMove?'big-red-font-mini':'big-red-font']">面议</span>
            </span>
            <span v-else>
              <span class="small-red-font">￥{{projectMsg.price}}</span>
              <span :class="[topImgMove?'big-red-font-mini':'big-red-font']">万元</span>
            </span>

            <!--            <el-button type="primary" class="buy-btn">我要买</el-button>-->
          </div>
        </div>
        <div class="describe-box">
          <!--          标题-->
          <div :class="[topImgMove?'describe-tt-mini':'describe-tt']">
            项目描述<i class="el-icon-s-opportunity describe-icon"></i>
          </div>
          <!--          内容-->
          <div :class="[topImgMove?'describe-tx-mini':'describe-tx']">
            {{projectMsg.description}}
          </div>
        </div>
        <div class="describe-box" v-if="projectMsg.company">
          <!--          标题-->
          <div :class="[topImgMove?'describe-tt-mini':'describe-tt']">
            主创单位
          </div>
          <!--          内容-->
          <el-card :class="[topImgMove?'company-box-mini':'company-box']">
            <div class="vertical-space-between">
              <!--                单位信息-->
              <div class="small-tx-font">
                <!--                  标题-->
                <div :class="[topImgMove?'company-box-tt-mini':'company-box-tt']">{{projectMsg.company}}</div>
              </div>
            </div>
          </el-card>
        </div>
        <div class="describe-box"  v-if="projectMsg.peopleName">
          <!--          标题-->
          <div :class="[topImgMove?'describe-tt-mini':'describe-tt']">
            主要负责人
          </div>
          <el-card :class="[topImgMove?'company-box-mini':'company-box']">
            <div class="vertical-space-between">
              <!--                单位信息-->
              <div :class="[topImgMove?'small-tx-font-mini':'small-tx-font']">
                <!--                  标题-->
                <div :class="[topImgMove?'company-box-tt-mini':'company-box-tt']"><i class="el-icon-info name-icon"></i>{{projectMsg.peopleName}}</div>
                <div>联系方式： <span :class="[topImgMove?'company-box-msg-mini':'company-box-msg']">{{projectMsg.peoplePhone}}</span></div>
              </div>
            </div>
          </el-card>
        </div>
        <!--        单位信息-->
        <div class="describe-box" v-if="projectMsg.partnerVoList">
          <!--        专利描述-->

          <!--          标题-->
          <div :class="[topImgMove?'describe-tt-mini':'describe-tt']">
            相关单位信息
          </div>
          <!--          内容-->
          <el-card  :class="[topImgMove?'company-box-mini':'company-box']" v-for="(item,index) in projectMsg.partnerVoList" :key="index">
            <div class="vertical-space-between">
              <!--                单位信息-->
              <div :class="[topImgMove?'small-tx-font-mini':'small-tx-font']">
                <!--                  标题-->
                <div :class="[topImgMove?'company-box-tt-mini':'company-box-tt']">{{item.label}}</div>
              </div>
              <!--                操作按钮-->
              <div>
                <el-button type="primary" v-if="topImgMove" size="mini" @click="goCompanyDetail(item.value)">进入单位</el-button>
                <el-button type="primary" v-else @click="goCompanyDetail(item.value)">进入单位</el-button>
              </div>
            </div>
          </el-card>
<!--          <el-card v-if="projectMsg.company" :class="[topImgMove?'company-box-mini':'company-box']">-->
<!--            <div class="vertical-space-between">-->
<!--              &lt;!&ndash;                单位信息&ndash;&gt;-->
<!--              <div :class="[topImgMove?'small-tx-font-mini':'small-tx-font']">-->
<!--                &lt;!&ndash;                  标题&ndash;&gt;-->
<!--                <div :class="[topImgMove?'company-box-tt-mini':'company-box-tt']">{{projectMsg.company}}</div>-->
<!--              </div>-->
<!--              &lt;!&ndash;                操作按钮&ndash;&gt;-->
<!--            </div>-->
<!--          </el-card>-->
        </div>
<!--        学校信息-->
        <div class="describe-box" v-if="projectMsg.schoolVoList">

          <!--          标题-->
          <div :class="[topImgMove?'describe-tt-mini':'describe-tt']">
            相关学校信息
          </div>
          <!--          内容-->
          <el-card  :class="[topImgMove?'company-box-mini':'company-box']" v-for="(item,index) in projectMsg.schoolVoList" :key="index">
            <div class="vertical-space-between">
              <!--                单位信息-->
              <div :class="[topImgMove?'small-tx-font-mini':'small-tx-font']">
                <!--                  标题-->
                <div :class="[topImgMove?'company-box-tt-mini':'company-box-tt']">{{item.label}}</div>
              </div>
              <!--                操作按钮-->
              <div>
                <el-button v-if="topImgMove" size="mini" type="primary" @click="goSchoolDetail(item.value)">进入学校</el-button>
                <el-button v-else type="primary" @click="goSchoolDetail(item.value)">进入学校</el-button>
              </div>
            </div>
          </el-card>
        </div>
        <!--        专利拥有者信息-->
        <div class="describe-box" v-if="projectMsg.beforeUserList">
          <!--          标题-->
          <div :class="[topImgMove?'describe-tt-mini':'describe-tt']">
            相关用户
          </div>
          <!--          内容-->
          <div>
            <el-card  :class="[topImgMove?'company-box-mini':'company-box']"
                     v-for="(item,index) in projectMsg.beforeUserList"
                     :key="index">
              <div class="vertical-space-between">
                <!--                单位信息-->
                <div :class="[topImgMove?'small-tx-font-mini':'small-tx-font']">
                  <!--                  标题-->
                  <div :class="[topImgMove?'company-box-tt-mini':'company-box-tt']"> <i class="el-icon-info name-icon"></i> {{item.name}}</div>
                  <div>联系方式： <span :class="[topImgMove?'company-box-msg-mini':'company-box-msg']">{{item.phone}}</span></div>
                </div>
              </div>
            </el-card>
          </div>
        </div>
<!--        项目专利-->
        <div class="describe-box">
          <!--          标题-->
          <div :class="[topImgMove?'describe-tt-mini':'describe-tt']">
            项目专利
          </div>
          <!--          内容-->
          <PatentItem :patentList="PatentList" bind-btn="3"></PatentItem>
        </div>
        <!--        实物拍照-->
        <div class="describe-box">
          <!--          标题-->
          <div :class="[topImgMove?'describe-tt-mini':'describe-tt']">
            项目图片
          </div>
          <!--          内容-->
          <div  :class="[topImgMove?'describe-tx-mini':'describe-tx']">
            <el-row>
              <el-col :span="18" :offset="3" v-for="(item,index) in projectMsg.pictureVoList" :key="index">
                <img width="100%" :src="item.url" alt="">
              </el-col>
            </el-row>
          </div>
        </div>

      </el-col>
    </el-row>

  </div>
</template>

<script>
import PatentItem from "@/components/PatentPage/PatentItem";
export default {
  name: "AchievementsDetail",
  components:{
    PatentItem
  },
  data(){
    return{
      id:0,
      projectMsg:{ },
      ///面包屑导航栏
      breadList:null,
      PatentList:[],
      screenWidth: document.body.clientWidth,//浏览器宽度
      miniStyle:false,//专利操作按钮
      HamburgerBtnFlag:false,//是否显示汉堡按钮
      topImgMove:false,//顶部图片网上移动
    }
  },
  created() {
    // 获取路由传奇过来的专利id,进行请求获取该专利的详情信息
    this.id = this.$route.query.id;
    //获取项目详情
    this.getProjectDetail();
    //获取项目已绑定的专利
    this.getProjectVoPatents();
    //监控屏幕变化
    this.monitoringScreenWidth();
  },
  watch:{
    //监听浏览器宽度
    screenWidth() {
      this.monitoringScreenWidth();
    }
  },
  mounted() {
    const that = this
    window.addEventListener("resize", function() {
      return (() => {
        window.screenWidth= document.body.clientWidth;
        that.screenWidth= window.screenWidth;
      })();
    });
  },
  methods:{
    // 获取该专利的详情信息
    getProjectDetail() {
      this.getRequest('web/project/' + this.id).then(res=>{
        if(res) {
          this.projectMsg = res;
          //获取领域分类名称
          this.projectMsg.typeName = this.getTypeName(res.typeId);
          //获取成熟度分类
          this.projectMsg.maturityName = this.getMaturityName(res.maturity)
          //合作方式
          this.projectMsg.cooperationName = this.geCooperationName(res.cooperationType)
          //专利级别
          this.projectMsg.patentLevelName = this.getPatentLevelName(res.level)

        }
      })
    },
    //获取项目已绑定专利
    getProjectVoPatents() {
      this.getRequest('web/project/binded?projectId=' + this.id).then(res=>{
        this.PatentList = res;
      })
    },
    goDetail(value) {
      this.$router.push({name:'PartnerDetailPage',query:{item:value}})
    },
    //进入公司详情
    //跳转到公司详情页
    goCompanyDetail(id) {
      this.$router.push({
        path: `/partner_detailPage/${id}/company`,
      })
    },
    goSchoolDetail(id) {
      this.$router.push({
        path: `/partner_detailPage/${id}/school`,
      })
    },
    //浏览器样式调整
    monitoringScreenWidth() {
      // xs 	<768px
      // sm 	≥768px
      // md 	≥992px
      // lg 	≥1200px
      // xl 	≥1920px
      // console.log(this.screenWidth)
      if(this.screenWidth < 1058) {
        this.HamburgerBtnFlag = true;
      }else {
        this.HamburgerBtnFlag = false;
      }
      if(this.screenWidth < 776) {
        this.miniStyle = true;
      }else {
        this.miniStyle = false;
      }
      if(this.screenWidth < 730) {
        this.topImgMove = true;
      }else {
        this.topImgMove = false;
      }

    }
  }
}
</script>

<style scoped>
.top-image-box {
  position: relative;
  margin-top: 20px;
}
.top-image-box-mini {
  position: relative;
  margin-top: -22px;
}
.top-img {
  width: 100%;
  height: 120px;
}
.top-img-mini {
  width: 100%;
  height: 100px;
}
.tt-box{
  color: #DCD6D6;
  font-size: 24px;
  font-weight: bold;
  font-family: 微软雅黑;
  position: absolute;
  padding: 20px;
  top: 40%;
  left: 20px;
  transform: translate(0,-50%);
}
.tt-box-mini{
  color: #DCD6D6;
  font-size: 18px;
  font-weight: bold;
  font-family: 微软雅黑;
  position: absolute;
  padding: 20px;
  top: 40%;
  transform: translate(0,-50%);
}
.transfer-tag {
  border-radius: 40px;
  padding: 2px;
  font-size: 12px;
  color: #5daf34;
  background-color: rgba(243,251,248,0.3);
}
.basic-properties-box {
  background-color: rgb(249,250,251);
  padding: 20px;
  font-size: 14px;
  color: rgb(126,130,136);
  margin-top: 20px;
}
.basic-properties-box-mini {
  background-color: rgb(249,250,251);
  padding: 20px 10px 10px 20px;
  font-size: 12px;
  color: rgb(126,130,136);
  margin-top: 10px;
}
.properties-border {
  color: #2C2C2C;
  font-size: 18px;
  margin-top: 10px;
}
.properties-border-mini {
  color: #2C2C2C;
  font-size: 12px;
  margin-top: 8px;
}
.price-box {
  text-align: right;
  color: rgb(126,130,136);
  font-size: 14px;
  margin-right: 20px;
  margin-top: 10px;
}
.price-box-mini {
  text-align: right;
  color: rgb(126,130,136);
  font-size: 12px;
  margin-right: 20px;
  margin-top: 8px;
}
.small-red-font {
  color: rgb(233,70,67);
  font-size: 18px;
  font-weight: bold;
}
.big-red-font {
  color: rgb(233,70,67);
  font-size: 22px;
  font-weight: bold;
}
.big-red-font-mini {
  color: rgb(233,70,67);
  font-size: 18px;
  font-weight: bold;
}
.buy-btn {
  margin-left: 30px;
  width: 120px;
  border-radius: 3px;
}
/*专利描述*/
.describe-box {
  margin-top: 20px;
  border-top: 2px solid rgb(240,240,240);
  padding-top: 20px;
}
.describe-icon {
  margin-left: 6px;
  color: #ffab00;
}
.describe-tt {
  font-size: 20px;
  font-family: 方正大黑简体;
  color: #2F2F2F;
  border-left: 6px solid #2954A2;
  padding-left: 10px;
  margin-left: 6px;
}
.describe-tt-mini {
  font-size: 16px;
  font-family: 方正大黑简体;
  color: #2F2F2F;
  border-left: 6px solid #2954A2;
  padding-left: 10px;
  margin-left: 6px;
}
.describe-tx {
  margin-top: 20px;
  color: #2C2C2C;
  font-size: 18px;
}
.describe-tx-mini {
  margin: 10px;
  color: #2C2C2C;
  font-size: 14px;
}
.small-tx-font {
  color: rgb(126,130,136);
  font-size: 14px;
}
.small-tx-font-mini {
  color: rgb(126,130,136);
  font-size: 10px;
}
.company-box {
  margin: 20px 10px 0 10px;
}
.company-box-mini {
  margin: 10px;
}
.company-box-tt {
  color: #2C2C2C;
  font-family: 方正大黑简体;
  font-size: 18px;
  margin-bottom: 10px;
}
.company-box-tt-mini {
  color: #2C2C2C;
  font-family: 方正大黑简体;
  font-size: 15px;
}
.company-box-msg {
  color: #2C2C2C;
  font-size: 14px;
  font-weight: bold;
}
.company-box-msg-mini {
  color: #2C2C2C;
  font-size: 12px;
  font-weight: bold;
}
.name-icon {
  color: #ffab00;
  margin-right: 4px;
}
</style>
