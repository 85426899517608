<template>
  <div>
    <el-row>
      <el-col :xs="{span:24,offset:0}" :md="{span:18,offset:3}">
        <div class="activities-box">
          <!--    我是最新活动-->
          <!--          标题-->
          <ModuleTitle title="校企合作"></ModuleTitle>
          <!--          tab标签页-->
          <div class="btn-box">
            <el-button class="cooperative-btn" round @click="cooperation()">合作加盟<i class="el-icon-position" style="margin-left: 4px"></i></el-button>
          </div>
          <!--          卡片区域-->
          <div class="cooperation-bg">
            <div class="school-box">
              <el-row>
                <el-col :span="6" v-for="(item,index) in schoolList" :key="index" class="col-box" >
                  <div :class="miniStyle?'school-item-mini':'school-item'" @click="goPartnerDetail(item.id)">
                    <el-image
                        :class="[miniStyle?'school-logo-mini':'school-logo']"
                        :src="item.logo"
                        alt="学校的logo"
                    ></el-image>
<!--                    <img :class="[miniStyle?'school-logo-mini':'school-logo']" src="http://r7hpinpck.hn-bkt.clouddn.com/FtuxleKpoaXQIzWAafZcYHgfQTwF?e=1648406609&token=v6g15Qn__SAt5K7jzTUqpv8EvxmMHcAT-K5s3nGN:XY-zZGBMe_IGddjfbdrIDBf8MNk=" alt="学校的logo">-->
                    <div :class="[miniStyle?'school-name-mini':'school-name']">{{item.name}}</div>
                  </div>
                </el-col>
              </el-row>
            </div>
          </div>
          <div>
            <!--              分页-->
            <Paging
                :total="pageTotal"
                :pageSize="parameterObj.pageSize"
                :currentPage="parameterObj.pageNum"
                @changeCurrent="changeCurrent"
                noSizesFlag="true"
            ></Paging>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import ModuleTitle from "./ModuleTitle";
import Paging from "@/components/Paging";
export default {
  name: "SchoolEnterpriseCooperation",
  components:{
    ModuleTitle,
    Paging
  },
  data() {
    return {
      tabList:[
        {
          label:'技术难题解决',
          id:'1022'
        },
        {
          label:'产学研合作',
          id:'1021'
        },
        {
          label:'成果转化服务',
          id:'1020'
        }
      ],
      schoolList:[],
      baseUrl:'web/school/page?',
      pageTotal:0,//专利总条数
      parameterObj:{
        pageNum:1,
        pageSize:8
      },
      screenWidth: document.body.clientWidth,//浏览器宽度
      HamburgerBtnFlag:false,//是否显示汉堡按钮
      miniStyle:false,//手机样式
    }
  },
  created() {
    this.getSchoolList();
    this.monitoringScreenWidth();
  },
  watch:{
    //监听浏览器宽度
    screenWidth() {
      this.monitoringScreenWidth();
    }
  },
  mounted() {
    const that = this
    window.addEventListener("resize", function() {
      return (() => {
        window.screenWidth= document.body.clientWidth;
        that.screenWidth= window.screenWidth;
      })();
    });
  },
  methods:{
    goPartnerDetail(id){
      this.$router.push({
        path: `/partner_detailPage/${id}/school`,
      })
    },
    getSchoolList() {
      this.getRequest(this.baseUrl + 'pageNum='+ this.parameterObj.pageNum+'&pageSize=' + this.parameterObj.pageSize).then(res=>{
        this.schoolList = res.row;
        this.pageTotal = res.totalCount;
        this.parameterObj.pageSize = res.pageSize;
        this.parameterObj.pageNum = res.pageNum;
        // console.log(this.schoolList)
      })
    },
    //请求另一页的数据
    changeCurrent(currentPage) {
      this.parameterObj.pageNum = currentPage;
      this.getSchoolList();
    },
    //点击了合作按钮
    cooperation() {
      this.$router.push({name:'AboutUsPage',query:{activeName:'contact'}})
    },
    //浏览器样式调整
    monitoringScreenWidth() {
      // xs 	<768px
      // sm 	≥768px
      // md 	≥992px
      // lg 	≥1200px
      // xl 	≥1920px
      if(this.screenWidth < 1173) {
        this.HamburgerBtnFlag = true;
        if(this.screenWidth < 760) {
          this.miniStyle = true;
        }else {
          this.miniStyle = false;
        }
      }else {
        this.HamburgerBtnFlag = false;
        this.miniStyle = false;
      }
    }
  }
}
</script>

<style scoped>
.cooperation-bg {
  background:url("../../assets/data_bg.jpg");
  /*background-color: pink;*/
  width: 100%;
  background-size:100% 100%;
  padding:20px 0 60px 0 ;
}
.col-box {
  display: inline-block;
}
.school-item {
  box-shadow:2px 2px 5px #aaa;
  padding: 10px;
  background-color: #FFFFFF;
  margin: 20px;
  text-align: center;
  cursor: pointer;
}
.school-item-mini {
  box-shadow:2px 2px 5px #aaa;
  background-color: #FFFFFF;
  margin: 4px;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px  ;
  cursor: pointer;
}
.school-logo {
  width: 100px;
  height: 100px;
}
.school-logo-mini {
  width: 50px;
  height: 50px;
}
.school-name {
  color: #333333;
  font-size: 16px;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  word-break: break-all;
}
.school-name-mini {
  color: #333333;
  font-size: 12px;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  word-break: break-all;
}
.school-box {
  /*width: 90%;*/
}
.control-icon {
  font-size: 30px;
  font-weight: bold;
  margin: 10px;
  color: #aaa;
}
.cooperation-box {
  height: 150px;
  /*margin: 20px;*/
  width: 100%;
}
.cooperation-btn {
  color: #2954A2;
  font-size: 18px;
  position: relative;
  bottom: -100%;
  right: -70%;
}
/*合作加盟按钮*/
.btn-box {
  width: 100%;
  text-align: center;
}
.cooperative-btn {
  background-color: #659CFF;
  color: #FFFFFF;
  margin: 20px 0 0px 0;
}
</style>
