<template>
  <div class="active-long">
<!--    最新活动页面-->
    <el-row>
      <el-col :span="18" :offset="3">
        <!--        政策法规-->
        <ModuleTitle title="最新活动"></ModuleTitle>
        <ActivitiesItem :activitiesList="activitiesList"></ActivitiesItem>
        <!--        分页-->
        <Paging
            :total="pageTotal"
            :pageSize="parameterObj.pageSize"
            :currentPage="parameterObj.pageNum"
            @changeCurrent="changeCurrent"
            @changeSize="changeSize"
        ></Paging>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import ModuleTitle from "@/components/HomePage/ModuleTitle";
import ActivitiesItem from "@/components/ActivitiesPage/ActivitiesItem";
import Paging from "@/components/Paging";
export default {
  name: "ActivitiesPage",
  components:{
    ModuleTitle,
    Paging,
    ActivitiesItem
  },
  data() {
    return {
      activitiesList:[],
      pageTotal:0,//专利总条数
      parameterObj:{
        pageNum:1,
        pageSize:5
      }
    }
  },
  created() {
    this.getActivitiesList();
  },
  methods:{
    getActivitiesList() {
      this.getRequest('web/activity/list?pageSize='+this.parameterObj.pageSize+'&pageNum='+ this.parameterObj.pageNum).then(res=>{
        if(res) {
          this.activitiesList = res.row;
          this.pageTotal = res.totalCount;
          this.parameterObj.pageNum = res.pageNum;
          this.parameterObj.pageSize = res.pageSize;
        }
      })
    },
    //请求另一页的数据
    changeCurrent(currentPage) {
      this.parameterObj.pageNum = currentPage;
      this.getActivitiesList();
    },
    //改变当前每页的数量
    changeSize(value) {
      this.parameterObj.pageSize = value;
      this.parameterObj.pageNum = 1;
      this.getActivitiesList();
    },
  }
}
</script>

<style scoped>
.active-long {
  height: 1200px;
  /*防止抖动*/
}
</style>