<template>
  <div>
    <el-row>
      <el-col :xs="{span:24,offset:0}" :sm="{span:22,offset:1}" :md="{span:18,offset:3}">
        <!--    专利详情页-->
        <!--    顶部图片-->
        <div :class="[topImgMove?'top-image-box-mini':'top-image-box']">
          <img src="../assets/page_top_bg.png" alt="" :class="[topImgMove?'top-img-mini':'top-img']">
          <div :class="[topImgMove?'tt-box-mini':'tt-box']">
            <!--        专利名称-->
            <div :class="[topImgMove?'small-tx-font-mini':'small-tx-font']">{{patentMsg.patentTypeName}}名称：</div>
            <div>
              {{patentMsg.name}}
            </div>
          </div>
        </div>
<!--        基本属性-->
        <div :class="[topImgMove?'basic-properties-box-mini':'basic-properties-box']">
          <el-row>
            <el-col :span="6">
              <!--领域分类-->
              <div>
                <el-row>
                  <el-col :xs="0" :sm="3"><i class="el-icon-menu"></i></el-col>
                  <el-col :xs="24" :sm="21">
                    <div>
                      领域分类
                    </div>
                    <div :class="[topImgMove?'properties-border-mini':'properties-border']">
                      {{patentMsg.typeName}}
                    </div>
                  </el-col>
                </el-row>
              </div>
            </el-col>
            <el-col :span="4">
              <!--成熟度-->
              <div>
                <el-row>
                  <el-col :xs="0" :sm="3"><i class="el-icon-set-up"></i></el-col>
                  <el-col :xs="24" :sm="21">
                    <div>
                      成熟度
                    </div>
                    <div :class="[topImgMove?'properties-border-mini':'properties-border']">
                      {{patentMsg.maturityName}}
                    </div>
                  </el-col>
                </el-row>
              </div>
            </el-col>
            <el-col :span="4">
              <!--合作方式-->
              <div>
                <el-row>
                  <el-col :xs="0" :sm="3"><i class="el-icon-s-check"></i></el-col>
                  <el-col :xs="24" :sm="21">
                    <div>
                      合作方式
                    </div>
                    <div :class="[topImgMove?'properties-border-mini':'properties-border']">
                      {{patentMsg.cooperationName}}
                    </div>
                  </el-col>
                </el-row>
              </div>
            </el-col>
            <el-col :span="3">
              <!--专利级别-->
              <div>
                <el-row>
                  <el-col :xs="0" :sm="3"><i class="el-icon-location-outline"></i></el-col>
                  <el-col :xs="24" :sm="21">
                    <div>
                      级别
                    </div>
                    <div :class="[topImgMove?'properties-border-mini':'properties-border']">
                      {{patentMsg.patentLevelName}}
                    </div>
                  </el-col>
                </el-row>
              </div>
            </el-col>
            <el-col :span="3">
              <!--专利状态-->
              <div>
                <el-row>
                  <el-col :xs="0" :sm="3"><i class="el-icon-location-outline"></i></el-col>
                  <el-col :xs="24" :sm="21">
                    <div>
                      热门
                    </div>
                    <div :class="[topImgMove?'properties-border-mini':'properties-border']" v-if="patentMsg.isHot">
                      是
                    </div>
                    <div :class="[topImgMove?'properties-border-mini':'properties-border']" v-else>
                      否
                    </div>
                  </el-col>
                </el-row>
              </div>
            </el-col>
            <el-col :span="4">
              <!--专利日期-->
              <div>
                <el-row>
                  <el-col :xs="0" :sm="3"><i class="el-icon-date"></i></el-col>
                  <el-col :xs="24" :sm="21">
                    <div>
                      专利日期
                    </div>
                    <div :class="[topImgMove?'properties-border-mini':'properties-border']">
                      {{patentMsg.projYear}}
                    </div>
                  </el-col>
                </el-row>
              </div>
            </el-col>
          </el-row>
        </div>
<!--        参考价格-->
        <div>
          <div :class="[topImgMove?'price-box-mini':'price-box']">
            参考价格：
            <span v-if="patentMsg.price == -1">
              <span :class="[topImgMove?'big-red-font-mini':'big-red-font']">面议</span>
            </span>
            <span v-else>
              <span class="small-red-font">￥{{patentMsg.price}}</span>
              <span :class="[topImgMove?'big-red-font-mini':'big-red-font']">万元</span>
            </span>

<!--            <el-button type="primary" class="buy-btn">我要买</el-button>-->
          </div>
        </div>
        <!--        专利描述-->
        <div class="describe-box">
          <!--          标题-->
          <div :class="[topImgMove?'describe-tt-mini':'describe-tt']">
            专利描述<i class="el-icon-s-opportunity describe-icon"></i>
          </div>
          <!--          内容-->
          <div :class="[topImgMove?'describe-tx-mini':'describe-tx']">
            {{patentMsg.description}}
          </div>
        </div>
        <div class="describe-box" v-if="patentMsg.company">
          <!--          标题-->
          <div :class="[topImgMove?'describe-tt-mini':'describe-tt']">
            主创单位
          </div>
          <!--          内容-->
          <el-card :class="[topImgMove?'company-box-mini':'company-box']">
            <div class="vertical-space-between">
              <!--                单位信息-->
              <div class="small-tx-font">
                <!--                  标题-->
                <div :class="[topImgMove?'company-box-tt-mini':'company-box-tt']">{{patentMsg.company}}</div>
              </div>
            </div>
          </el-card>
        </div>
        <div class="describe-box"  v-if="patentMsg.peopleName">
          <!--          标题-->
          <div :class="[topImgMove?'describe-tt-mini':'describe-tt']">
            主要负责人
          </div>
          <el-card :class="[topImgMove?'company-box-mini':'company-box']">
            <div class="vertical-space-between">
              <!--                单位信息-->
              <div :class="[topImgMove?'small-tx-font-mini':'small-tx-font']">
                <!--                  标题-->
                <div :class="[topImgMove?'company-box-tt-mini':'company-box-tt']"><i class="el-icon-info name-icon"></i>{{patentMsg.peopleName}}</div>
                <div>联系方式： <span :class="[topImgMove?'company-box-msg-mini':'company-box-msg']">{{patentMsg.peoplePhone}}</span></div>
              </div>
            </div>
          </el-card>
        </div>
        <!--        单位信息-->
        <div class="describe-box" v-if="patentMsg.partnerVoList">
          <!--          标题-->
          <div :class="[topImgMove?'describe-tt-mini':'describe-tt']">
            相关单位信息
          </div>
          <!--          内容-->
          <el-card :class="[topImgMove?'company-box-mini':'company-box']" v-for="(item,index) in patentMsg.partnerVoList" :key="index">
            <div class="vertical-space-between">
              <!--                单位信息-->
              <div class="small-tx-font">
                <!--                  标题-->
                <div :class="[topImgMove?'company-box-tt-mini':'company-box-tt']">{{item.label}}</div>
              </div>
              <!--                操作按钮-->
              <div>
                <el-button type="primary" v-if="topImgMove" size="mini" @click="goDetail(item.value)">进入单位</el-button>
                <el-button type="primary" v-else @click="goDetail(item.value)">进入单位</el-button>
              </div>
            </div>
          </el-card>
        </div>
        <div class="describe-box" v-if="patentMsg.schoolVoList">
          <!--          标题-->
          <div :class="[topImgMove?'describe-tt-mini':'describe-tt']">
            相关学校信息
          </div>
          <!--          内容-->
          <el-card :class="[topImgMove?'company-box-mini':'company-box']" v-for="(item,index) in patentMsg.schoolVoList" :key="index">
            <div class="vertical-space-between">
              <!--                单位信息-->
              <div class="small-tx-font">
                <!--                  标题-->
                <div :class="[topImgMove?'company-box-tt-mini':'company-box-tt']">{{item.label}}</div>
              </div>
              <!--                操作按钮-->
              <div>
                <el-button type="primary"  v-if="topImgMove" size="mini" @click="goSchoolDetail(item.value)">进入单位</el-button>
                <el-button type="primary" v-else @click="goSchoolDetail(item.value)">进入单位</el-button>
              </div>
            </div>
          </el-card>
        </div>

<!--        专利拥有者信息-->
        <div class="describe-box" v-if="patentMsg.beforeUserList">
          <!--          标题-->
          <div :class="[topImgMove?'describe-tt-mini':'describe-tt']">
            相关用户
          </div>
          <!--          内容-->
         <div v-if="patentMsg.beforeUserList">
           <el-card :class="[topImgMove?'company-box-mini':'company-box']" v-for="(item,index) in patentMsg.beforeUserList" :key="index">
             <div class="vertical-space-between">
               <!--                单位信息-->
               <div :class="[topImgMove?'small-tx-font-mini':'small-tx-font']">
                 <!--                  标题-->
                 <div :class="[topImgMove?'company-box-tt-mini':'company-box-tt']"><i class="el-icon-info name-icon"></i>{{item.name}}</div>
                 <div>联系方式： <span :class="[topImgMove?'company-box-msg-mini':'company-box-msg']">{{item.phone}}</span></div>
               </div>
             </div>
           </el-card>
         </div>
        </div>


<!--        实物拍照-->
        <div class="describe-box">
          <!--          标题-->
          <div :class="[topImgMove?'describe-tt-mini':'describe-tt']">
            专利图片
          </div>
          <!--          内容-->
          <div :class="[topImgMove?'describe-tx-mini':'describe-tx']">
            <el-row>
              <el-col style="text-align: center" :span="18" :offset="3" v-for="(item,index) in patentMsg.pictureVoList" :key="index">
                <img class="paten-img" :src="item.url" alt="">
              </el-col>
            </el-row>
          </div>
        </div>

      </el-col>
    </el-row>

  </div>
</template>

<script>
export default {
  name: "PatentDetail",
  data(){
    return{
      id:0,
      patentMsg:{
        cooperationName:'',
        maturityName:'',
        typeName:''
      },
      // 面包屑导航栏
      breadList:null,
      screenWidth: document.body.clientWidth,//浏览器宽度
      miniStyle:false,//专利操作按钮
      HamburgerBtnFlag:false,//是否显示汉堡按钮
      topImgMove:false,//顶部图片网上移动
    }
  },
  created() {
    // 获取路由传奇过来的专利id,进行请求获取该专利的详情信息
    this.id = this.$route.query.id;
    // this.patentMsg.cooperationName = this.$route.query.cooperationName;
    // this.patentMsg.maturityName = this.$route.query.maturityName;

    // console.log(this.$route.query)
    // console.log('路由传递的参数是' + this.id)
    //获取对应id的专利
    this.getPatentDetail();
    //监控屏幕变化
    this.monitoringScreenWidth();
  },
  watch:{
    $route() {
      this.getBreadcrumb();
    },
    //监听浏览器宽度
    screenWidth() {
      this.monitoringScreenWidth();
    }
  },
  mounted() {
    const that = this
    window.addEventListener("resize", function() {
      return (() => {
        window.screenWidth= document.body.clientWidth;
        that.screenWidth= window.screenWidth;
      })();
    });
  },
  methods:{
    getBreadcrumb() {
      let matched = this.$route.matched.filter(item => item.meta && item.meta.title);
      this.breadList = matched;
      // console.log('路由')
      // console.log(this.$route)
    },
    //获取该专利的详情信息
    getPatentDetail() {
      this.getRequest('web/patent/' + this.id).then(res=>{
        // console.log(res)
        if(res) {
          this.patentMsg = res;
          //获取领域分类名称
          this.patentMsg.typeName = this.getTypeName(res.typeId);
          //获取成熟度分类
          this.patentMsg.maturityName = this.getMaturityName(res.maturity)
          //合作方式
          this.patentMsg.cooperationName = this.geCooperationName(res.cooperationType)
          //专利级别
          this.patentMsg.patentLevelName = this.getPatentLevelName(res.level)
          //专利类型
          this.patentMsg.patentTypeName = this.getPatentTypeName(res.type);
        }
      })
    },
    goDetail(id) {
      this.$router.push({
        path: `/partner_detailPage/${id}/company`,
      })
    },
    goSchoolDetail(id) {
      this.$router.push({
        path: `/partner_detailPage/${id}/school`,
      })
    },
    //浏览器样式调整
    monitoringScreenWidth() {
      // xs 	<768px
      // sm 	≥768px
      // md 	≥992px
      // lg 	≥1200px
      // xl 	≥1920px
      // console.log(this.screenWidth)
      if(this.screenWidth < 1058) {
        this.HamburgerBtnFlag = true;
      }else {
        this.HamburgerBtnFlag = false;
      }
      if(this.screenWidth < 776) {
        this.miniStyle = true;
      }else {
        this.miniStyle = false;
      }
      if(this.screenWidth < 730) {
        this.topImgMove = true;
      }else {
        this.topImgMove = false;
      }

    }
  },
}
</script>

<style scoped>
.top-image-box {
  position: relative;
  margin-top: 20px;
}
.top-image-box-mini {
  position: relative;
  margin-top: -22px;
}
.top-img {
  width: 100%;
  height: 120px;
}
.top-img-mini {
  width: 100%;
  height: 100px;
}
.tt-box{
  color: #DCD6D6;
  font-size: 24px;
  font-weight: bold;
  font-family: 微软雅黑;
  position: absolute;
  padding: 20px;
  top: 40%;
  left: 20px;
  transform: translate(0,-50%);
}
.tt-box-mini{
  color: #DCD6D6;
  font-size: 18px;
  font-weight: bold;
  font-family: 微软雅黑;
  position: absolute;
  padding: 20px;
  top: 40%;
  transform: translate(0,-50%);
}
.transfer-tag {
  border-radius: 40px;
  padding: 2px;
  font-size: 12px;
  color: #5daf34;
  background-color: rgba(243,251,248,0.3);
}
.basic-properties-box {
  background-color: rgb(249,250,251);
  padding: 20px;
  font-size: 14px;
  color: rgb(126,130,136);
  margin-top: 20px;
}
.basic-properties-box-mini {
  background-color: rgb(249,250,251);
  padding: 20px 10px 10px 20px;
  font-size: 12px;
  color: rgb(126,130,136);
  margin-top: 10px;
}
.properties-border {
  color: #2C2C2C;
  font-size: 18px;
  margin-top: 10px;
}
.properties-border-mini {
  color: #2C2C2C;
  font-size: 12px;
  margin-top: 8px;
}
.price-box {
  text-align: right;
  color: rgb(126,130,136);
  font-size: 14px;
  margin-right: 20px;
  margin-top: 10px;
}
.price-box-mini {
  text-align: right;
  color: rgb(126,130,136);
  font-size: 12px;
  margin-right: 20px;
  margin-top: 8px;
}
.small-red-font {
  color: rgb(233,70,67);
  font-size: 18px;
  font-weight: bold;
}
.big-red-font {
  color: rgb(233,70,67);
  font-size: 22px;
  font-weight: bold;
}
.big-red-font-mini {
  color: rgb(233,70,67);
  font-size: 18px;
  font-weight: bold;
}
.buy-btn {
  margin-left: 30px;
  width: 120px;
  border-radius: 3px;
}
/*专利描述*/
.describe-box {
  margin-top: 20px;
  border-top: 2px solid rgb(240,240,240);
  padding-top: 20px;
}
.describe-icon {
  margin-left: 6px;
  color: #ffab00;
}
.name-icon {
  color: #ffab00;
  margin-right: 4px;
}
.describe-tt {
  font-size: 20px;
  font-family: 方正大黑简体;
  color: #2F2F2F;
  border-left: 6px solid #2954A2;
  padding-left: 10px;
  margin-left: 6px;
}
.describe-tt-mini {
  font-size: 16px;
  font-family: 方正大黑简体;
  color: #2F2F2F;
  border-left: 6px solid #2954A2;
  padding-left: 10px;
  margin-left: 6px;
}
.describe-tx {
  margin: 20px 20px 0 20px;
  color: #2C2C2C;
  font-size: 18px;
}
.describe-tx-mini {
  margin: 10px;
  color: #2C2C2C;
  font-size: 14px;
}
.small-tx-font {
  color: rgb(126,130,136);
  font-size: 14px;
}
.small-tx-font-mini {
  color: rgb(126,130,136);
  font-size: 10px;
}
.company-box {
  margin: 20px 10px 0 10px;
}
.company-box-mini {
  margin: 10px;
}
.company-box-tt {
  color: #2C2C2C;
  font-family: 方正大黑简体;
  font-size: 18px;
  margin-bottom: 10px;
}
.company-box-tt-mini {
  color: #2C2C2C;
  font-family: 方正大黑简体;
  font-size: 15px;
}
.company-box-msg {
  color: #2C2C2C;
  font-size: 14px;
  font-weight: bold;
}
.company-box-msg-mini {
  color: #2C2C2C;
  font-size: 12px;
  font-weight: bold;
}
.paten-img {
  max-width: 100%;
}
</style>
