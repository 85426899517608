<template>
  <div>
    <el-row>
      <el-col :xs="{span:24,offset:0}" :md="{span:18,offset:3}">
        <div>
          <!--    我是最新活动-->
          <!--          标题-->
          <ModuleTitle title="需求大厅"></ModuleTitle>
          <!--          tab标签页-->
          <TabAssembly
              v-if="miniStyle"
              :activeName="tabList[0].id"
              :tabList="tabList"
              :more-btn="true"
              :demandFlag="true"
              @getDemandByKey="getDemandByKey"
          ></TabAssembly>
          <!--          内容区域-->
          <div class="demand-bg">
            <el-row v-if="!miniStyle">
              <el-col :md="{span:8,offset:0}" v-for="(item,index) in demandList" :key="index">
                <div class="demand-item" :class="[index%2==0?'blue-bg':'yellow-bg']">
                  <div class="demand-tt-box">
                    {{item.title}}
                  </div>
                  <div
                      v-for="(subItem,subIndex) in item.child"
                      :key="subIndex"
                      class="demand-child-item"
                      @click="goDetailPage(subItem.id)"
                  >
                    <div class="demand-yellow-numb" v-if="subItem.price == -1">面议</div>
                    <div class="demand-yellow-numb" v-else>{{subItem.price}} <span v-if="subItem.unit == 1">万元</span></div>
                    <div class="demand-demand-name">{{subItem.title}}</div>
                  </div>
                </div>
              </el-col>
            </el-row>
            <el-row v-else>
              <el-col :xs="{span:24,offset:0}">
                <div class=" blue-bg">
                  <div class="demand-tt-box-mini">
                    {{miniDemandList.title}}
                  </div>
                  <div
                      v-for="(subItem,subIndex) in miniDemandList.child"
                      :key="subIndex"
                      class="demand-child-item"
                      @click="goDetailPage(subItem.id)"
                  >
                    <div class="demand-yellow-numb-mini" v-if="subItem.price == -1">面议</div>
                    <div class="demand-yellow-numb-mini" v-else>{{subItem.price}} <span v-if="subItem.unit == 1">万元</span></div>
                    <div class="demand-demand-name-mini">{{subItem.title}}</div>
                  </div>
                </div>
              </el-col>
            </el-row>
            <div class="demand-release-form">
              <el-button class="demand-btn" round type="warning" @click="goMyDemand">发布需求 <i class="el-icon-s-promotion"></i></el-button>
            </div>
          </div>

        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import ModuleTitle from "./ModuleTitle";
import TabAssembly from "./TabAssembly";
export default {
  name: "DemandHall",
  components:{
    ModuleTitle,
    TabAssembly
  },
  data() {
    return {
      tabList:[
        {
          label:'技术难题',
          id:'hard'
        },
        {
          label:'项目投资',
          id:'project'
        },
        {
          label:'其他服务',
          id:'other'
        }
      ],
      demandList:[
        {
          title:'技术难题',
          child:[]
        },
        {
          title:'项目投资',
          child:[]
        },
        {
          title:'其他服务',
          child:[]
        }
      ],
      demandForm:{
        demand:'',
        contactInfo:''
      },
      screenWidth: document.body.clientWidth,//浏览器宽度
      HamburgerBtnFlag:false,//是否显示汉堡按钮
      miniStyle:false,//手机样式
      miniDemandList:{},//手机时的需求数据
    }
  },
  created() {
    this.getInvestmentDemand();
    this.getTechnicalDemand();
    this.getOtherServicesDemand();
    this.monitoringScreenWidth();
  },
  mounted() {
    const that = this
    window.addEventListener("resize", function() {
      return (() => {
        window.screenWidth= document.body.clientWidth;
        that.screenWidth= window.screenWidth;
      })();
    });
  },
  watch:{
    //监听浏览器宽度
    screenWidth() {
      this.monitoringScreenWidth();
    }
  },
  methods:{
    //项目投资需求
    getInvestmentDemand() {
      let url = 'web/demand/byDict?type=1&size=4';
      this.getRequest(url).then(res=>{
        this.demandList[1].child = res;
        this.miniDemandList = this.demandList[1];
      })
    },
    //技术难题需求
    getTechnicalDemand() {
      let url = 'web/demand/byDict?type=0&size=4';
      this.getRequest(url).then(res=>{
        this.demandList[0].child = res;
        this.miniDemandList = this.demandList[0];
      })

    },
    //其他服务需求
    getOtherServicesDemand() {
      let url = 'web/demand/byDict?type=2&size=4';
      this.getRequest(url).then(res=>{
        this.demandList[2].child = res;
        this.miniDemandList = this.demandList[2];
      })
    },
    //跳转到需求详情页面
    goDetailPage(id) {
      if(this.$cookies.get('user')){
        this.$router.push({name:'DemandDetail',query:{id:id}})
      }else {
        this.$message({
          message:'登录成为会员才能查看更多信息哦！',
          type:'warning'
        })
      }
    },
    //跳转到我的需求页面
    goMyDemand() {
      if(this.$cookies.get('user')){
        this.$router.push({name:'MyDemand'});
      }else {
        this.$message({
          message:'登录成为会员才能发布需求！',
          type:'warning'
        })
      }
    },
    //小页面的需求大厅请求
    getDemandByKey(data){
      switch (data) {
        case 'hard':
          this.getTechnicalDemand();
          break
        case "project":
          this.getInvestmentDemand();
          break
        case "other":
          this.getOtherServicesDemand();
          break
      }
    },
    //浏览器样式调整
    monitoringScreenWidth() {
      // xs 	<768px
      // sm 	≥768px
      // md 	≥992px
      // lg 	≥1200px
      // xl 	≥1920px
      if(this.screenWidth < 1173) {
        this.HamburgerBtnFlag = true;
        if(this.screenWidth < 768) {
          this.miniStyle = true;
        }else {
          this.miniStyle = false;
        }
      }else {
        this.HamburgerBtnFlag = false;
        this.miniStyle = false;
      }
    }

  }

}
</script>

<style scoped>
.demand-bg {
  background-color: #EEF5FF;
  padding: 30px 60px ;
}
.demand-item {
  margin-right: 20px;
}
.blue-bg .demand-tt-box {
  background-color: #2954A2;
  color: #FFFFFF;
  /*font-weight: bold;*/
  font-size: 20px;
  text-align: center;
  padding: 12px;

}
.blue-bg .demand-tt-box-mini {
  background-color: #2954A2;
  color: #FFFFFF;
  /*font-weight: bold;*/
  font-size: 16px;
  text-align: center;
  padding: 12px;

}
.blue-bg .demand-child-item {
  background-image: linear-gradient(rgb(255,255,255),rgb(201,220,253));
  padding: 10px;
  cursor: pointer;
}
.yellow-bg  .demand-tt-box {
  background-color: #F47921;
  color: #FFFFFF;
  /*font-weight: bold;*/
  font-size: 20px;
  text-align: center;
  padding: 12px;

}
.yellow-bg  .demand-child-item {
  background-image: linear-gradient(#FFFFFF,#FDDCC6);
  padding: 10px;
  cursor: pointer;
}
.demand-yellow-numb {
  font-size: 18px;
  color: #F47921;
  font-weight: bold;
}
.demand-yellow-numb-mini {
  font-size: 14px;
  color: #F47921;
  font-weight: bold;
}
.demand-demand-name {
  color: #333333;
  font-size: 13px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  word-break: break-all;
  margin-top: 10px;
}
.demand-demand-name-mini {
  color: #333333;
  font-size: 12px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  word-break: break-all;
  margin-top: 10px;
}
.demand-release-form {
  margin-top: 30px;
  /*width: 98%;*/
  text-align: right;
}
.demand-input {

}
.demand-btn {
  /*width: 60%;*/
  color: #FFFFFF;
  background-color: rgb(244,121,33);
  padding: 16px 40px;
  margin-right: 20px;
}
.release-btn {
  width: 100%;
  margin-right: 20px;
}
</style>
