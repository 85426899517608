<template>
  <div>
    <div class="partner-box" v-if="!miniStyle">
      <!--    各种合作伙伴详情页-->
      <el-row>
        <!--      左侧学校、公司信息-->
        <el-col :sm="{span:6,offset:1}" :md="{span:5,offset:2}" :lg="{span:4,offset:2}">
          <!--        logo卡片-->
          <el-card>
            <div class="logo-card-box">
              <img class="logo-pic" :src="partnerMsg.logo" alt="">
              <div :class="[smallFontFlag?'partner-name-mini':'partner-name']">{{partnerMsg.name}}</div>
              <el-link type="primary" class="partner-link" :href="partnerMsg.link">{{partnerMsg.link}}</el-link>
            </div>
          </el-card>
          <!--        条件筛选按钮-->
          <div>
            <div class="check-tt">条件筛选</div>
            <div class="check-btn-group">
              <el-button size="mini" class="check-btn-item" @click="checkPatent">专利</el-button>
              <el-button size="mini" class="check-btn-item" @click="checkAchievement">项目</el-button>
              <el-button size="mini" class="check-btn-item" @click="checkDemand">需求</el-button>
            </div>
          </div>
          <!--        学校、公司简介-->
          <div class="into-box">
            <div class="into-name">{{partnerMsg.name}}</div>
<!--            <div>{{partnerMsg.description}}</div>-->
            <div v-if="partnerType == 'school'">{{partnerMsg.description}}</div>
            <div v-else >{{partnerMsg.discription}}</div>
          </div>
        </el-col>
        <!--      右侧专利列表-->
        <el-col :sm="{span:15,offset:1}" :md="{span:14,offset:1}" :lg="{span:15,offset:1}">
          <div :class="[smallFontFlag?'list-tt-mini':'list-tt']">{{listTitle}}</div>
          <div v-if="getMsgType == 0">
            <div v-if="patentList.length">
              <PatentItem :patentList="patentList" :small-size="true"></PatentItem>
              <Paging
                  :total="pageTotal"
                  :pageSize="parameterObj.pageSize"
                  :currentPage="parameterObj.pageNum"
                  @changeCurrent="changeCurrent"
                  @changeSize="changeSize"
              ></Paging>
            </div>
            <div v-else>
              <div class="no-msg">~ 暂无数据 ~</div>
            </div>
          </div>
          <div v-if="getMsgType == 1">
            <div v-if="projectList.length">
              <AchievementsItem  :projectList="projectList" :small-size="true"></AchievementsItem>
              <Paging
                  :total="pageTotal"
                  :pageSize="parameterObj.pageSize"
                  :currentPage="parameterObj.pageNum"
                  @changeCurrent="changeCurrent"
                  @changeSize="changeSize"
              ></Paging>
            </div>
            <div v-else>
              <div class="no-msg">~ 暂无数据 ~</div>
            </div>
          </div>
          <div v-if="getMsgType == 2">
            <div v-if="demandList.length">
              <DemandItem  :demandList="demandList" :small-size="true"></DemandItem>
              <Paging
                  :total="pageTotal"
                  :pageSize="parameterObj.pageSize"
                  :currentPage="parameterObj.pageNum"
                  @changeCurrent="changeCurrent"
                  @changeSize="changeSize"
              ></Paging>
            </div>
            <div v-else>
              <div class="no-msg">~ 暂无数据 ~</div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <div v-else>
<!--      顶部图片-->
      <div :class="[topImgMove?'top-image-box-mini':'top-image-box']" >
        <img src="../assets/page_top_bg.png" alt="" class="top-image" >
        <div class="tt-box">
          <div >
            校企精准对接
          </div>
          <div class="title_after">
            倾力专利服务
          </div>
        </div>
      </div>
<!--      学校logo及资料-->
      <div>
        <el-row>
          <el-col :span="8">
              <div class="logo-box-mini">
                <img class="logo-pic" :src="partnerMsg.logo"  alt="">
                <div class="logo-name-mini">{{partnerMsg.name}}</div>
                <el-link :href="partnerMsg.link" type="primary" class="logo-link">{{partnerMsg.link}}</el-link>
              </div>
          </el-col>
          <el-col :span="16" >
            <div class="mini-partner-msg" v-if="partnerType == 'school'">{{partnerMsg.description}}</div>
            <div class="mini-partner-msg" v-else >{{partnerMsg.discription}}</div>
<!--            <div class="mini-partner-msg">华侨大学（Huaqiao University），校本部位于福建省泉州市，直属中央统战部领导，是周恩来总理亲自批准设立的中央部属高校，是中央统战部与国侨办、教育部、福建省共建的综合性大学，被中共中央确定为“国家重点扶植的大学”。</div>-->
          </el-col>
        </el-row>
      </div>
<!--      合作伙伴相关专利、需求、项目-->
      <div class="tab-box">
        <div class="check-btn-group">
          <el-button size="mini" type="primary" class="check-btn-item" @click="checkPatent">专利</el-button>
          <el-button size="mini" type="warning" class="check-btn-item" @click="checkAchievement">项目</el-button>
          <el-button size="mini" type="primary" class="check-btn-item" @click="checkDemand">需求</el-button>
        </div>
        <div v-if="getMsgType == 0">
          <div v-if="patentList.length">
            <PatentItem :patentList="patentList" :small-size="true"></PatentItem>
            <Paging
                :total="pageTotal"
                :pageSize="parameterObj.pageSize"
                :currentPage="parameterObj.pageNum"
                @changeCurrent="changeCurrent"
                @changeSize="changeSize"
            ></Paging>
          </div>
          <div v-else>
            <div class="no-msg">~ 暂无数据 ~</div>
          </div>
        </div>
        <div v-if="getMsgType == 1">
          <div v-if="projectList.length">
            <AchievementsItem  :projectList="projectList" :small-size="true"></AchievementsItem>
            <Paging
                :total="pageTotal"
                :pageSize="parameterObj.pageSize"
                :currentPage="parameterObj.pageNum"
                @changeCurrent="changeCurrent"
                @changeSize="changeSize"
            ></Paging>
          </div>
          <div v-else>
            <div class="no-msg">~ 暂无数据 ~</div>
          </div>
        </div>
        <div v-if="getMsgType == 2">
          <div v-if="demandList.length">
            <DemandItem  :demandList="demandList" :small-size="true"></DemandItem>
            <Paging
                :total="pageTotal"
                :pageSize="parameterObj.pageSize"
                :currentPage="parameterObj.pageNum"
                @changeCurrent="changeCurrent"
                @changeSize="changeSize"
            ></Paging>
          </div>
          <div v-else>
            <div class="no-msg">~ 暂无数据 ~</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Paging from "@/components/Paging";
import PatentItem from "@/components/PatentPage/PatentItem";
import DemandItem from "@/components/DemandPage/DemandItem";
import AchievementsItem from "@/components/AchievementsPage/AchievementsItem";
export default {
  name: "PartnerDetailPage",
  components:{
    Paging,
    // AchievementsList,
    // DemandList,
    PatentItem,
    DemandItem,
    AchievementsItem
  },
  data(){
    return {
      patentList:[],
      demandList:[],
      projectList:[],
      listTitle:'',
      getMsgType:0,//0：专利 1：成果 2：需求
      id:1,//公司ID
      pageTotal:0,//专利总条数
      parameterObj:{
        pageNum:1,
        pageSize:5
      },
      partnerMsg:{},
      partnerType:'',//判断合作伙伴是学校还是公司的标志
      screenWidth: document.body.clientWidth,//浏览器宽度
      miniStyle:false,//专利操作按钮
      smallFontFlag:false,//小文字标志
      topImgMove:false,
    }
  },
  created() {
    this.partnerType = this.$route.params.type;
    this.id = this.$route.params.id;
    if(this.partnerType == 'school') {
      // this.id = this.$route.query.item.id;
      //获取学校信息
      this.getSchoolMsg();
      //获取专利列表
      this.getSchoolPatentList();
    } else if(this.partnerType == 'company') {
      // this.id = this.$route.query.item.id;
      //获取公司信息
      this.getCompanyMsg();
      //获取公司专利列表
      this.getCompanyPatentList();
    } else {
      this.id = this.$route.query.item.value;
      //获取公司信息
      this.getCompanyMsg();
      // this.listTitle='关于'+this.$route.query.item.label+'的专利展示'
      //获取公司专利列表
      this.getCompanyPatentList();
    }
    //监控屏幕变化
    this.monitoringScreenWidth();
  },
  watch:{
    //监听浏览器宽度
    screenWidth() {
      this.monitoringScreenWidth();
    }
  },
  mounted() {
    const that = this
    window.addEventListener("resize", function() {
      return (() => {
        window.screenWidth= document.body.clientWidth;
        that.screenWidth= window.screenWidth;
      })();
    });
  },
  methods:{
    checkPatent(){
      this.listTitle='关于'+this.partnerMsg.name+'的专利展示'
      this.getMsgType = 0;//专利
      this.parameterObj.pageSize = 5;
      this.parameterObj.pageNum = 1;
      if(this.partnerType == 'school') {
        this.getSchoolPatentList();
      } else {
        this.getCompanyPatentList();
      }
    },
    checkAchievement() {
      this.listTitle='关于'+this.partnerMsg.name+'的项目展示'
      this.getMsgType = 1;//项目
      this.parameterObj.pageSize = 5;
      this.parameterObj.pageNum = 1;
      if(this.partnerType == 'school') {
        this.getSchoolProjectList();
      } else {
        this.getCompanyProjectList();
      }
    },
    checkDemand() {
      this.listTitle='关于'+this.partnerMsg.name+'的需求展示'
      this.getMsgType = 2;//需求
      this.parameterObj.pageSize = 5;
      this.parameterObj.pageNum = 1;
      if(this.partnerType == 'school') {
        this.getSchoolDemandList();
      } else {
        this.getCompanyDemandList();
      }
    },
    //获取学校的专利列表
    getSchoolPatentList() {
      let url = '/web/patent/school?id=' + this.id + '&pageNum='+ this.parameterObj.pageNum +'&pageSize=' + this.parameterObj.pageSize;
      this.getRequest(url).then(res=>{
        if(res.row.length) {
          this.patentList = res.row;
          this.pageTotal = res.totalCount;
          this.parameterObj.pageNum = res.pageNum;
          this.parameterObj.pageSize = res.pageSize;
        }
      })
    },
    //获取学校需求列表
    getSchoolDemandList() {
      this.getRequest('web/demand/school/list?pageNum='+this.parameterObj.pageNum+'&pageSize='+this.parameterObj.pageSize+'&schoolId=' + this.id).then(res=>{
        if(res.row.length) {
          this.demandList = res.row;
          this.pageTotal = res.totalCount;
          this.parameterObj.pageNum = res.pageNum;
          this.parameterObj.pageSize = res.pageSize;
        }
      })
    },
    //获取学校项目列表
    getSchoolProjectList() {
      let url = 'web/project/school/list?pageNum='+this.parameterObj.pageNum+'&pageSize='+this.parameterObj.pageSize+'&schoolId=' + this.id;
      this.getRequest(url).then(res=>{
        if(res.row.length) {
          this.projectList = res.row;
          this.pageTotal = res.totalCount;
          this.parameterObj.pageNum = res.pageNum;
          this.parameterObj.pageSize = res.pageSize;
        }
      })
    },
    //根据Id获取学校的信息
    getSchoolMsg() {
      this.getRequest('web/school/' + this.id).then(res=>{
        this.partnerMsg = res;
        this.listTitle='关于'+this.partnerMsg.name+'的专利展示'
      })
    },
    //根据Id获取公司信息
    getCompanyMsg() {
      this.getRequest('web/partner/' + this.id).then(res=>{
        this.partnerMsg = res;
        this.listTitle='关于'+this.partnerMsg.name+'的专利展示'
      })
    },
    //获取公司专利列表
    getCompanyPatentList() {
      this.getRequest('web/patent/partner?id='+ this.id +'&pageNum=' + this.parameterObj.pageNum+ '&pageSize=' + this.parameterObj.pageSize).then(res=>{
        this.patentList = res.row;
        this.pageTotal = res.totalCount;
        this.parameterObj.pageNum = res.pageNum;
        this.parameterObj.pageSize = res.pageSize;
      })
    },
    //获取公司需求列表
    getCompanyDemandList() {
      let url = 'web/demand/partner/list?pageNum='+ this.parameterObj.pageNum+'&pageSize='+ this.parameterObj.pageSize + '&partnerId=' + this.id;
      this.getRequest(url).then(res=>{
        this.demandList = res.row;
        this.pageTotal = res.totalCount;
        this.parameterObj.pageNum = res.pageNum;
        this.parameterObj.pageSize = res.pageSize;
      })
    },
    //获取公司项目列表
    getCompanyProjectList() {
      let url = 'web/project/partner/list?pageNum='+ this.parameterObj.pageNum+ '&pageSize=' + this.parameterObj.pageSize + '&partnerId=' + this.id;
      this.getRequest(url).then(res=>{
        this.projectList = res.row;
        this.pageTotal = res.totalCount;
        this.parameterObj.pageNum = res.pageNum;
        this.parameterObj.pageSize = res.pageSize;
      })
    },
    //请求另一页的数据
    changeCurrent(currentPage) {
      this.parameterObj.pageNum = currentPage;
      if(this.partnerType == 'school') {
        switch(this.getMsgType){
          case 0:
            //学校专利
              this.getSchoolPatentList();
            break
          case 1:
            //学校项目
              this.getSchoolProjectList();
            break
          case 2:
            //学校需求
              this.getSchoolDemandList();
            break
        }
      } else {
        switch(this.getMsgType){
          case 0:
            //公司专利
            this.getCompanyPatentList()
            break
          case 1:
            //公司项目
            this.getCompanyProjectList()
            break
          case 2:
            //公司需求
            this.getCompanyDemandList()
            break
        }
      }

    },
    //改变当前每页的数量
    changeSize(value) {
      this.parameterObj.pageSize = value;
      this.parameterObj.pageNum = 1;
      if(this.partnerType == 'school') {
        switch(this.getMsgType){
          case 0:
            //学校专利
            this.getSchoolPatentList();
            break
          case 1:
            //学校项目
            this.getSchoolProjectList();
            break
          case 2:
            //学校需求
            this.getSchoolDemandList();
            break
        }
      } else {
        switch(this.getMsgType){
          case 0:
            //公司专利
            this.getCompanyPatentList()
            break
          case 1:
            //公司项目
            this.getCompanyProjectList()
            break
          case 2:
            //公司需求
            this.getCompanyDemandList()
            break
        }
      }
    },
    //浏览器样式调整
    monitoringScreenWidth() {
      // xs 	<768px
      // sm 	≥768px
      // md 	≥992px
      // lg 	≥1200px
      // xl 	≥1920px
      // console.log(this.screenWidth)
      if(this.screenWidth < 1010) {
        this.smallFontFlag = true;
      }else {
        this.smallFontFlag = false;
      }
      if(this.screenWidth < 751) {
        this.miniStyle = true;
      }else {
        this.miniStyle = false;
      }
      if(this.screenWidth > 729) {
        this.topImgMove = false;
      }else {
        this.topImgMove = true;
      }

    }
  },
}
</script>

<style scoped>
.partner-box {
  margin-top: 20px;
}
.logo-card-box {
  text-align: center;
  color: #2954A2;
  font-size: 12px;
  font-family: 微软雅黑;
}

.logo-pic {
  width: 70%;
}
.partner-name {
  font-size: 20px;
  font-weight: bold;
  margin-top: 10px;
}
.partner-name-mini {
  font-size: 16px;
  font-weight: bold;
  margin-top: 10px;
}
.partner-link {
  margin-top: 10px;
  font-size: 12px;
}
/*条件筛选样式*/
.check-tt {
  background-color: #2954A2;
  color: #FFFFFF;
  font-size: 20px;
  margin-top: 20px;
  padding: 10px;
}
.check-btn-item{
  width: 100%;
  border-radius: 0;
  margin-left: 0 !important;
  color: #333333;

}
.check-btn-group {
  display: flex;
}
.into-box {
  background-color: #2954A2;
  color: #FFFFFF;
  font-family: 微软雅黑;
  padding: 20px;
  font-size: 14px;
  margin-bottom: 20px;
}
.into-name {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 10px;
}
/*专利列表*/
.list-tt {
  background-color: #2954A2;
  color: #FFFFFF;
  font-size: 20px;
  font-family: 微软雅黑;
  font-weight: bold;
  padding: 14px;
}
.list-tt-mini {
  background-color: #2954A2;
  color: #FFFFFF;
  font-size: 14px;
  font-family: 微软雅黑;
  font-weight: bold;
  padding: 10px;
}
/*分页固定*/
/*.page-box {*/
/*  position: absolute;*/
/*  bottom: 0;*/
/*}*/
.no-msg {
  font-size: 14px;
  color: #666666;
  background-color: #efefef;
  padding: 20px;
  text-align: center;
  margin-top: 10px;
}
.top-image {
  width:100%;
  height: 160px;
}
.top-image-box {
  position: relative;
  margin-top: 0px;
}
.top-image-box-mini {
  position: relative;
  top: -22px;
}
.tt-box{
  color: #DCD6D6;
  font-size: 26px;
  font-weight: bold;
  font-family: 微软雅黑;
  position: absolute;
  padding: 10px;
  top: 40%;
  left: 6%;
  transform: translate(0,-50%);
}
.title_after {
  margin-left: 80px;
}
.logo-box-mini {
  background-color: #FFFFFF;
  text-align: center;
  padding: 10px;
  color: #2954A2;
  font-size: 12px;
  font-family: 微软雅黑;
  box-shadow: 0px 2px 10px #ddd;
  position: relative;
  margin-top: -50px;
  margin-left: 10px;
}
.mini-partner-msg {
  font-size: 12px;
  color: #2954A2;
  padding: 0px 20px 0px 20px;
  /*letter-spacing:2px;*/
  line-height:150%
}
.logo-name-mini {
  font-size: 14px;
  color: #2954A2;
  font-weight: bold;
}
.logo-link {
  font-size: 10px;
  margin-top: 8px;
}
.tab-box {
  margin: 16px 10px 0 10px;
}
</style>
