<template>
  <div>
    <el-row>
      <el-col :span="18" :offset="3">
        <ModuleTitle title="项目列表"></ModuleTitle>
        <div v-if="projectList.length">
          <AchievementsItem :project-list="projectList"></AchievementsItem>
        </div>
        <div v-else>
          <div class="no-msg-box">暂无匹配项目</div>
        </div>
        <ModuleTitle title="专利列表"></ModuleTitle>
        <div v-if="patentList.length">
          <PatentItem :patent-list="patentList"></PatentItem>
        </div>
        <div v-else>
          <div class="no-msg-box">暂无匹配专利</div>
        </div>
        <ModuleTitle title="需求列表"></ModuleTitle>
        <div v-if="demandList.length" style="margin-bottom: 30px">
          <DemandItem :demandList="demandList"></DemandItem>
        </div>
        <div v-else>
          <div class="no-msg-box">暂无匹配需求</div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import AchievementsItem from "../components/AchievementsPage/AchievementsItem";
import DemandItem from "../components/DemandPage/DemandItem";
import PatentItem from "../components/PatentPage/PatentItem";
import ModuleTitle from "../components/HomePage/ModuleTitle";
export default {
  name: "SearchResult",
  components:{
    AchievementsItem,
    DemandItem,
    PatentItem,
    ModuleTitle
  },
  data() {
    return {
      keyWord:'',
      patentList:[],
      projectList:[],
      demandList:[]
    }
  },
  created() {
    this.keyWord = this.$route.params.searchKey;
    this.getSearchResult();
    // console.log(this.$route.params.searchKey)
  },
  watch:{
    '$route' () {
      this.patentList = [];
      this.projectList = [];
      this.demandList = [];
      this.keyWord = this.$route.params.searchKey;
      this.getSearchResult();
    }
  },
  methods:{
    getSearchResult() {
      this.getRequest('web/globe/?record=' + this.keyWord).then(res=>{
        // console.log(res)
        if(res) {
          res.forEach(item=>{
            if(item.type === 0) {
              this.patentList.push(item.object);
            }else if(item.type === 1) {
              this.projectList.push(item.object);
            }else if(item.type === 2) {
              this.demandList.push(item.object);
            }
          })
        }
      })
    }
  }

}
</script>

<style scoped>
.no-msg-box {
  background-color: #efefef;
  text-align: center;
  color: #888;
  font-size: 12px;
  padding: 20px;
  margin: 10px 20px 10px 20px;
}
</style>
