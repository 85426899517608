<template>
  <div>
<!--    我是公司简介-->
<!--    标题-->
    <div class="tt-box">
      <div :class="[miniStyle?'chinese-tt-mini':'chinese-tt']">环宇通简介</div>
      <div :class="[miniStyle?'english-tt-mini':'english-tt']">introduction</div>
    </div>
<!--    简介内容-->
    <div :class="[miniStyle?'profile-tx-mini':'profile-tx']">
      {{companyInfo.content}}
    </div>
<!--    公司图片-->
    <div class="company-pic-box">
      <el-row>
<!--        <el-col :span="8" v-for="(item,index) in companyInfo.pictureVoList" :key="index">-->
<!--          <img class="company-pic" :src="item.url" alt="公司图片">-->
<!--          <div class="company-tx">{{item.name}}</div>-->
<!--        </el-col>-->
        <el-col :span="8" v-for="(item,index) in companyInfo.pictureVoList" :key="index" :gutter="10">
          <div v-if="index<3">
            <img class="company-pic" :src="item.url" alt="公司图片">
            <div :class="[miniStyle?'company-tx-mini':'company-tx']">环宇通图片</div>
          </div>
        </el-col>
      </el-row>
    </div>
<!--    公司文化定位-->
    <div class="company-cultural-box">
      <div :class="[miniStyle?'company-cultural-item-mini':'company-cultural-item']">
        <div class="vertical-start">
          <div :class="[miniStyle?'item-icon-mini':'item-icon']"><i class="el-icon-aim"></i></div>
          <div :class="[miniStyle?'item-name-mini':'item-name']">企业使命</div>
        </div>
        <div :class="[miniStyle?'item-tt-mini':'item-tt']">{{companyInfo.location}}</div>
      </div>

      <div :class="[miniStyle?'company-cultural-item-mini':'company-cultural-item']">
        <div class="vertical-start">
          <div :class="[miniStyle?'item-icon-mini':'item-icon']"><i class="el-icon-medal"></i></div>
          <div :class="[miniStyle?'item-name-mini':'item-name']">企业愿景</div>
        </div>
        <div :class="[miniStyle?'item-tt-mini':'item-tt']">{{companyInfo.mission}}</div>
      </div>

      <div :class="[miniStyle?'company-cultural-item-mini':'company-cultural-item']">
        <div class="vertical-start">
          <div :class="[miniStyle?'item-icon-mini':'item-icon']"><i class="el-icon-trophy"></i></div>
          <div :class="[miniStyle?'item-name-mini':'item-name']">企业价值观</div>
        </div>
        <div :class="[miniStyle?'item-tt-mini':'item-tt']">{{companyInfo.culture}}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CompanyProfile",
  props:['companyInfo'],
  data(){
    return {
      screenWidth: document.body.clientWidth,//浏览器宽度
      // HamburgerBtnFlag:false,//是否显示汉堡按钮
      miniStyle:false,//手机样式
    }
  },
  created() {
    this.monitoringScreenWidth();
  },
  mounted() {
    const that = this
    window.addEventListener("resize", function() {
      return (() => {
        window.screenWidth= document.body.clientWidth;
        that.screenWidth= window.screenWidth;
      })();
    });
  },
  watch:{
    //监听浏览器宽度
    screenWidth() {
      this.monitoringScreenWidth();
    }
  },
  methods: {
    //浏览器样式调整
    monitoringScreenWidth() {
      // xs 	<768px
      // sm 	≥768px
      // md 	≥992px
      // lg 	≥1200px
      // xl 	≥1920px
      // console.log(this.screenWidth)
      // if(this.screenWidth < 1173) {
      //   this.HamburgerBtnFlag = true;
      // }else {
      //   this.HamburgerBtnFlag = false;
      // }
      if(this.screenWidth < 620) {
        this.miniStyle = true;
      }else {
        this.miniStyle = false;
      }
    }
  }
}
</script>

<style scoped>
.tt-box {
  text-align: center;
  margin-top: 20px;
}
.chinese-tt {
  font-size: 24px;
  font-weight: bold;
  font-family: 微软雅黑;
  color: #343434;
}
.chinese-tt-mini {
  font-size: 18px;
  font-weight: bold;
  font-family: 微软雅黑;
  color: #343434;
}
.english-tt {
  color: #ADADAD;
  font-size: 20px;
  letter-spacing: 5px;
  font-weight: bold;
  margin-top: 10px;
 }
.english-tt-mini {
  color: #ADADAD;
  font-size: 16px;
  letter-spacing: 5px;
  font-weight: bold;
  margin-top: 5px;
 }
.profile-tx {
  color: #727171;
  font-size: 18px;
  margin-top: 10px;
  padding: 20px;
}
.profile-tx-mini {
  color: #727171;
  font-size: 14px;
  margin-top: 10px;
  padding: 20px;
}
.company-pic-box {
  /*padding: 20px;*/
  text-align: center;

}
.company-pic {
  max-width: 90%;
  max-height: 230px;
}
.company-tx {
  font-family: 微软雅黑;
  color: #727171;
  font-size: 12px;
  margin-top: 10px;
}
.company-tx-mini {
  font-family: 微软雅黑;
  color: #727171;
  font-size: 10px;
  margin-top: 8px;
}
.company-cultural-box {
  margin-top: 30px;
}
.company-cultural-item {
  margin-left: 20px;
  line-height: 50px;
  margin-top: 20px;
}
.company-cultural-item-mini {
  margin-left: 10px;
  line-height: 40px;
}
.item-icon {
  font-size: 32px;
  color: #2954A2;
  font-weight: bolder;
}
.item-icon-mini {
  font-size: 22px;
  color: #2954A2;
  font-weight: bolder;
}
.item-name {
  font-size: 16px;
  font-family: 微软雅黑;
  color: #343434;
  margin-left: 10px;
  margin-right: 10px;
  font-weight: bold;
}
.item-name-mini {
  font-size: 14px;
  font-family: 微软雅黑;
  color: #343434;
  margin-left: 8px;
  margin-right: 8px;
  font-weight: bold;
}
.item-tt {
  font-size: 14px;
  color: #727171;
  font-family: 微软雅黑;
  margin-left: 10px;
}
.item-tt-mini {
  font-size: 12px;
  color: #727171;
  font-family: 微软雅黑;
  margin-left: 8px;
}
</style>
