<template>
  <div class="modular-tt">
    <span :class="[miniStyle?'modular-tt-strong-mini':'modular-tt-strong']">{{title}}</span>/<el-button type="text" @click="gotoMore">MORE+</el-button>
    <div class="decoration-box">
      <div class="decoration-line"></div>
      <div class="decoration-icon"><i class="el-icon-s-promotion"></i></div>
      <div class="decoration-line"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ModuleTitle",
  props: ['title'],
  data() {
    return {
      screenWidth: document.body.clientWidth,//浏览器宽度
      HamburgerBtnFlag:false,//是否显示汉堡按钮
      miniStyle:false,//手机样式
    }
  },
  created() {
    this.monitoringScreenWidth();
  },
  mounted() {
    const that = this
    window.addEventListener("resize", function() {
      return (() => {
        window.screenWidth= document.body.clientWidth;
        that.screenWidth= window.screenWidth;
      })();
    });
  },
  methods: {
    gotoMore() {

    },
    //浏览器样式调整
    monitoringScreenWidth() {
      if(this.screenWidth < 1173) {
        this.HamburgerBtnFlag = true;
        if(this.screenWidth < 730) {
          this.miniStyle = true;
        }else {
          this.miniStyle = false;
        }
      }else {
        this.HamburgerBtnFlag = false;
        this.miniStyle = false;
      }
    }
  },
  watch:{
    //监听浏览器宽度
    screenWidth() {
      this.monitoringScreenWidth();
    }
  },
}
</script>

<style scoped>
.modular-tt {
  color: #2954A2;
  font-size: 20px;
  text-decoration: none;
  text-align: center;
  margin-top: 40px;
}
.modular-tt-strong {
  font-size: 24px;
  font-weight: bold;
}
.modular-tt-strong-mini {
  font-size: 18px;
  font-weight: bold;
}

.decoration-box {
  display: flex;
  justify-content: center;
  align-items: center;
}
.decoration-line {
  width: 100px;
  height: 2px;
  background-color: #F47921;
}
.decoration-icon {
  color: #F47921;
  margin: 0 10px;
}
</style>