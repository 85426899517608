<template>
    <div>
<!--      <div v-if="smallSize">-->
<!--        <div-->
<!--            class="patent-item"-->
<!--            v-for="(item,index) in patentList"-->
<!--            :key="index"-->
<!--            @click="goDetailPage(item.id)"-->
<!--        >-->
<!--          <el-row>-->
<!--            <el-col :span="4" class="vertical-center">-->
<!--              <div class="blue-circle" v-if="item.patentTypeName.length>2" style="font-size: 16px">{{ item.patentTypeName }}</div>-->
<!--              <div class="blue-circle" v-else>{{ item.patentTypeName }}</div>-->
<!--            </el-col>-->
<!--            <el-col :span="15">-->
<!--              <div>-->
<!--                <div class="patent-item-name">-->
<!--                  {{item.name}}-->
<!--                  <span class="hot-icon" v-if="item.isHot"><i class="el-icon-collection-tag"></i>hot</span>-->
<!--                </div>-->
<!--                <div class="text-font">-->
<!--                  <span> <i class="el-icon-document"></i></span>-->
<!--                  分类: {{item.typeName}}-->
<!--                </div>-->
<!--                <div class="text-font">-->
<!--                  <el-row>-->
<!--                    <el-col :span="4">-->
<!--                      <i class="el-icon-message"></i> 私信-->
<!--                    </el-col>-->
<!--                    <el-col :span="9">-->
<!--                      <i class="el-icon-sell"></i>-->
<!--                      交易方式: {{item.cooperationName}}-->
<!--                    </el-col>-->
<!--                    <el-col :span="7">-->
<!--                      <i class="el-icon-s-operation"></i>-->
<!--                      成熟度: {{item.maturityName}}-->
<!--                    </el-col>-->
<!--                  </el-row>-->
<!--                </div>-->
<!--              </div>-->
<!--            </el-col>-->
<!--            <el-col :span="5">-->
<!--              <div v-if="item.price == -1">-->
<!--                <span class="price-font">面议</span>-->
<!--              </div>-->
<!--              <div v-else :class="[item.status == 1?'move-up':'','price-font']">-->
<!--                {{item.price}}-->
<!--                <span class="unit-font" v-if="item.price != -1">万元</span>-->
<!--              </div>-->
<!--              &lt;!&ndash;          状态标志&ndash;&gt;-->
<!--              <div class="status-flag" v-if="item.status == 1">-->
<!--                &lt;!&ndash;              已结束标志&ndash;&gt;-->
<!--                <div class="border-out">-->
<!--                  <div class="border-inside">-->
<!--                    - 已结算 - -->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </el-col>-->
<!--          </el-row>-->
<!--        </div>-->
<!--      </div>-->
      <div>
        <div
            class="patent-item"
            v-for="(item,index) in patentList"
            :key="index"
            @click="patentItemClick(item.id)"
        >
          <el-row>
            <el-col  :xs="4" :sm="4" class="vertical-center">
              <div style="color: #fff">.</div>
              <div :class="[miniStyle?'blue-circle-mini':'blue-circle',miniStyle?'patent-long-font-mini':'patent-long-font']" v-if="item.patentTypeName.length>2">{{ item.patentTypeName }}</div>
              <div :class="[miniStyle?'blue-circle-mini':'blue-circle']" v-else>{{ item.patentTypeName }}</div>
<!--              <div :class="[miniStyle?'blue-circle-mini':'blue-circle']">专利</div>-->
            </el-col>
            <el-col  :xs="15" :sm="11" :md="11" >
              <div>
                <div :class="[HamburgerBtnFlag?'patent-item-name-mini':'patent-item-name']">
                  {{item.name}}
                  <span :class="[HamburgerBtnFlag?'hot-icon-mini':'hot-icon']" v-if="item.isHot"><i class="el-icon-collection-tag"></i>hot</span>
                  <span v-if="item.isTop"><el-tag class="tag-box" size="mini">TOP</el-tag></span>
                </div>
                <div v-if="!miniBtnFlag">
                  <div :class="[miniStyle?'text-font-mini':'text-font']">
                    <span> <i class="el-icon-document"></i></span>
                    分类: {{item.typeName}}
                  </div>
                  <div :class="[miniStyle?'text-font-mini':'text-font']">
                    <el-row>
                      <el-col :span="4" v-if="privateLetter">
                        <i class="el-icon-message"></i> 私信
                      </el-col>
                      <el-col :xs="12" :sm="12" :md="12" :lg="10">
                        <i class="el-icon-sell" v-if="!HamburgerBtnFlag"></i>
                        交易方式: {{item.cooperationName}}
                      </el-col>
                      <el-col :xs="12" :sm="12" :md="12" :lg="10">
                        <i class="el-icon-s-operation" v-if="!HamburgerBtnFlag"></i>
                        成熟度: {{item.maturityName}}
                      </el-col>
                    </el-row>
                  </div>
                </div>
                <div v-else class="mini-msg-font">
                  {{item.typeName}}/{{item.cooperationName}}/{{item.maturityName}}
                </div>
              </div>
            </el-col>
            <el-col v-if="!bindBtn" :sm="{span:3,offset:0}" :xs="{span:5,offset:0}">
              <div :class="[miniStyle && !miniBtnFlag?'move-right-style':'']">
                <div v-if="item.price == -1" :class="[miniBtnFlag?'price-font-mini':'price-font']">
                  <span>面议</span>
                </div>
                <div v-else :class="[miniBtnFlag?'price-font-mini':'price-font']">
                  {{item.price}}
                  <span :class="[miniBtnFlag?'unit-font-mini':'unit-font']" v-if="item.price != -1">万元</span>
                </div>
              </div>
            </el-col>
            <el-col :sm="{span:6,offset:0}" :xs="{span:0,offset:0}" v-if="!miniStyle">
              <div v-if="!editBtn">
                <div v-if="bindBtn" class="bindBtn-box my-detail-btn">
                  <el-button v-if="bindBtn == 1" type="warning" @click="relievePatent(item.id)">解除绑定</el-button>
                  <el-button v-if="bindBtn == 2"  type="success" @click="bindPatent(item.id)">绑定专利</el-button>
                  <el-button v-if="bindBtn == 3"  type="primary" @click="goDetailPage(item.id)">了解详情 <i class="el-icon-search"></i></el-button>
                </div>
                <div v-else  class="detail-btn">
                  <div style="color: #FFFFFF">.</div>
                  <el-button type="primary"  @click="goDetailPage(item.id)">了解详情</el-button>
                </div>
              </div>
<!--              <el-button-group class="detail-btn" v-else>-->
<!--                <el-button type="primary" size="mini" icon="el-icon-search"  @click="goMyDetailPage(item.id)">详情</el-button>-->
<!--                <el-button type="primary" size="mini" @click="editMyPatent(item)">编辑<i class="el-icon-edit el-icon&#45;&#45;right"></i></el-button>-->
<!--                <el-button type="primary" size="mini" @click="deleteMyPatent(item)">删除<i class="el-icon-delete el-icon&#45;&#45;right"></i></el-button>-->
<!--              </el-button-group>-->
              <div class="detail-vertical-btn detail-btn" v-else>
                <div><el-button type="primary" size="mini" icon="el-icon-search"  @click="goMyDetailPage(item.id)">详情</el-button></div>
                <div><el-button type="primary" size="mini" @click="editMyPatent(item)">编辑<i class="el-icon-edit el-icon--right"></i></el-button></div>
                <div><el-button type="primary" size="mini" @click="deleteMyPatent(item)">删除<i class="el-icon-delete el-icon--right"></i></el-button></div>
              </div>
            </el-col>
          </el-row>
          <div v-if="miniStyle">
            <div v-if="!editBtn">
              <div v-if="bindBtn" class="bindBtn-box mini-btn">
                <el-button v-if="bindBtn == 1" size="mini" type="warning" @click="relievePatent(item.id)">解除</el-button>
                <el-button v-if="bindBtn == 2" size="mini" type="success" @click="bindPatent(item.id)">绑定</el-button>
              </div>
            </div>
            <div class="demand-operation-btn" v-else>
              <el-button type="primary" size="mini" icon="el-icon-search"  @click="goMyDetailPage(item.id)">详情</el-button>
              <el-button type="warning" size="mini" @click="editMyPatent(item)">编辑<i class="el-icon-edit el-icon--right"></i></el-button>
              <el-button type="danger" size="mini" @click="deleteMyPatent(item)">删除<i class="el-icon-delete el-icon--right"></i></el-button>
            </div>
          </div>
        </div>
      </div>
<!--      发布专利的编辑框-->
      <el-dialog title="专利信息编辑" :visible.sync="dialogFormVisible" :width="dialogWidth">
        <UploadPatent
            v-if="dialogFormVisible"
            :patentEditObj="patentEditObj"
            @dialogCancel="dialogCancel"
            @updateList="patentEditObjSubmit"
        ></UploadPatent>
      </el-dialog>
      <el-dialog
          title="提示"
          :visible.sync="deleteDialogVisible"
          :width="dialogWidth">
        <span>您是否要删除这条专利</span>
        <span slot="footer" class="dialog-footer">
        <el-button @click="deleteCancel">取 消</el-button>
        <el-button type="primary" @click="deleteSubmit">确 定</el-button>
      </span>
      </el-dialog>
    </div>
</template>

<script>
import UploadPatent from "@/components/PatentPage/UploadPatent";
export default {
  name: "PatentItem",
  props:['patentList','editBtn','smallSize','bindBtn','noJustDetail'],
  components:{
    UploadPatent
  },
  data() {
    return {
      // patentItem_list:[],
      dialogFormVisible:false,//编辑对话框打开与否
      patentEditObj:{

      },//需要编辑的专利对象
      formLabelWidth:'120px',//编辑对话框提示文字的长度
      dialogImageUrl: '',//图片
      dialogVisible: false,
      deleteDialogVisible:false,//删除对话框
      deleteId:null,
      HamburgerBtnFlag:false,//是否显示汉堡按钮
      miniStyle:false,//手机样式
      activeName:'classification',
      screenWidth: document.body.clientWidth,//浏览器宽度
      miniBtnFlag:false,//专利操作按钮
      privateLetter:true,//私信隐藏
      dialogWidth:'50%'
    }
  },

  created(){
    this.dictionaryQueryProcessing();
    //监控屏幕变化
    this.monitoringScreenWidth();
  },
  watch:{
    patentList() {
      this.dictionaryQueryProcessing();
    },
    //监听浏览器宽度
    screenWidth() {
      this.monitoringScreenWidth();
    }
  },
  mounted() {
    const that = this
    window.addEventListener("resize", function() {
      return (() => {
        window.screenWidth= document.body.clientWidth;
        that.screenWidth= window.screenWidth;
      })();
    });
  },
  methods:{
    goDetailPage(id){
      if(this.$cookies.get('user')){
        this.$router.push({name:'PatentDetail',query: {id:id}})
      }else {
        this.$message({
          message:'登录成为会员才能查看更多信息哦！',
          type:'warning'
        })
      }
    },
    patentItemClick(id) {
      //卡片点击方法只有点击按钮不生效才能起作用
      if(this.miniStyle && !this.noJustDetail) {
        if(this.$cookies.get('user')){
          this.$router.push({name:'PatentDetail',query: {id:id}})
        }else {
          this.$message({
            message:'登录成为会员才能查看更多信息哦！',
            type:'warning'
          })
        }
      }
    },
    goMyDetailPage(id) {
      this.$router.push({name:'PatentDetail',query: {id:id}})
    },
    //数据的字典查询
    dictionaryQueryProcessing() {
      this.patentList.forEach(item=>{
        //分类名称处理
        item.typeName = this.getTypeName(item.typeId);
        //获取成熟度分类
        item.maturityName = this.getMaturityName(item.maturity)
        //合作方式
        item.cooperationName = this.geCooperationName(item.cooperationType)
        //专利类型
        item.patentTypeName = this.getPatentTypeName(item.type);
      })
    },
    //打开编辑对话框
    editMyPatent(item) {
      this.patentEditObj = item;
      this.dialogFormVisible = true;
    },
    //图片的方法
    handleRemove(file) {
      console.log(file);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleDownload(file) {
      console.log(file);
    },
    //关闭编辑对话框
    dialogCancel() {
      this.dialogFormVisible = false;
    },
    patentEditObjSubmit() {
      this.dialogFormVisible = false;
      //刷新数据
      this.$emit('updatePatent')
    },
    //删除
    deleteMyPatent(item) {
      this.deleteDialogVisible = true;
      this.deleteId = item.id;
    },
    //删除取消
    deleteCancel() {
      this.deleteDialogVisible = false;
      this.deleteId = null;
      this.$message({
          type: 'info',
          message: '已取消删除'
        });
    },
    deleteSubmit() {
      this.deleteDialogVisible = false;
      this.deleteRequest('web/patent/' + this.deleteId).then(res=>{
         if(res.code == 200) {
           this.$message({
             type: 'success',
             message: '删除成功'
           });
           this.$emit('updatePatent')
         }
      })
    },
    //绑定该专利->向父组件传递要绑定的专利的Id
    bindPatent(id) {
      this.$emit('bindingPatent',id);
    },
    //解除绑定
    relievePatent(id) {
      this.$emit('relievePatent',id)
    },
    //浏览器样式调整
    monitoringScreenWidth() {
      // xs 	<768px
      // sm 	≥768px
      // md 	≥992px
      // lg 	≥1200px
      // xl 	≥1920px
      // console.log(this.screenWidth)
      if(this.screenWidth < 1274) {
        this.HamburgerBtnFlag = true;
        if(this.screenWidth < 900) {
          this.miniStyle = true;
        }else {
          this.miniStyle = false;
        }
      }else {
        this.HamburgerBtnFlag = false;
        this.miniStyle = false;
      }
      if(this.screenWidth < 585) {
        this.miniBtnFlag = true;
      }else {
        this.miniBtnFlag = false;
      }
      if(this.screenWidth >1200) {
        this.privateLetter = true;
      }else {
        this.privateLetter = false;
      }
      if(this.screenWidth < 1123) {
        this.dialogWidth ='80%';
      }else {
        this.dialogWidth = '50%';
      }
    }


  }
}
</script>

<style scoped>
.patent-item {
  padding: 20px;
  box-shadow:2px 2px 5px #aaa;
  margin-top: 20px;
}
.blue-circle {
  height:68px;
  width: 68px;
  border-radius: 50%;
  background-color: #2954A2;
  color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
}
.blue-circle-mini {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: #2954A2;
  color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
}
.patent-item-name {
  font-size: 20px;
  font-weight: bold;
  color: #222222;
}
.patent-item-name-mini {
  font-size: 14px;
  font-weight: bold;
  color: #222222;
}
.hot-icon {
  font-size: 16px;
  color: #FD3A0C;
}
.hot-icon-mini {
  font-size: 12px;
  color: #FD3A0C;
}
.text-font {
  color: #666666;
  font-size: 14px;
  margin-top: 10px;
}
.text-font-mini {
  color: #666666;
  font-size: 12px;
  margin-top: 5px;
}
.mini-msg-font {
  color: #666666;
  font-size: 11px;
  margin-top: 5px;
}
.text-font i {
  font-weight: bold;
}
.move-up {
  margin-top: -20px;
}
.status-flag {
  position: absolute;
  top:16px;
  z-index: -1;
  right: 30px;
}
.border-out {
  border: 4px solid #DCD6D6;
  border-radius: 50%;
  padding: 5px;
}
.border-inside {
  border: 2px solid #DCD6D6;
  border-radius: 50%;
  font-size: 20px;
  color: #DCD6D6;
  text-align: center;
  font-weight: bolder;
  padding: 10px;
}
.price-font {
  font-size: 20px;
  color: #FD3A0C;
  font-weight: bold;
  position: relative;
  top: 50%; /*偏移*/
  transform: translateY(50%);
  text-align: center;
}
.price-font-mini {
  font-size: 16px;
  color: #FD3A0C;
  font-weight: bold;
  position: relative;
  top: 50%; /*偏移*/
  text-align: center;
}
.unit-font {
  font-size: 14px;
}
.unit-font-mini {
  font-size: 10px;
}
.detail-btn {
  position: relative;
  top: 50%; /*偏移*/
  /*transform: translateY(50%);*/
  text-align: center;
}
.my-detail-btn {
  position: relative;
  top: 50%; /*偏移*/
  transform: translateY(50%);
  text-align: center;
}
.detail-vertical-btn {
  text-align: center;
}
.detail-vertical-btn button{
  margin-top: 2px;
}
.bindBtn-box {
  text-align: right;
}
.mini-btn {
  float: right;
  margin-top: 0px;
  position: relative;
  top: 50%; /*偏移*/
  transform: translateY(-130%);
}
.move-right-style {
  position: absolute;
  right: 5%;
}
.demand-operation-btn {
  text-align: center;
  margin-top: 10px;
}
.patent-long-font {
  font-size: 14px!important;
}
.patent-long-font-mini {
  font-size: 12px !important;
}
.tag-box {
  position: absolute;
  /*background: #92b7bd;*/
  background-color: rgb(245,108,108);
  z-index: 999;
  border-radius: 3px;
  border: none;
  color: #fff;
  font-size: 70%;
  padding: 2px 4px 0;
  top: -20px;
  left: -25px;
  line-height: 1.4;
  -ms-transform: rotate(-7deg);
  -webkit-transform: rotate(-7deg);
  transform: rotate(-7deg);
  transition: transform 0.1s ease-in;
}
</style>
