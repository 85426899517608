<template>
  <div>
    <el-row>
      <Classification @setRequestParameter="getRequestParameter"></Classification>
      <el-col :span="18" :offset="3">
        <AchievementsItem :projectList="projectList"></AchievementsItem>
        <!--        分页-->
        <Paging
            :total="pageTotal"
            :pageSize="parameterObj.pageSize"
            :currentPage="parameterObj.pageNum"
            @changeCurrent="changeCurrent"
            @changeSize="changeSize"
        ></Paging>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import Classification from "../components/PatentPage/Classification";
import AchievementsItem from "@/components/AchievementsPage/AchievementsItem";
import Paging from "@/components/Paging";
export default {
  name: "AchievementsPage",
  components:{
    Classification,
    AchievementsItem,
    Paging
  },
  data() {
    return {
      pageTotal:0,//专利总条数
      parameterObj:{
        pageNum:1,
        pageSize:5
      },
      baseUrl:'web/project/list?',
      projectList:[],//项目列表
    }
  },
  created() {
    this.getProjectList(this.baseUrl);
  },
  methods :{
    //获取到参数对象
    getRequestParameter(data) {
      this.parameterObj = data;
      this.handleParameterURL();
    },
    // url处理
    handleParameterURL() {
      let reqUrl = this.baseUrl;
      let obj = Object.entries(this.parameterObj);
      obj.forEach( (item,index) =>{
        if(index == 0) {
          reqUrl = reqUrl + item[0] + '=' + item[1]
        } else {
          reqUrl = reqUrl + '&' + item[0] + '=' + item[1]
        }
      })
      // console.log(reqUrl)
      this.getProjectList(reqUrl);
    },
    //请求另一页的数据
    changeCurrent(currentPage) {
      this.parameterObj.pageNum = currentPage;
      this.handleParameterURL();
    },
    //改变当前每页的数量
    changeSize(value) {
      this.parameterObj.pageSize = value;
      this.parameterObj.pageNum = 1;
      this.handleParameterURL();
    },
    //获取所有专利列表
    getProjectList(url) {
      this.patentListFlag = false;
      this.getRequest(url).then(res =>{
        // console.log('专利列表请求到的数据')
        this.projectList = res.row;
        this.pageTotal = res.totalCount;
        this.parameterObj.pageNum = res.pageNum;
        this.parameterObj.pageSize = res.pageSize;
        this.patentListFlag = true;
      })
    },
  }
}
</script>

<style scoped>
.page-box {
  margin: 30px;
  text-align: right;
}
</style>