<template>
  <div :class="[miniStyle?'top-image-box-mini':'top-image-box']">
    <img src="../../assets/page_top_bg.png" alt="" :class="[miniStyle?'top-image-mini':'top-image']" >
    <div :class="[miniStyle?'tt-box-mini':'tt-box']">
      <div class="title_front">
        {{titleFront}}
      </div>
      <div :class="[miniStyle?'title_after-mini':'title_after']">
        {{titleAfter}}
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "TopImage",
  props:['titleFront','titleAfter'],
  data() {
    return {
      screenWidth: document.body.clientWidth,//浏览器宽度
      HamburgerBtnFlag:false,//是否显示汉堡按钮
      miniStyle:false,//手机样式
    }
  },
  created() {
    this.monitoringScreenWidth();
  },
  mounted() {
    const that = this
    window.addEventListener("resize", function() {
      return (() => {
        window.screenWidth= document.body.clientWidth;
        that.screenWidth= window.screenWidth;
      })();
    });
  },
  watch:{
    //监听浏览器宽度
    screenWidth() {
      this.monitoringScreenWidth();
    }
  },
  methods:{
    //浏览器样式调整
    monitoringScreenWidth() {
      if(this.screenWidth < 1173) {
        this.HamburgerBtnFlag = true;
        if(this.screenWidth < 730) {
          this.miniStyle = true;
        }else {
          this.miniStyle = false;
        }
      }else {
        this.HamburgerBtnFlag = false;
        this.miniStyle = false;
      }
    }
  }
}
</script>

<style scoped>
.top-image-box {
  position: relative;
  margin-top: 0px;
}
.top-image-box-mini {
  position: relative;
  top: -22px;
}
.tt-box{
  color: #DCD6D6;
  font-size: 36px;
  font-weight: bold;
  font-family: 微软雅黑;
  position: absolute;
  padding: 20px;
  top: 42%;
  left: 12%;
  transform: translate(0,-50%);
}
.tt-box-mini{
  color: #DCD6D6;
  font-size: 28px;
  font-weight: bold;
  font-family: 微软雅黑;
  position: absolute;
  padding: 10px;
  top: 50%;
  left: 12%;
  transform: translate(0,-50%);
}
.title_after {
  margin-left: 80px;
  font-size: 36px;
  color: rgba(220,214,214,0.2);
}
.title_after-mini {
  margin-left: 40px;
  font-size: 25px;
  color: rgba(220,214,214,0.2);
}
.top-image {
  width:100%;
  height:210px;
}
.top-image-mini {
  width:100%;
  height: 160px;
}
</style>