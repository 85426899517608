<template>
  <div>
<!--    我是入驻信息模块-->
    <el-row>
      <el-col
          :sm="{span:18,offset:3}"
          :ms="{span:24}"
      >
        <div :class="[miniStyle?'settled-data-box-mini':'settled-data-box']">
          <div :class="[miniStyle?'date-tt-mini':'date-tt']">截至<span class="date-strong">{{todayDate}}</span></div>
          <el-row>
            <el-col
                :span="6"
                v-for="(item,index) in SettledData"
                :key="index"
            >
              <div :class="miniStyle?'SettledData-item-mini':'SettledData-item'">
                <div class="icon-box" v-if="!miniStyle"><i :class="[item.icon,'SettledData-i']"></i></div>
<!--                名称-->
                <div class="SettledData-tt">
                  <i :class="[item.icon,'SettledData-i-mini']" v-if="miniStyle"></i>
                  {{item.name}}
                </div>
<!--                真实数据-->
<!--                <div><span class="SettledData-number">{{item.value}}</span>{{item.unit}}</div>-->
<!--                好看数据-->
                <div><span class="SettledData-number">{{item.defaultVal}}</span>{{item.unit}}</div>
              </div>
            </el-col>
          </el-row>

        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "SettledData",
  data() {
    return {
      todayDate:'今日',
      SettledData: [
        {
          icon:'el-icon-menu',
          name:'签约项目',
          value:0,
          defaultVal:'89+',
          color:'#70AEEB',
          unit:'项'
        },
        {
          icon:'el-icon-s-management',
          name:'入驻学校',
          value:0,
          defaultVal:'35+',
          color:'#FF9D66',
          unit:'家'
        },
        {
          icon:'el-icon-s-marketing',
          name:'专利数量',
          value:0,
          defaultVal:'1000+',
          color:'#FDAED3',
          unit:'项'
        },
        {
          icon:'el-icon-s-custom',
          name:'合作企业',
          value:0,
          defaultVal:'500+',
          color:'#FF6968',
          unit:'家'
        }
      ],
      screenWidth: document.body.clientWidth,//浏览器宽度
      HamburgerBtnFlag:false,//是否显示汉堡按钮
      miniStyle:false,//手机样式
    }
  },
  created() {
    this.getDateNow();
    this.getAllPatentNumb();
    this.getAllPartnerNumb();
    this.getAllSchoolNumb();
    this.getAllProjectNumb();
    this.monitoringScreenWidth();
  },
  mounted() {
    this.designColor();
    const that = this
    window.addEventListener("resize", function() {
      return (() => {
        window.screenWidth= document.body.clientWidth;
        that.screenWidth= window.screenWidth;
      })();
    });
  },
  methods:{
    designColor() {
      let that = this;
      let domObjs = document.getElementsByClassName('icon-box')
      Array.prototype.forEach.call(domObjs, function (element,index) {
        element.style.backgroundColor = that.SettledData[index].color;
      });
    },
    //获取最新日期
    getDateNow() {
      let nowDate = new Date();
      let year = nowDate.getFullYear();
      let month = nowDate.getMonth() + 1 < 10 ? "0" + (nowDate.getMonth() + 1)
          : nowDate.getMonth() + 1;
      let day = nowDate.getDate() < 10 ? "0" + nowDate.getDate() : nowDate
          .getDate();
      let dateStr = year + "年" + month + "月" + day + "日";
      this.todayDate = dateStr;
    },
    //获取专利数据
    getAllPatentNumb() {
      this.getRequest('web/patent/num').then(res=>{
        this.SettledData[2].value = res;
      })
    },
    //所有合作伙伴数量
    getAllPartnerNumb() {
      this.getRequest('web/partner/num').then(res=>{
        this.SettledData[3].value = res;
      })
    },
    //学校
    getAllSchoolNumb() {
      this.getRequest('web/school/num').then(res=>{
        this.SettledData[1].value = res;
      })
    },
    //项目
    getAllProjectNumb() {
      this.getRequest('web/project/num').then(res=>{
        this.SettledData[0].value = res;
      })
    },
    //浏览器样式调整
    monitoringScreenWidth() {
      if(this.screenWidth < 1173) {
        this.HamburgerBtnFlag = true;
        if(this.screenWidth < 730) {
          this.miniStyle = true;
        }else {
          this.miniStyle = false;
        }
      }else {
        this.HamburgerBtnFlag = false;
        this.miniStyle = false;
      }
    }
  },
  watch:{
    //监听浏览器宽度
    screenWidth() {
      this.monitoringScreenWidth();
    }
  },
}
</script>

<style scoped>
  .settled-data-box {
    background:url("../../assets/data_bg.jpg");
    width: 100%;
    height: 280px;
    background-size:100% 100%;
    color: #666666;
    position: relative;
    top: -20px;
  }
  .settled-data-box-mini {
    background:url("../../assets/data_bg.jpg");
    width: 100%;
    height: 180px;
    background-size:100% 100%;
    color: #666666;
    position: relative;
    top: -20px;
  }
  .date-tt {
    font-size: 24px;
    color: #2F2F2F;
    text-align: center;
    padding-top: 10px;
  }
  .date-tt-mini {
    font-size: 18px;
    color: #2F2F2F;
    text-align: center;
    padding-top: 10px;
  }
  .date-strong {
    font-weight: bold;
    color: #2954A2;
    margin-left: 10px;
  }
  .SettledData-item {
    text-align: center;
    font-size: 20px;
  }
  .SettledData-item-mini {
    text-align: center;
    font-size: 14px;
    margin-top: 40px;
  }
  .icon-box {
    width:80px;
    height: 80px;
    border-radius: 50%;
    background-color: #70AEEB;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 30px auto;
  }

  .SettledData-i {
    color: white;
    font-size: 36px;
  }
  .SettledData-i-mini {
    color: #2954A2;
    font-size: 20px;
  }
  .SettledData-tt {
    margin-bottom: 10px;
    margin-top: -10px;
  }
  .SettledData-number {
    font-weight: bold;
    color: #2954A2;
    margin-right: 4px;
  }
</style>
