<template>
  <div>
    <el-row>
      <el-col :xs="{span:24,offset:0}"  :sm="{span:20,offset:2}"  :md="{span:18,offset:3}">
        <!--    顶部图片跟用户基本信息-->
        <div>
          <img :class="[miniStyle?'myInfo-top-image-mini':'myInfo-top-image']" src="../assets/page_top_bg.png" alt="">
          <div :class="[miniStyle?'top-info-mini':'top-info']" >
            <div>
              <span :class="[miniStyle?'userName-mini':'userName']">
              {{userInfo.name}}
              </span>
            </div>
            <div :class="[miniStyle?'small-gray-front-mini':'small-gray-front']">
              您上传了 <router-link to="/my_patent" class="link-font">{{patentNumb}}个专利</router-link>，
              上传了 <router-link to="/my_project" class="link-font">{{projectNumb}}个项目</router-link>，
              发布了 <router-link to="/my_demand" class="link-font">{{demandNumb}}个需求</router-link>
            </div>
          </div>
        </div>
<!--        个人资料卡片-->
        <div class="module-box">
          <div :class="[miniStyle?'describe-tt-mini':'describe-tt']">
            基本信息
          </div>
          <!--          内容-->
          <el-card class="company-box">
            <el-row v-if="!miniStyle">
              <el-col :span="12">
                <div class="vertical-space-between">
                  <ul>
                    <li :class="[miniStyle?'li-font-mini':'li-font']">
                      <i class="el-icon-s-custom li-icon"></i>姓名：{{userInfo.name}}
                    </li>
                    <li :class="[miniStyle?'li-font-mini':'li-font']">
                      <i class="el-icon-user li-icon"></i>性别：
                      <span>{{userInfo.sexName}}</span>
                    </li>
                    <li :class="[miniStyle?'li-font-mini':'li-font']">
                      <i class="el-icon-mobile-phone li-icon"></i>联系电话：{{userInfo.phone}}
                    </li>
                    <li :class="[miniStyle?'li-font-mini':'li-font']">
                      <i class="el-icon-mobile-phone li-icon"></i>座机号码：{{userInfo.telLine}}
                    </li>
                  </ul>
                </div>
              </el-col>
              <el-col :span="12">
                <div class="vertical-space-between">
                  <ul>
                    <li :class="[miniStyle?'li-font-mini':'li-font']">
                      <i class="el-icon-message li-icon"></i>绑定邮箱：{{userInfo.email}}
                    </li>
                    <li :class="[miniStyle?'li-font-mini':'li-font']">
                      <i class="el-icon-postcard li-icon"></i>身份证号码：{{userInfo.idCard}}
                    </li>
                    <li :class="[miniStyle?'li-font-mini':'li-font']">
                      <i class="el-icon-location-outline li-icon"></i>地址：{{userInfo.address}}
                    </li>
                    <li class="li-btn" :class="[miniStyle?'li-font-mini':'li-font']">
                      <el-button class="edit-btn" type="text" @click="editMyInfo">编辑资料<i class="el-icon-edit"></i></el-button>
                    </li>
                  </ul>
                </div>
              </el-col>
            </el-row>
            <el-row v-if="miniStyle">
              <el-col :span="22" :offset="1">
                <div class="vertical-space-between">
                  <ul>
                    <li :class="[miniStyle?'li-font-mini':'li-font']">
                      <i class="el-icon-s-custom li-icon"></i>姓名：{{userInfo.name}}
                    </li>
                    <li :class="[miniStyle?'li-font-mini':'li-font']">
                      <i class="el-icon-user li-icon"></i>性别：
                      <span>{{userInfo.sexName}}</span>
                    </li>
                    <li :class="[miniStyle?'li-font-mini':'li-font']">
                      <i class="el-icon-mobile-phone li-icon"></i>联系电话：{{userInfo.phone}}
                    </li>
                    <li :class="[miniStyle?'li-font-mini':'li-font']">
                      <i class="el-icon-mobile-phone li-icon"></i>座机号码：{{userInfo.telLine}}
                    </li>
                    <li :class="[miniStyle?'li-font-mini':'li-font']">
                      <i class="el-icon-message li-icon"></i>绑定邮箱：{{userInfo.email}}
                    </li>
                    <li :class="[miniStyle?'li-font-mini':'li-font']">
                      <i class="el-icon-postcard li-icon"></i>身份证号码：{{userInfo.idCard}}
                    </li>
                    <li :class="[miniStyle?'li-font-mini':'li-font']">
                      <i class="el-icon-location-outline li-icon"></i>地址：{{userInfo.address}}
                    </li>
                  </ul>
                  <el-button class="mini-edit-btn" type="text" @click="editMyInfo">编辑资料<i class="el-icon-edit"></i></el-button>
                </div>
              </el-col>
            </el-row>
          </el-card>
        </div>
<!--        公司信息-->
        <div class="module-box">
          <div :class="[miniStyle?'describe-tt-mini':'describe-tt']">
            单位信息
          </div>
          <!--          内容-->
          <el-card class="company-box" v-for="(item,index) in userInfo.partnerVoList" :key="index">
            <div class="vertical-space-between">
              <!--                单位信息-->
              <div class="small-tx-font">
                <!--                  标题-->
                <div :class="[miniStyle?'company-box-tt-mini':'company-box-tt']"><i class="el-icon-office-building"></i>{{item.label}}</div>
              </div>
              <!--                操作按钮-->
              <div>
                <el-button v-if="!miniStyle" type="primary" @click="goCompanyDetail(item)">进入单位</el-button>
                <el-button v-else size="mini" type="primary" @click="goCompanyDetail(item)">进入单位</el-button>
              </div>
            </div>
          </el-card>
          <div :class="[miniStyle?'describe-tt-mini':'describe-tt']">
            学校信息
          </div>
          <!--          内容-->
          <el-card class="company-box" v-for="(item,index) in userInfo.schoolVoList" :key="index">
            <div class="vertical-space-between">
              <!--                单位信息-->
              <div class="small-tx-font">
                <!--                  标题-->
                <div :class="[miniStyle?'company-box-tt-mini':'company-box-tt']"><i class="el-icon-office-building"></i>{{item.label}}</div>
              </div>
              <!--                操作按钮-->
              <div>
                <el-button v-if="!miniStyle" type="primary" @click="goSchoolDetail(item)">进入单位</el-button>
                <el-button v-else size="mini" type="primary" @click="goSchoolDetail(item)">进入单位</el-button>
              </div>
            </div>
          </el-card>
        </div>
      </el-col>
    </el-row>
    <!--          打开编辑对话框-->
    <el-dialog title="个人资料编辑" :visible.sync="dialogFormVisible" :width="dialogWidth">
      <el-form :model="userForm" ref="userForm" :rules="rules" size="mini">
        <el-row>
          <el-col :xs="24" :sm="24" :md="12">
            <el-form-item label="姓名" :label-width="formLabelWidth" prop="name">
              <el-input v-model="userForm.name" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="性别" :label-width="formLabelWidth">
              <el-select v-model="userForm.sex" placeholder="请选择性别">
                <el-option
                    v-for="item in genderDict"
                    :key="item.dictValue"
                    :label="item.dictLabel"
                    :value="item.dictValue">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="联系电话" :label-width="formLabelWidth" prop="phone">
              <el-input v-model="userForm.phone" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="座机号码 " :label-width="formLabelWidth">
              <el-input v-model="userForm.telLine" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col  :xs="24" :sm="24" :md="12">
            <el-form-item label="绑定邮箱" :label-width="formLabelWidth" prop="email">
              <el-input v-model="userForm.email" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="身份证号" :label-width="formLabelWidth" prop="idCard">
              <el-input v-model="userForm.idCard" autocomplete="off" ></el-input>
            </el-form-item>
            <el-form-item label="地址" :label-width="formLabelWidth">
              <el-input  type="textarea" v-model="userForm.address" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="公司" :label-width="formLabelWidth">
              <el-select v-model="userForm.partnerVoList" multiple placeholder="请选择所属公司">
                <el-option
                    v-for="item in companyDict"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="学校" :label-width="formLabelWidth">
              <el-select v-model="userForm.schoolVoList" multiple placeholder="请选择所属学校">
                <el-option
                    v-for="item in schoolDict"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitEdit">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "MyInfo",
  data() {
    //手机号正则表达式
    let reg=/^(((13[0-9]{1})|(15[0-9]{1})|(16[0-9]{1})|(17[3-8]{1})|(18[0-9]{1})|(19[0-9]{1})|(14[5-7]{1}))+\d{8})$/
    let validatorPhone = function (phone, value, callback) {
      if (value === '') {
        callback(new Error('手机号不能为空'))
      } else if (!reg.test(value)) {
        callback(new Error('手机号格式错误'))
      } else {
        callback()
      }
    }

    //邮箱正则表达式
    let emailReg=/^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
    let validatorEmail = function (email, value, callback) {
      if (value === '') {
        callback(new Error('邮箱不能为空'))
      } else if (!emailReg.test(value)) {
        callback(new Error('邮箱格式错误'))
      } else {
        callback()
      }
    }
    //身份证号正则表达式
    let IdCardReg = /^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/
    let validatorIdCard = function (code, value, callback) {
      if (value === '') {
        callback(new Error('身份证号不能为空'))
      } else if (!IdCardReg.test(value)) {
        callback(new Error('身份证号输入错误'))
      } else {
        callback()
      }
    }
    return {
      userInfo:{},
      dialogFormVisible: false,
      formLabelWidth:'80px',
      patentNumb:0,
      demandNumb:0,
      projectNumb:0,
      userForm:{
        company:''
      },
      //性别字典
      genderDict:[],
      //公司字典
      companyDict:[],
      //学校字典
      schoolDict:[],
      //表单验证
      rules:{
        name:[
          { required: true, message: '名字不能为空', trigger: 'blur' },
          { min: 2, max: 25, message: '长度在 2 到 25 个字符', trigger: 'blur' }
        ],
        phone:[
          { required: true,validator: validatorPhone, trigger: 'blur' }
        ],
        email:[
          { required: true,validator: validatorEmail, trigger: 'blur' }
        ],
        idCard:[
          { required: true,validator: validatorIdCard, trigger: 'blur' }
        ]
      },
      screenWidth: document.body.clientWidth,//浏览器宽度
      dialogWidth:'50%',//控制编辑框宽度
      miniStyle:false,//手机样式

    }
  },
  created() {
    this.userInfo = this.$cookies.get('user');
    this.genderDict = this.$store.state.genderDict;
    this.getUserInfo();
    //监控屏幕变化
    this.monitoringScreenWidth();
  },
  mounted() {
    this.getPatentNumb();
    this.getDemandNumb();
    this.getProjectNumb();
    const that = this
    window.addEventListener("resize", function() {
      return (() => {
        window.screenWidth= document.body.clientWidth;
        that.screenWidth= window.screenWidth;
      })();
    });
  },
  watch:{
    //监听浏览器宽度
    screenWidth() {
      this.monitoringScreenWidth();
    },
  },
  methods: {
    //请求用户的所有专利数
    getPatentNumb() {
      this.getRequest('web/patent/user?id=' + this.userInfo.id + '&pageNum=1&pageSize=5').then(res=>{
        if(res.totalCount) {
          this.patentNumb = res.totalCount;
        }
      })
    },
    //请求用户的所有需求数
    getDemandNumb() {
      this.getRequest('web/demand/user/list?pageSize=5&pageNum=1&userId=' + this.userInfo.id).then(res=>{
        if(res.totalCount) {
          this.demandNumb = res.totalCount;
        }
      })
    },
    getProjectNumb() {
      this.getRequest('web/project/user?pageNum=1&pageSize=5&userId=' + this.userInfo.id).then(res=>{
        if(res.totalCount) {
          this.projectNumb = res.totalCount;
        }
      })
    },
    //打开编辑窗口
    editMyInfo() {
      this.getRequest('web/user/' + this.userInfo.id).then(res=>{
        this.userForm = res;
        this.companyDict = this.$store.state.companyDict;
        this.schoolDict = this.$store.state.schoolDict;
        // let partnerVoList = [];
        // let len = this.userForm.partnerVoList.length;
        // for(let i = 0; i < len ; i++) {
        //   partnerVoList[i] = this.userForm.partnerVoList[i].value;
        // }
        // this.userForm.partnerVoList = partnerVoList;
        // console.log(this.userForm.partnerVoList)

        this.userForm.partnerVoList.forEach((item,index)=>{
          this.userForm.partnerVoList[index] = item.value;
        })
        this.userForm.schoolVoList.forEach((item,index)=>{
          this.userForm.schoolVoList[index] = item.value;
        })
        this.userForm.sex  += "";
        this.dialogFormVisible = true;
      })

    },
    //获取个人信息
    getUserInfo() {
      this.getRequest('web/user/' + this.userInfo.id).then(res=>{
        this.userInfo = res;
        this.$cookies.set('user',res)
        let sexValue = this.userInfo.sex;
        this.userInfo.sexName = this.genderDict.find(function (item){
          return item.dictValue == sexValue;
        },sexValue).dictLabel;
      })
    },
    //提交编辑请求
    submitEdit() {
      this.$refs['userForm'].validate((valid) => {
        if (valid) {
          this.dialogFormVisible = false;
          this.userForm.partnerVoList.forEach((item,index)=>{
            this.userForm.partnerVoList[index] = {'value':item}
          })
          this.userForm.schoolVoList.forEach((item,index)=>{
            this.userForm.schoolVoList[index] = {'value':item}
          })
          this.putRequest('web/user/update',this.userForm).then(res=>{
            if(res.code == 200) {
              this.getUserInfo();
              this.$message({
                message:'编辑成功！',
                type:'success'
              })
            }
          })
        }
      })

    },
    //跳转到公司详情页
    goCompanyDetail(item) {
      this.$router.push({
        path: `/partner_detailPage/${item.value}/company`,
      })
    },
    goSchoolDetail(item) {
      this.$router.push({
        path: `/partner_detailPage/${item.value}/school`,
      })
    },
    //浏览器样式调整
    monitoringScreenWidth() {
      // xs 	<768px
      // sm 	≥768px
      // md 	≥992px
      // lg 	≥1200px
      // xl 	≥1920px
      // console.log(this.screenWidth)
      if(this.screenWidth < 625) {
        this.miniStyle = true;
      }else {
        this.miniStyle = false;
      }
      if(this.screenWidth < 720) {
        this.dialogWidth ='90%';
      }else {
        this.dialogWidth = '50%';
      }
      if(this.screenWidth < 725) {
        this.formLabelWidth = 0
      }else {
        this.formLabelWidth = '120px'
      }
    }
  }
}
</script>

<style scoped>
.myInfo-top-image {
  width: 100%;
  height: 130px;
  margin-top: 10px;
  position: relative;
}
.myInfo-top-image-mini {
  width: 100%;
  height: 130px;
  position: relative;
  top:-22px;
}
.top-info {
  color: #FFFFFF;
  position: absolute;
  top:16px;
  width: 100%;
  padding: 20px;
  margin-left: 20px;
}
.top-info-mini {
  color: #FFFFFF;
  position: absolute;
  top:0px;
  width: 100%;
  padding: 10px;
  margin-left: 10px;
}
.userName {
  font-size: 32px;
  font-weight: bold;
}
.userName-mini {
  font-size: 22px;
  font-weight: bold;
}
.level-front {
  color: #FEAF0A;
  font-size: 12px;
}
.vipIcon {
  font-size: 20px;
}
.small-gray-front {
  font-size: 14px;
  margin-top: 10px;
  color: #cccccc;
}
.small-gray-front-mini {
  font-size: 11px;
  margin-top: 10px;
  color: #cccccc;
}
.describe-tt {
  font-size: 20px;
  font-family: 方正大黑简体;
  color: #2F2F2F;
  margin-top: 20px;
  padding-left: 10px;
  border-left: 6px solid #2954A2;
}
.describe-tt-mini {
  font-size: 16px;
  font-family: 方正大黑简体;
  color: #2F2F2F;
  margin-top: 16px;
  padding-left: 10px;
  border-left: 6px solid #2954A2;
}
.company-box {
  margin-top: 20px;
}
.company-box-tt {
  color: #2C2C2C;
  font-family: 方正大黑简体;
  font-size: 18px;
  margin-bottom: 10px;
}
.company-box-tt-mini {
  color: #2C2C2C;
  font-family: 方正大黑简体;
  font-size: 14px;
}
.company-box-msg {
  color: #2C2C2C;
  font-size: 14px;
  font-weight: bold;
}
.module-box {
  margin-bottom: 20px;
}
.li-icon {
  margin-right: 10px;
}
.li-font {
  list-style-type:none;
  margin-top: 10px;
  font-size: 16px;
  color: #666666;
}
.li-font-mini {
  list-style-type:none;
  margin-top: 10px;
  font-size: 12px;
  color: #666666;
}
.li-btn {
  /*position: relative;*/
  text-align: right;
}
.edit-btn {
  left: 200px;
}
.mini-edit-btn {
  position: absolute;
  top: 0;
  right: 0;
}
.link-font {
  color: #FD3A0C;
  font-weight: bold;
}
</style>
