<template>
  <div>
    <el-row>
      <el-col :xs="{span:20,offset:2}" :sm="{span:18,offset:3}" :md="{span:16,offset:4}">
        <div>
          <!--    我是最新活动-->
          <!--          标题-->
          <ModuleTitle title="智能匹配"></ModuleTitle>
          <!--          tab标签页-->
<!--          <TabAssembly :activeName="tabList[0].id" :tabList="tabList" :moreBtn="false"></TabAssembly>-->
          <!--          内容区域-->
          <div>
            <el-row :gutter="10">
              <el-col :span="12" v-for="(item,index) in serverList" :key="index" >
                <div
                    class="intelligent-item vertical-center"
                    :class="[miniStyle?'intelligent-item-mini':'intelligent-item-big']"
                >
                  <div :class="[miniStyle?'intelligent-icon-mini':'intelligent-icon']"><i :class="item.icon"></i></div>
                  <div>
                    <div :class="[miniStyle?'intelligent-name-mini':'intelligent-name']">{{item.role}}</div>
                    <el-button  v-if="!miniStyle" round class="intelligent-btn" @click="goMatchPage(item)">更多服务</el-button>
                    <el-button
                        round
                        class="intelligent-btn-mini"
                        @click="goMatchPage(item)"
                        v-if="miniStyle"
                        size="mini"
                    >
                      更多服务
                    </el-button>
                  </div>
                </div>
              </el-col>
            </el-row>
          </div>

        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import ModuleTitle from "./ModuleTitle";
// import TabAssembly from "./TabAssembly";
export default {
  name: "IntelligentMatching",
  components:{
    ModuleTitle,
    // TabAssembly
  },
  data() {
    return {
      tabList:[
        {
          label:'用户场景化',
          id:'1022'
        },
        {
          label:'知识产权声明周期',
          id:'1021'
        },
      ],
      serverList:[
        {
          role:'科研人员',
          icon:'el-icon-user',
          id:'61',
          color:'#FDAED3'
        },
        {
          role:'管理人员',
          icon:'el-icon-s-operation',
          id:'62',
          color: '#72D8A8'
        },
        {
          role:'创业者',
          icon:'el-icon-s-shop',
          id:'63',
          color: '#70AEEB'
        },
        {
          role:'企业主',
          icon:'el-icon-s-flag',
          id:'64',
          color: '#FEAF0A'
        },
        {
          role:'高校学生',
          icon:'el-icon-s-custom',
          id:'65',
          color: '#FF6968'
        },
        {
          role:'猜你想要',
          icon:'el-icon-menu',
          id:'66',
          color: '#FF9D66'
        }
      ],
      screenWidth: document.body.clientWidth,//浏览器宽度
      HamburgerBtnFlag:false,//是否显示汉堡按钮
      miniStyle:false,//手机样式
    }
  },
  created() {
    this.monitoringScreenWidth();
  },
  mounted() {
    this.designColor();
    const that = this
    window.addEventListener("resize", function() {
      return (() => {
        window.screenWidth= document.body.clientWidth;
        that.screenWidth= window.screenWidth;
      })();
    });
  },
  watch:{
    //监听浏览器宽度
    screenWidth() {
      this.monitoringScreenWidth();
    }
  },
  methods:{
    designColor() {
      let that = this;
      let domObjs = document.getElementsByClassName('intelligent-item')
      Array.prototype.forEach.call(domObjs, function (element,index) {
        element.style.backgroundColor = that.serverList[index].color;
      });
      let domBtns = document.getElementsByClassName('intelligent-btn')
      Array.prototype.forEach.call(domBtns, function (element,index) {
        element.style.backgroundColor = that.serverList[index].color;
      });
      let domMiniBtns = document.getElementsByClassName('intelligent-btn-mini')
      Array.prototype.forEach.call(domMiniBtns, function (element,index) {
        element.style.backgroundColor = that.serverList[index].color;
      });
    },
    goMatchPage(item) {
      this.$router.push({name:'IntelligentMatchDetailPage',query:{matchObj:item}});
    },
    //浏览器样式调整
    monitoringScreenWidth() {
      // xs 	<768px
      // sm 	≥768px
      // md 	≥992px
      // lg 	≥1200px
      // xl 	≥1920px
      if(this.screenWidth < 1173) {
        this.HamburgerBtnFlag = true;
        if(this.screenWidth < 1019) {
          this.miniStyle = true;
        }else {
          this.miniStyle = false;
        }
      }else {
        this.HamburgerBtnFlag = false;
        this.miniStyle = false;
      }
    }
  }
}
</script>

<style scoped>
.intelligent-item {
  margin-bottom: 30px;
}
.intelligent-item-mini {
  padding: 10px;
}
.intelligent-item-big {
  /*height: 200px;*/
  padding: 20px;
}
.left-box {
  margin-right: 30px;
  margin-bottom: 30px;
}
.right-box {
  margin-left: 30px;
  margin-bottom: 30px;
}
.intelligent-icon {
  font-size: 120px;
  color: #FFFFFF;
  margin-right: 20px;
}
.intelligent-icon-mini {
  font-size: 45px;
  color: #FFFFFF;
  margin-right: 10px;
}
.intelligent-name {
  font-size: 36px;
  font-weight: bold;
  color: #FFFFFF;
}
.intelligent-name-mini {
  font-size: 16px;
  font-weight: bold;
  color: #FFFFFF;
}
.intelligent-btn {
  color: #FFFFFF;
  border: 2px solid #FFFFFF;
  width: 100%;
  margin-top: 10px;
}
.intelligent-btn-mini {
  color: #FFFFFF;
  border: 2px solid #FFFFFF;
  width: 100%;
  margin-top: 10px;
  font-size: 10px;
  padding: 0px;
}
</style>