<template>
  <div>
    <div class="issue-release-btn">
      <el-button v-if="!miniStyle" type="warning" @click="issueRelease()">发布问题</el-button>
      <el-button v-else size="mini" type="warning" @click="issueRelease()">发布问题</el-button>
    </div>
    <div class="AandQ-box" v-if="servicesList">
      <!--    问答列表页面-->
      <el-card v-for="(item,index) in servicesList" :key="index" class="AandQ-card">
        <!--      问题-->
        <div class="question-box">
          <el-row>
            <el-col :span="2"><el-button :class="[miniStyle?'Q-btn-mini':'Q-btn']" >问</el-button></el-col>
            <el-col :xs="22" :sm="19">
              <span v-if="!miniStyle" class="Q-user">
                <span v-if="item.user.name != '美一知识产权平台'">
                  {{item.user.name.charAt(0)}} * {{item.user.name.substr(-1)}} :
                </span>
                <span v-else>
                  {{item.user.name}}:
                </span>
              </span>
              <div v-else class="Q-user-mini">{{item.user.name}} :<span class="Q-time-mini"><i class="el-icon-time"></i> {{item.addTime}}</span></div>
              <el-link :underline="false" :class="[miniStyle?'Q-font-mini':'Q-font']" @click="answer(item)">{{item.content}}</el-link>
              <span v-if="!miniStyle" class="Q-time" ><i class="el-icon-time"></i> {{item.addTime}}</span>
            </el-col>
            <el-col :xs="0" :sm="3"><el-button size="mini" type="primary" @click="answer(item)">回答</el-button></el-col>
          </el-row>
        </div>
        <!--      回答-->
        <div :class="[miniStyle?'A-box-mini':'A-box']" class="question-box">
          <el-row>
            <el-col :span="2"><el-button :class="[miniStyle?'A-btn-mini':'A-btn']" >答</el-button></el-col>
            <el-col :span="22">
              <div v-if="item.childrenList.length">
                <div v-if="!miniStyle">
                  <span class="A-name">
                    <span v-if="item.childrenList[0].user.name != '美一知识产权平台'">
                      {{item.childrenList[0].user.name.charAt(0)}} * {{item.childrenList[0].user.name.substr(-1)}} :
                    </span>
                    <span v-else>
                      {{item.childrenList[0].user.name}}:
                    </span>
                  </span>
                  <span class="A-tx">{{item.childrenList[0].content}}</span>
                  <span class="Q-time">{{item.childrenList[0].addTime}}</span>
                </div>
                <div v-else>
                  <div class="A-name-mini">
                    <span v-if="item.childrenList[0].user.name != '美一知识产权平台'">
                      {{item.childrenList[0].user.name.charAt(0)}} * {{item.childrenList[0].user.name.substr(-1)}} :
                    </span>
                    <span v-else>
                      {{item.childrenList[0].user.name}}:
                    </span>

                    <span class="Q-time-mini">{{item.childrenList[0].addTime}}</span></div>
                  <span class="A-tx">{{item.childrenList[0].content}}</span>
                </div>
              </div>

              <div v-else>
                <div class="noMsg-small">暂无回答，快来发表您的看法。</div>
              </div>
            </el-col>
            <el-col :span="22" :offset="2" v-if="showAllFlag && activeFlag == index" >
              <div class="answer-item" v-for="(answerItem,answerIndex) in item.childrenList" :key="answerIndex">
                <div v-if="answerIndex != 0">
                  <div v-if="!miniStyle">
                    <span class="A-name">
                      <span v-if="answerItem.user.name != '美一知识产权平台'">
                        {{answerItem.user.name.charAt(0)}} * {{answerItem.user.name.substr(-1)}} :
                      </span>
                      <span v-else>
                        {{answerItem.user.name}}:
                      </span>

                    </span>
                    <span class="A-tx">{{answerItem.content}}</span>
                    <span class="Q-time">{{answerItem.addTime}}</span>
                  </div>
                  <div v-else>
                    <div class="A-name-mini">

                      <span v-if="answerItem.user.name != '美一知识产权平台'">
                        {{answerItem.user.name.charAt(0)}} * {{answerItem.user.name.substr(-1)}} :
                      </span>
                      <span v-else>
                        {{answerItem.user.name}}:
                      </span>

                      <span class="Q-time-mini">{{answerItem.addTime}}</span></div>
                    <span class="A-tx">{{answerItem.content}}</span>
                  </div>
<!--                  <span :class="[miniStyle?'A-name-mini':'A-name']">{{answerItem.user.name}}:</span>-->
<!--                  <span class="A-tx">{{answerItem.content}}</span>-->
<!--                  <span :class="[miniStyle?'Q-time-mini':'Q-time']">[{{answerItem.addTime}}]</span>-->
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
        <!--      全部回答-->
        <div v-if="activeFlag != index && item.childrenList.length > 1">
          <el-row>
            <el-col :span="22" :offset="2">
              <el-button :class="[miniStyle?'all-tx-mini':'all-tx']" type="text" @click="showAllAnswer(index)">
                全部{{item.childrenList.length}}个回答
                <i class="el-icon-arrow-right"></i>
              </el-button>
            </el-col>
          </el-row>
        </div>
        <div v-if="activeFlag == index && item.childrenList.length > 1">
          <el-row>
            <el-col :span="22" :offset="2">
              <el-button :class="[miniStyle?'pack-up-tx-mini':'pack-up-tx']" type="text" @click="packUpAnswer()">
                收起回答
                <i class="el-icon-arrow-up"></i>
              </el-button>
            </el-col>
          </el-row>
        </div>
      </el-card>
      <el-dialog
          :title="answerForm.pid?'回答问题':'发布问题'"
          center
          :visible.sync="dialogVisible"
          width="50%">
        <div class="">
          <el-form :model="answerForm">
            <el-form-item>
              <el-input  type="textarea" v-model="answerForm.content" autocomplete="off" clearable></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="submitAnswer">确 定</el-button>
        </div>
      </el-dialog>
    </div>
    <div v-else class="noMsg">
      暂无数据~
    </div>
  </div>
</template>

<script>
export default {
  name: "AandQItem",
  props:['servicesList'],
  data() {
    return {
      showAllFlag:false,
      activeFlag:-1,
      answerObj:{},//被回答的对象
      dialogVisible:false,//评论对话框
      answerForm:{
        // "userId": "1",
        // "content":"这是一条评论",
        // "pid": 1,
        // "addTime":"2020-11-11",
        // "status": 0
      },
      formLabelWidth:'120px',
      userId:null,
      screenWidth: document.body.clientWidth,//浏览器宽度
      HamburgerBtnFlag:false,//是否显示汉堡按钮
      miniStyle:false,//手机样式
    }
  },
  created() {
    if(this.$cookies.isKey('user') ) {
      this.userId = this.$cookies.get('user').id;
    }
    this.monitoringScreenWidth();
  },
  mounted() {
    const that = this
    window.addEventListener("resize", function() {
      return (() => {
        window.screenWidth= document.body.clientWidth;
        that.screenWidth= window.screenWidth;
      })();
    });
  },
  watch:{
    //监听浏览器宽度
    screenWidth() {
      this.monitoringScreenWidth();
    }
  },
  methods:{
    showAllAnswer(index) {
      this.showAllFlag = true;
      this.activeFlag = index;
    },
    packUpAnswer() {
      this.showAllFlag = true;
      this.activeFlag = -1;
    },
    //回答问题
    answer(item) {
      if(this.userId) {
        if(this.userId !== item.userId) {
          this.resetForm();
          this.answerObj = item;
          this.dialogVisible = true;
          this.answerForm.pid = item.id;
        } else {
          this.$message({
            message: "暂不支持回答自问自答操作。",
            type: 'warning'
          });
        }

      }else {
        this.$message({
          message: "只有登录状态下才能回复！",
          type: 'warning'
        });
      }
    },
    //发布问题
    issueRelease() {
      if(this.userId) {
        this.resetForm();
        this.answerForm.pid = 0;
        this.dialogVisible = true;
      }else {
        this.$message({
          message: "只有登录状态下才能发布问题！",
          type: 'warning'
        });
      }
    },
    //提交问题
    submitAnswer() {
      if(this.answerForm.content) {
        this.dialogVisible = false;
        this.answerForm.userId = this.userId;
        let myDate = new Date();
        // this.answerForm.addTime = myDate.toLocaleDateString().split('/').join('-');
        this.answerForm.addTime = myDate.valueOf()/1000;
        this.postRequest('web/comment/add',this.answerForm).then(res=>{
          if(res.code == 200) {
            this.$message({
              message: "提交成功！",
              type: 'success'
            });
            this.$emit('updateList')
          }
        })
      }else {
        this.$message({
          message: "回答内容不能为空",
          type: 'warning'
        });
      }
    },
    //重置
    resetForm() {
      this.answerForm = {
        userId: "",
        content:"",
        pid: null,
        addTime:""
      };
    },
    //浏览器样式调整
    monitoringScreenWidth() {
      // xs 	<768px
      // sm 	≥768px
      // md 	≥992px
      // lg 	≥1200px
      // xl 	≥1920px
      // console.log(this.screenWidth)
      if(this.screenWidth < 1173) {
        this.HamburgerBtnFlag = true;
        if(this.screenWidth < 630) {
          this.miniStyle = true;
        }else {
          this.miniStyle = false;
        }
      }else {
        this.HamburgerBtnFlag = false;
        this.miniStyle = false;
      }
    }
  }
}
</script>

<style scoped>
.AandQ-box {
  font-family: 微软雅黑;
  margin-top: 20px;
}
.issue-release-btn {
  text-align: right;
  margin-top: 20px;
}
.AandQ-card {
  margin-top: 10px;
}
.question-box {
  line-height: 20px;
}
.Q-btn {
  background-color: #F4792C;
  color: #FFFFFF;
  font-size: 18px;
  padding: 4px;
  border: none;
}
.Q-btn-mini {
  background-color: #F4792C;
  color: #FFFFFF;
  font-size: 14px;
  padding: 4px;
  border: none;
}
.A-btn {
  background-color: #2954A2;
  color: #FFFFFF;
  font-family: 微软雅黑;
  font-size: 18px;
  padding: 4px;
  border: none;
}
.A-btn-mini {
  background-color: #2954A2;
  color: #FFFFFF;
  font-family: 微软雅黑;
  font-size: 14px;
  padding: 4px;
  border: none;
  margin-right: 10px;
}
.Q-user {
  font-size: 14px;
  color: #8A8A8A;
}
.Q-user-mini {
  font-size: 12px;
  color: #8A8A8A;
  margin-right: 10px;
  margin-left: 6px;
}
.Q-time {
  font-size: 12px;
  color: #8A8A8A;
  margin-left: 12px;
}
.Q-time-mini {
  font-size: 10px;
  color: #8A8A8A;
  margin-left: 10px;
}
.Q-font {
  font-size: 18px;
  font-weight: bold;
  color: #4f4f4f
}
.Q-font-mini {
  font-size: 15px;
  font-weight: bold;
  color: #4f4f4f
}
.A-box {
  font-size: 14px;
  margin-top: 10px;
}.A-box-mini {
  font-size: 12px;
  margin-top: 10px;
}
.A-name {
  color: #8A8A8A;
}
.A-name-mini {
  color: #8A8A8A;
  margin-left: 6px;
}
.A-tx {
  color: #4f4f4f;
  margin-left: 10px;
}
.all-tx {
  color: #F4792C;
  font-size: 14px;
  padding: 0;
  /*margin-top: 10px;*/
}
.all-tx-mini {
  color: #F4792C;
  font-size: 12px;
  padding: 0;
  /*margin-top: 10px;*/
}
.pack-up-tx {
  color: #2954A2;
  font-size: 14px;
  padding: 0;
  /*margin-top: 14px;*/
}
.pack-up-tx-mini {
  color: #2954A2;
  font-size: 12px;
  padding: 0;
  /*margin-top: 14px;*/
}
.answer-item {
  margin-bottom: 10px;
}
.noMsg{
  text-align: center;
  color: #666666;
  background-color: #efefef;
  margin-top: 10px;
  padding: 20px;
}
.noMsg-small {
  color: #888;
  background-color: #f5f5f5;
  font-size: 12px;
  padding: 10px;
  width: 95%;
}
</style>
