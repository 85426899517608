<template>
  <div>
    <div class="vertical-space-between" v-if="!miniStyle">
      <div>
        <el-tabs class="tab-mini-size" v-model="active_Name" @tab-click="handleClick">
          <el-tab-pane
              v-for="(item,index) in tabList"
              :key="index"
              :label="item.label"
              :name="item.id"
          ></el-tab-pane>
        </el-tabs>
      </div>
      <div v-if="moreBtn">
        <el-button type="text" class="tab-btn-font" @click="clickMore">更多 <i class="el-icon-d-arrow-right"></i></el-button>
      </div>
    </div>
    <div v-else>
      <el-row>
        <el-col :span="5" v-for="(item,index) in tabList" :key="index">
          <el-button type="text" class="mini-tabs-btn" @click="tabBtnClick(item.id)">{{ item.label }}</el-button>
        </el-col>
        <el-col :span="4" v-if="moreBtn">
          <el-button class="mini-tabs-btn" type="text" @click="clickMore">更多 <i class="el-icon-d-arrow-right"></i></el-button>
        </el-col>
      </el-row>
    </div>
  </div>

</template>

<script>
export default {
  name: "TabAssembly",
  props:['activeName','tabList','moreBtn','activityFlag','patentFlag','demandFlag'],
  data() {
    return {
      active_Name:'',
      screenWidth: document.body.clientWidth,//浏览器宽度
      HamburgerBtnFlag:false,//是否显示汉堡按钮
      miniStyle:false,//手机样式
    }
  },
  created() {
    this.active_Name = this.activeName;
    this.monitoringScreenWidth();
  },
  mounted() {
    const that = this
    window.addEventListener("resize", function() {
      return (() => {
        window.screenWidth= document.body.clientWidth;
        that.screenWidth= window.screenWidth;
      })();
    });
  },
  methods:{
    handleClick(tab) {
      //首页活动tab点击操作
      if(this.activityFlag) {
        // console.log(this.tabList[tab.index]);
        this.$emit('getActivityByKey',this.tabList[tab.index].id);
      }
      //首页专利平台tab点击操作
      if(this.patentFlag) {
        this.$emit('getPatentByKey',this.tabList[tab.index].id);
      }
    },
    tabBtnClick(id) {
      if(this.activityFlag) {
        // console.log(this.tabList[tab.index]);
        this.$emit('getActivityByKey',id);
      }
      //首页专利平台tab点击操作
      if(this.patentFlag) {
        this.$emit('getPatentByKey',id);
      }
      if(this.demandFlag) {
        this.$emit('getDemandByKey',id);
      }
    },
    clickMore() {
      //首页活动更多按钮点击操作
      if(this.activityFlag) {
        // 跳转到活动列表页面
        this.$router.push({name:'ActivitiesPage'})
      }
      if(this.patentFlag) {
        // 跳转到专利列表页面
        this.$router.push({name:'PatentList'})
      }
      if(this.demandFlag) {
        // 跳转到专利列表页面
        this.$router.push({name:'DemandPage'})
      }
    },
    //浏览器样式调整
    monitoringScreenWidth() {
      if(this.screenWidth < 1173) {
        this.HamburgerBtnFlag = true;
        if(this.screenWidth < 730) {
          this.miniStyle = true;
        }else {
          this.miniStyle = false;
        }
      }else {
        this.HamburgerBtnFlag = false;
        this.miniStyle = false;
      }
    }
  },
  watch:{
    //监听浏览器宽度
    screenWidth() {
      this.monitoringScreenWidth();
    }
  },
}
</script>

<style scoped>
/deep/.el-tabs__item {
  font-size: 20px !important;
  color: #666666 !important;
  width: 200px;
  text-align: center;
}
/deep/.is-active {
  font-weight: bold;
  color: #2954A2 !important;
}
.tab-btn-font {
  color: #ABABAB;
  font-size: 16px;
}
/deep/.el-tabs__nav-wrap::after {
  width: 0;
}
.mini-tabs-btn {
  color: #666666;
}
</style>