<template>
  <div>
<!--    <div v-if="smallSize">-->
<!--      <div-->
<!--          class="patent-item"-->
<!--          v-for="(item,index) in demandList"-->
<!--          :key="index"-->
<!--          @click="goDetailPage(item.id)"-->
<!--      >-->
<!--        <el-row class="vertical-space-between">-->
<!--          <el-col :span="4" class="vertical-center">-->
<!--            <div class="blue-circle">需求</div>-->
<!--          </el-col>-->
<!--          <el-col :span="16">-->
<!--            <div class="demand-tx">-->
<!--              &lt;!&ndash;            名称&ndash;&gt;-->
<!--              <div class="patent-item-name">-->
<!--                {{item.title}}-->
<!--                <span class="hot-icon"><i class="el-icon-collection-tag"></i>hot</span>-->
<!--              </div>-->
<!--              &lt;!&ndash;            其他基本信息&ndash;&gt;-->
<!--              <div class="text-font">-->
<!--                <el-row>-->
<!--                  <el-col :span="9">-->
<!--                    &lt;!&ndash;            分类&ndash;&gt;-->
<!--                    <span> <i class="el-icon-document"></i></span>-->
<!--                    分类: {{item.typeName}}-->
<!--                  </el-col>-->
<!--                  <el-col :span="11">-->
<!--                    <i class="el-icon-alarm-clock"></i>-->
<!--                    截止时间: {{item.deadline}}-->
<!--                  </el-col>-->
<!--                  <el-col :span="3">-->
<!--                    <i class="el-icon-message"></i> 私信-->
<!--                  </el-col>-->
<!--                </el-row>-->
<!--              </div>-->
<!--            </div>-->
<!--          </el-col>-->
<!--          <el-col :span="4">-->
<!--            <div v-if="item.price == -1" class="price-box">-->
<!--              <span class="price-font">面议</span>-->
<!--            </div>-->
<!--            <div v-else class="price-box" :class="[item.status==1?'move-up':'','price-font']">-->
<!--              {{item.price}}-->
<!--              <span class="unit-font" v-if="item.price != '面议'">万元</span>-->
<!--            </div>-->
<!--            &lt;!&ndash;          状态标志&ndash;&gt;-->
<!--            <div class="status-flag" v-if="item.status == 1">-->
<!--              &lt;!&ndash;              已结束标志&ndash;&gt;-->
<!--              <div class="border-out">-->
<!--                <div class="border-inside">-->
<!--                  - 已结算 - -->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </el-col>-->
<!--        </el-row>-->
<!--      </div>-->
<!--    </div>-->
    <div>
      <div
          class="patent-item"
          v-for="(item,index) in demandList"
          :key="index"
          @click="demandItemClick(item.id)"
      >
        <el-row class="vertical-space-between">
          <el-col  :xs="4" :sm="3" class="vertical-center">
            <div :class="[miniStyle?'blue-circle-mini':'blue-circle']">需求</div>
          </el-col>
          <el-col :xs="15" :sm="12" :md="11">
            <div class="demand-tx">
              <!--            名称-->
              <div :class="[HamburgerBtnFlag?'patent-item-name-mini':'patent-item-name']">
                {{item.title}}
                <span :class="[HamburgerBtnFlag?'hot-icon-mini':'hot-icon']" v-if="item.isHot"><i class="el-icon-collection-tag"></i>hot</span>
                <span v-if="item.isTop"><el-tag class="tag-box" size="mini">TOP</el-tag></span>
              </div>
              <!--            其他基本信息-->
              <div v-if="!miniFontFlag">
                <div :class="[miniFontFlag?'text-font-mini':'text-font']">
                  <el-row>
                    <el-col  :xs="12" :sm="12" :md="12" :lg="9">
                      <!--            分类-->
                      <span> <i class="el-icon-document"  v-if="!HamburgerBtnFlag"></i></span>
                      分类: {{item.typeName}}
                    </el-col>
                    <el-col  :xs="12" :sm="12" :md="12" :lg="11">
                      <i class="el-icon-alarm-clock"  v-if="!HamburgerBtnFlag"></i>
                      截止: {{item.deadline}}
                    </el-col>
                    <el-col :span="3" v-if="privateLetter">
                      <i class="el-icon-message"></i> 私信
                    </el-col>
                  </el-row>
                </div>
              </div>
              <div v-else class="mini-msg-font">
                {{item.typeName}}/截止于{{item.deadline}}
              </div>
            </div>
          </el-col>
          <el-col :sm="{span:3,offset:0}" :xs="{span:6,offset:0}">
            <div v-if="item.price == -1" class="price-box">
              <span :class="[miniFontFlag?'price-font-mini':'price-font']">面议</span>
            </div>
            <div v-else class="price-box" :class="[item.status==1?'move-up':'',miniFontFlag?'price-font-mini':'price-font']">
              {{item.price}}
              <span :class="[miniFontFlag?'unit-font-mini':'unit-font']" v-if="item.price != '面议'">万元</span>
            </div>
            <!--          状态标志-->
            <div class="status-flag" v-if="item.status == 1">
              <!--              已结束标志-->
              <div class="border-out">
                <div class="border-inside">
                  - 已结算 -
                </div>
              </div>
            </div>
          </el-col>
          <el-col  :sm="{span:6,offset:0}" :xs="{span:0,offset:0}" v-if="!miniBtnFlag">
            <div v-if="!editBtn" class="detail-btn">
              <el-button  type="primary"  @click="goDetailPage(item.id)">了解详情</el-button>
            </div>
<!--            <div class="detail-vertical-btn" v-if="editBtn && !miniStyle">-->
<!--              <div><el-button type="primary" size="mini" @click="goMyDetailPage(item.id)">详情</el-button></div>-->
<!--              <div><el-button type="primary" size="mini" @click="editMyDemand(item)">编辑</el-button></div>-->
<!--              <div><el-button type="primary" size="mini" @click="deleteMyDemand(item.id)">删除</el-button></div>-->
<!--            </div>-->
            <el-button-group class="detail-btn" v-else>
              <el-button type="primary" size="mini" @click="goMyDetailPage(item.id)">详情</el-button>
              <el-button type="warning" size="mini" @click="editMyDemand(item)">编辑</el-button>
              <el-button type="danger" size="mini" @click="deleteMyDemand(item.id)">删除</el-button>
            </el-button-group>
          </el-col>
        </el-row>
        <div v-if="miniBtnFlag && editBtn" class="demand-operation-btn">
          <el-button type="primary" size="mini" @click="goMyDetailPage(item.id)">详情</el-button>
          <el-button type="warning" size="mini" @click="editMyDemand(item)">编辑</el-button>
          <el-button type="danger" size="mini" @click="deleteMyDemand(item.id)">删除</el-button>
        </div>
      </div>
    </div>

<!--    需求编辑弹出框-->
    <el-dialog title="需求信息编辑" :visible.sync="dialogFormVisible"  :width="dialogWidth">
      <UploadDemand
          v-if="dialogFormVisible"
          :demandObj="demandObj"
          @dialogCancel="dialogCancel"
          @demandObjSubmit="demandObjSubmit"
      ></UploadDemand>
    </el-dialog>
<!--    删除对话框-->
    <el-dialog
        title="提示"
        :visible.sync="deleteDialogVisible"
        :width="dialogWidth">
      <span>您是否要删除这条专利</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="deleteCancel">取 消</el-button>
        <el-button type="primary" @click="deleteSubmit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import UploadDemand from "@/components/DemandPage/UploadDemand";
export default {
  name: "DemandItem",
  props:['demandList','editBtn','smallSize'],
  components:{
    UploadDemand
  },
  data() {
    return {
      dialogFormVisible:false,//编辑对话框打开与否
      demandObj:{},
      deleteDialogVisible:false,//删除对话框
      deleteId:null,
      screenWidth: document.body.clientWidth,//浏览器宽度
      miniBtnFlag:false,//专利操作按钮
      HamburgerBtnFlag:false,//是否显示汉堡按钮
      miniStyle:false,//手机样式
      privateLetter:false,
      miniFontFlag:false,
      dialogWidth:'50%'
    }
  },
  created() {
    this.dictionaryQueryProcessing();
    //监控屏幕变化
    this.monitoringScreenWidth();
  },
  watch:{
    demandList() {
      this.dictionaryQueryProcessing();
    },
    //监听浏览器宽度
    screenWidth() {
      this.monitoringScreenWidth();
    }
  },
  mounted() {
    const that = this
    window.addEventListener("resize", function() {
      return (() => {
        window.screenWidth= document.body.clientWidth;
        that.screenWidth= window.screenWidth;
      })();
    });
  },
  methods:{
    goDetailPage(id) {
      if(this.$cookies.get('user')){
        this.$router.push({name:'DemandDetail',query:{id:id}})
      }else {
        this.$message({
          message:'登录成为会员才能查看更多信息哦！',
          type:'warning'
        })
      }
    },
    goMyDetailPage(id) {
      this.$router.push({name:'DemandDetail',query:{id:id}})
    },
    //迷你屏幕点击查看详情方法
    demandItemClick(id) {
      //卡片点击方法只有点击按钮不生效才能起作用
      if(this.miniBtnFlag && !this.editBtn) {
        if(this.$cookies.get('user')){
          this.$router.push({name:'DemandDetail',query:{id:id}})
        }else {
          this.$message({
            message:'登录成为会员才能查看更多信息哦！',
            type:'warning'
          })
        }
      }
    },
    //字典查询请求（数据处理）
    dictionaryQueryProcessing() {
      //分类名称处理
     this.demandList.forEach(item=>{
       //分类名称处理
       item.typeName = this.getTypeName(item.typeId);
     })
    },
    //关闭编辑对话框
    dialogCancel() {
      this.dialogFormVisible = false;
    },
    demandObjSubmit() {
      this.dialogFormVisible = false;
      //刷新请求
      this.$emit('updateDemandList')
    },
    //打开编辑对话框
    editMyDemand(item) {
      this.demandObj = item;
      this.dialogFormVisible = true;
    },
    //
    deleteMyDemand(id) {
      this.deleteDialogVisible = true;
      this.deleteId = id;
    },
    //删除取消
    deleteCancel() {
      this.deleteDialogVisible = false;
      this.deleteId = null;
      this.$message({
        type: 'info',
        message: '已取消删除'
      });
    },
    deleteSubmit() {
      this.deleteDialogVisible = false;
      this.deleteRequest('web/demand/' + this.deleteId).then(res=>{
        if(res.code == 200) {
          this.$message({
            type: 'success',
            message: '删除成功'
          });
          this.$emit('updateDemandList')
        }
      })

    },
    //浏览器样式调整
    monitoringScreenWidth() {
      // xs 	<768px
      // sm 	≥768px
      // md 	≥992px
      // lg 	≥1200px
      // xl 	≥1920px
      if(this.screenWidth < 1274) {
        this.HamburgerBtnFlag = true;
        if(this.screenWidth < 900) {
          this.miniStyle = true;
        }else {
          this.miniStyle = false;
        }
      }else {
        this.HamburgerBtnFlag = false;
        this.miniStyle = false;
      }
      if(this.screenWidth < 950) {
        this.miniBtnFlag = true;
      }else {
        this.miniBtnFlag = false;
      }
      if(this.screenWidth < 585) {
        this.miniFontFlag = true;
      }else {
        this.miniFontFlag = false;
      }
      if(this.screenWidth >1200) {
        this.privateLetter = true;
      }else {
        this.privateLetter = false;
      }
      if(this.screenWidth < 1123) {
        this.dialogWidth ='80%';
      }else {
        this.dialogWidth = '50%';
      }
    }
  }
}
</script>

<style scoped>
.patent-item {
  padding: 10px;
  box-shadow:2px 2px 5px #aaa;
  margin-top: 20px;
  /*position: relative;*/
}
.blue-circle {
  height: 68px;
  width: 68px;
  border-radius: 50%;
  background-color: #2954A2;
  color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
}
.blue-circle-mini {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: #2954A2;
  color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
}
.patent-item-name {
  font-size: 20px;
  font-weight: bold;
  color: #222222;
}
.patent-item-name-mini {
  font-size: 14px;
  font-weight: bold;
  color: #222222;
}
.demand-tx {
  margin-top: 12px;
}
.hot-icon {
  font-size: 16px;
  color: #FD3A0C;
}
.hot-icon-mini {
  font-size: 12px;
  color: #FD3A0C;
}
.text-font {
  color: #666666;
  font-size: 14px;
  margin-top: 10px;
}
.text-font-mini {
  color: #666666;
  font-size: 12px;
  margin-top: 5px;
}
.mini-msg-font {
  color: #666666;
  font-size: 11px;
  margin-top: 5px;
}
.text-font i {
  font-weight: bold;
}
.move-up {
  margin-top: -20px;
}
.unit-font {
  font-size: 14px;
  /*margin-left: -10px;*/
}
.unit-font-mini {
  font-size: 10px;
}
.status-flag {
  position: absolute;
  top:16px;
  z-index: -1;
  right: 30px;
}
.border-out {
  border: 4px solid #DCD6D6;
  border-radius: 50%;
  padding: 5px;
}
.border-inside {
  border: 2px solid #DCD6D6;
  border-radius: 50%;
  font-size: 20px;
  color: #DCD6D6;
  text-align: center;
  font-weight: bolder;
  padding: 10px;
}
.price-font {
  /*font-size: 30px;*/
  /*color: #FD3A0C;*/
  /*font-weight: bold;*/
  /*line-height: 80px;*/
  font-size: 25px;
  color: #FD3A0C;
  font-weight: bold;
  position: relative;
  top: 50%; /*偏移*/
  transform: translateY(-50%);
  text-align: center;
}
.price-font-mini {
  font-size: 16px;
  color: #FD3A0C;
  font-weight: bold;
  position: relative;
  top: 50%; /*偏移*/
  transform: translateY(-50%);
  text-align: center;
}
.price-box {
  text-align: center;
}
.detail-btn {
  position: relative;
  top: 50%; /*偏移*/
  transform: translateY(-50%);
  text-align: center;
}
.detail-vertical-btn {
  text-align: center;
}
.detail-vertical-btn button{
  margin-top: 2px;
}
.demand-operation-btn {
  text-align: center;
  margin-top: 8px;
}
.tag-box {
  position: absolute;
  /*background: #92b7bd;*/
  background-color: rgb(245,108,108);
  z-index: 999;
  border-radius: 3px;
  border: none;
  color: #fff;
  font-size: 70%;
  padding: 2px 4px 0;
  top: -15px;
  left: -17px;
  line-height: 1.4;
  -ms-transform: rotate(-7deg);
  -webkit-transform: rotate(-7deg);
  transform: rotate(-7deg);
  transition: transform 0.1s ease-in;
}
</style>
