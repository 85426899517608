<template>
  <div class="policy-box">
    <!--    专利政策详情页-->
    <el-row>
      <el-col :xs="{span:24,offset:0}" :sm="{span:20,offset:2}" :md="{span:16,offset:4}">
        <!--    标题-->
        <div :class="[miniStyle?'policy-tt-mini':'policy-tt']">{{detailMsg.title}}</div>

        <div class="vertical-center">
          <div :class="[miniStyle?'policy-time-mini':'policy-time']">活动时间： {{detailMsg.time}}</div>
          <div :class="[miniStyle?'policy-time-mini':'policy-time']">活动地点： {{detailMsg.address}}</div>
        </div>

        <div class="myVideo" v-if="detailMsg.video">
          <video
              width="80%"
              v-if="detailMsg.video"
              Controls="true"
              :Autoplay="Autoplay"
              class="video-mini"
              :src="detailMsg.video"
              muted="true"
          >
            您的浏览器不支持video
          </video>
        </div>
        <div :class="[miniStyle?'policy-tx-mini':'policy-tx']" v-html="detailMsg.content" class="rich-text-box"></div>
<!--        <div  class="myVideo">-->
<!--          <el-image class="active-img-style" :src="detailMsg.picture"></el-image>-->
<!--        </div>-->
        <div :class="[miniStyle?'policy-unit-mini':'policy-unit']">
          举办单位： {{detailMsg.company}}
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "ActivitiesDetail",
  data() {
    return {
      id:'',
      detailMsg:{},
      screenWidth: document.body.clientWidth,//浏览器宽度
      miniStyle:false,//专利操作按钮
      HamburgerBtnFlag:false,//是否显示汉堡按钮
      Autoplay:false,//视频自动播放
    }
  },
  created() {
    this.id = this.$route.params.id;
    this.getActivityDetail(this.id);
    //监控屏幕变化
    this.monitoringScreenWidth();
  },
  watch:{
    //监听浏览器宽度
    screenWidth() {
      this.monitoringScreenWidth();
    }
  },
  mounted() {
    const that = this
    window.addEventListener("resize", function() {
      return (() => {
        window.screenWidth= document.body.clientWidth;
        that.screenWidth= window.screenWidth;
      })();
    });
  },
  methods:{
    getActivityDetail(id) {
      let url = 'web/activity/' + id;
      this.getRequest(url).then(res => {
        this.detailMsg = res;
        // this.loadVideo(res.video);
        this.visitRecord(id);
        // console.log(res.content)
      })
    },
    // loadVideo(url) {
    //   document.getElementById("videoPlay").src = url;
    //   document.getElementById("videoPlay").muted=true;
    // },
    //活动浏览操作
    visitRecord(id) {
      this.getRequest('web/activity/visit?id=' + id);
    },
    //浏览器样式调整
    monitoringScreenWidth() {
      // xs 	<768px
      // sm 	≥768px
      // md 	≥992px
      // lg 	≥1200px
      // xl 	≥1920px
      // console.log(this.screenWidth)
      if(this.screenWidth < 1058) {
        this.HamburgerBtnFlag = true;
      }else {
        this.HamburgerBtnFlag = false;
      }
      if(this.screenWidth < 730) {
        this.miniStyle = true;
      }else {
        this.miniStyle = false;
      }

    }

  },

}
</script>

<style scoped>
.policy-box {
  background-color: rgb(237,239,247);
}
.policy-tt {
  background-color: #FFFFFF;
  color: #2F2F2F;
  font-size: 20px;
  text-align: center;
  padding: 20px;
  margin-top: 20px;
}
.policy-tt-mini {
  background-color: #FFFFFF;
  color: #2F2F2F;
  font-size: 16px;
  text-align: center;
  padding: 10px;
  margin-top: 10px;
}
.policy-time {
  font-size: 14px;
  text-align: center;
  padding: 10px;
  color: #8A8A8A;
}
.policy-time-mini {
  font-size: 10px;
  text-align: center;
  padding: 8px;
  color: #8A8A8A;
}
.policy-tx {
  color: #2F2F2F;
  font-size: 14px;
  background-color: #fff;
  padding: 20px;
  letter-spacing: 2px;
  margin-bottom: 10px;
  text-align: center;
}
.policy-tx-mini {
  color: #2F2F2F;
  font-size: 12px;
  background-color: #fff;
  padding: 10px 20px 10px 20px;
  letter-spacing: 2px;
  margin-bottom: 10px;
  text-align: center;
}
.policy-unit {
  color: #2F2F2F;
  text-align: right;
  padding: 10px;
  margin-top: 10px;
  font-size: 14px;
  margin-bottom:10px ;
}
.policy-unit-mini {
  color: #2F2F2F;
  text-align: right;
  padding: 10px;
  margin-top: 10px;
  font-size: 10px;
  margin-bottom:10px ;
}
.link-style {
  margin-right: 10px;
}
.myVideo {
  text-align: center;
  margin-bottom: 10px;
}
.active-img-style {
  width: 80%;
}
.rich-text-box >>> img {
  max-width: 90%;
}
</style>
