<template>
  <div class="policy-box">
<!--    专利政策详情页-->
    <el-row>
      <el-col :xs="{span:24,offset:0}" :sm="{span:22,offset:1}" :md="{span:18,offset:3}">
        <!--    标题-->
        <div :class="[miniStyle?'policy-tt-mini':'policy-tt']">{{policyDetailMsg.title}}</div>
        <div :class="[miniStyle?'policy-time-mini':'policy-time']">发布时间： {{policyDetailMsg.addTime}}</div>

        <div :class="[miniStyle?'policy-tx-mini':'policy-tx']" v-html="policyDetailMsg.content" class="rich-text-box"></div>

        <div :class="[miniStyle?'policy-unit-mini':'policy-unit']" class="vertical-space-between">
          <div><el-link @click="toCard(policyDetailMsg.linkUrl)" :class="[miniStyle?'link-style-mini':'link-style']" type="primary">原文链接</el-link></div>
<!--          <div><el-link :href="policyDetailMsg.linkUrl" :class="[miniStyle?'link-style-mini':'link-style']" type="primary">原文链接</el-link></div>-->
          <div>发布单位： {{policyDetailMsg.company}}</div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "PolicyDetail",
  data() {
    return {
      id:'',
      policyDetailMsg:{},
      screenWidth: document.body.clientWidth,//浏览器宽度
      miniStyle:false,//专利操作按钮
      HamburgerBtnFlag:false,//是否显示汉堡按钮
    }
  },
  created() {
    this.id = this.$route.params.id;
    this.getPolicyDetail(this.id);
    //监控屏幕变化
    this.monitoringScreenWidth();
  },
  watch:{
    //监听浏览器宽度
    screenWidth() {
      this.monitoringScreenWidth();
    }
  },
  mounted() {
    const that = this
    window.addEventListener("resize", function() {
      return (() => {
        window.screenWidth= document.body.clientWidth;
        that.screenWidth= window.screenWidth;
      })();
    });
  },
  methods:{
    getPolicyDetail(id) {
      let url = 'web/policy/' + id;
      this.getRequest(url).then(res=>{
        // console.log(res.content)
        this.policyDetailMsg = res;
      })
    },
    //路由跳转并在新窗口打开
    toCard (url) {
      window.open(url, '_blank')
    },
    //浏览器样式调整
    monitoringScreenWidth() {
      // xs 	<768px
      // sm 	≥768px
      // md 	≥992px
      // lg 	≥1200px
      // xl 	≥1920px
      // console.log(this.screenWidth)
      if(this.screenWidth < 1058) {
        this.HamburgerBtnFlag = true;
      }else {
        this.HamburgerBtnFlag = false;
      }
      if(this.screenWidth < 730) {
        this.miniStyle = true;
      }else {
        this.miniStyle = false;
      }
    }
  },

}
</script>

<style scoped>
.policy-box {
  background-color: rgb(237,239,247);
}

.policy-tt {
  background-color: #FFFFFF;
  color: #2F2F2F;
  font-size: 20px;
  text-align: center;
  padding: 20px;
  margin-top: 20px;
}
.policy-tt-mini {
  background-color: #FFFFFF;
  color: #2F2F2F;
  font-size: 16px;
  text-align: center;
  padding: 10px;
  margin-top: 10px;
}
.policy-time {
  font-size: 14px;
  text-align: center;
  padding: 10px;
  color: #8A8A8A;
}
.policy-time-mini {
  font-size: 10px;
  text-align: center;
  padding: 8px;
  color: #8A8A8A;
}
.policy-tx {
  color: #2F2F2F;
  font-size: 14px;
  background-color: #fff;
  padding: 20px;
  letter-spacing: 2px;
  /*text-align: center;*/
}
.policy-tx-mini {
  color: #2F2F2F;
  font-size: 12px;
  background-color: #fff;
  padding: 10px 20px 10px 20px;
  letter-spacing: 2px;
  margin-bottom: 10px;
  /*text-align: center;*/
}
.policy-unit {
  color: #2F2F2F;
  text-align: right;
  padding: 10px;
  margin-top: 10px;
  font-size: 14px;
  margin-bottom:10px ;
}
.policy-unit-mini {
  color: #2F2F2F;
  text-align: right;
  padding: 10px;
  margin-top: 10px;
  font-size: 10px;
  margin-bottom:10px ;
}
.link-style {
  margin-right: 10px;
}
.link-style-mini {
  margin-right: 10px;
  font-size: 11px;
}
.rich-text-box >>> img {
  max-width: 90%;
  text-align: center;
}
</style>
