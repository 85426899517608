<template>
  <div>
<!--    服务与合作页面-->
    <!--    标题-->
    <div class="tt-box">
      <div :class="[miniStyle?'chinese-tt-mini':'chinese-tt']">联系我们</div>
      <div :class="[miniStyle?'english-tt-mini':'english-tt']">introduction</div>
    </div>
    <div :class="[miniStyle?'contact-mini':'contact']">
      <div><i class="el-icon-phone" :class="[miniStyle?'contact-icon-mini':'contact-icon']"></i>客服热线: <span :class="[miniStyle?'contact-tx-mini':'contact-tx']">{{companyInfo.phone}}</span></div>
      <div><i class="el-icon-s-custom" :class="[miniStyle?'contact-icon-mini':'contact-icon']"></i>联系人:<span :class="[miniStyle?'contact-tx-mini':'contact-tx']">{{companyInfo.admin}}</span></div>
      <div><i class="el-icon-phone-outline" :class="[miniStyle?'contact-icon-mini':'contact-icon']"></i>联系电话:<span :class="[miniStyle?'contact-tx-mini':'contact-tx']">{{companyInfo.phone}}</span></div>
      <div><i class="el-icon-message" :class="[miniStyle?'contact-icon-mini':'contact-icon']"></i>邮箱:<span :class="[miniStyle?'contact-tx-mini':'contact-tx']">{{companyInfo.email}}</span></div>
      <div><i class="el-icon-location" :class="[miniStyle?'contact-icon-mini':'contact-icon']"></i>地址:<span :class="[miniStyle?'contact-tx-mini':'contact-tx']">{{companyInfo.address}}</span></div>
    </div>
    <div>
      <img class="location-pic" src="../../../assets/hyt-location.png" alt="">
    </div>
  </div>
</template>

<script>
export default {
  name: "ServiceAndCooperation",
  props:['companyInfo'],
  data() {
    return {
      screenWidth: document.body.clientWidth,//浏览器宽度
      // HamburgerBtnFlag:false,//是否显示汉堡按钮
      miniStyle:false,//手机样式
    }
  },
  created() {
    this.monitoringScreenWidth();
  },
  mounted() {
    const that = this
    window.addEventListener("resize", function() {
      return (() => {
        window.screenWidth= document.body.clientWidth;
        that.screenWidth= window.screenWidth;
      })();
    });
  },
  watch:{
    //监听浏览器宽度
    screenWidth() {
      this.monitoringScreenWidth();
    }
  },
  methods: {
    //浏览器样式调整
    monitoringScreenWidth() {
      // xs 	<768px
      // sm 	≥768px
      // md 	≥992px
      // lg 	≥1200px
      // xl 	≥1920px
      console.log(this.screenWidth)
      // if(this.screenWidth < 1173) {
      //   this.HamburgerBtnFlag = true;
      // }else {
      //   this.HamburgerBtnFlag = false;
      // }
      if(this.screenWidth < 620) {
        this.miniStyle = true;
      }else {
        this.miniStyle = false;
      }
    }
  }
}
</script>

<style scoped>
.tt-box {
  text-align: center;
  margin-top: 20px;
}
.chinese-tt {
  font-size: 24px;
  font-weight: bold;
  font-family: 微软雅黑;
  color: #343434;
}
.chinese-tt-mini {
  font-size: 18px;
  font-weight: bold;
  font-family: 微软雅黑;
  color: #343434;
}
.english-tt {
  color: #ADADAD;
  font-size: 20px;
  letter-spacing: 5px;
  font-weight: bold;
  margin-top: 10px;
}
.english-tt-mini {
  color: #ADADAD;
  font-size: 16px;
  letter-spacing: 5px;
  font-weight: bold;
  margin-top: 5px;
}
.contact {
  font-size: 18px;
  color: #6F6F6F;
  font-family: 微软雅黑;
  padding: 20px;
  margin-top: 20px;
  line-height: 40px;
}
.contact-mini {
  font-size: 14px;
  color: #6F6F6F;
  font-family: 微软雅黑;
  padding: 20px;
  /*margin-top: 20px;*/
  line-height: 40px;
}
.contact-tx {
  color: #2C2C2C;
  font-size: 20px;
  margin-left: 20px;
}
.contact-tx-mini {
  color: #2C2C2C;
  font-size: 14px;
  margin-left: 10px;
}
.contact-icon {
  margin-right: 20px;
  font-size: 20px;
}
.contact-icon-mini {
  margin-right: 6px;
  font-size: 16px;
}
.location-pic {
  width: 100%;
}

</style>