<template>
  <div>
    <el-row>
      <el-col  :xs="{span:22,offset:1}"  :sm="{span:20,offset:2}"  :md="{span:18,offset:3}">
        <div>
          <!--    我是最新活动-->
          <!--          标题-->
          <ModuleTitle title="合作伙伴"></ModuleTitle>
<!--          入驻按钮-->
          <div class="btn-box">
            <el-button class="cooperative-btn" round @click="cooperation">资源在手，我要入驻 <i class="el-icon-d-arrow-right"></i></el-button>
          </div>
          <!--          内容区域-->
          <div class="partner-box">
            <el-row>
              <el-col
                  :span="4"
                  v-for="(item,img_index) in partnerList"
                  :key="item.id"
              >
                <div :class="[miniStyle?'partner-item-mini':'partner-item',img_index == partnerList.length-1?'partner-item-border-left':'']" @click="goPartnerDetail(item.id)">
<!--                  <img :class="[miniStyle?'partner-img-mini':'partner-img']" :src="item.logo" alt="">-->
                  <el-image
                      :class="[miniStyle?'partner-img-mini':'partner-img']"
                      :src="item.logo"
                      alt="公司的logo"
                  ></el-image>
                </div>
              </el-col>
            </el-row>
          </div>
          <Paging
              :total="pageTotal"
              :pageSize="parameterObj.pageSize"
              :currentPage="parameterObj.pageNum"
              @changeCurrent="changeCurrent"
              noSizesFlag="true"
          ></Paging>
          <div class="vertical-space-between">
            <el-button type="text" class="friendship-link">友情链接</el-button>
            <el-button type="text" class="link-cooperation-btn" @click="cooperation">申请链接合作 <i class="el-icon-d-arrow-right"></i></el-button>
          </div>
          <div>
            <el-row>
              <el-col :span="4" v-for="(item,index) in partnerList" :key="index">
                <el-link :class="[miniLink?'link-item-mini':'link-item']" :href="item.link" target="_blank">{{item.name}}</el-link>
              </el-col>
            </el-row>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import ModuleTitle from "./ModuleTitle";
import Paging from "@/components/Paging";
export default {
  name: "CooperativePartner",
  components:{
    ModuleTitle,
    Paging
  },
  data() {
    return {
      partnerList:[],
      linkList:[],
      pageTotal:0,//专利总条数
      parameterObj:{
        pageNum:1,
        pageSize:8
      },
      screenWidth: document.body.clientWidth,//浏览器宽度
      miniLink:false,//是否显示汉堡按钮
      miniStyle:false,//手机样式
    }
  },
  created() {
    this.getPartnerList();
    this.monitoringScreenWidth();
  },
  watch:{
    //监听浏览器宽度
    screenWidth() {
      this.monitoringScreenWidth();
    }
  },
  mounted() {
    const that = this
    window.addEventListener("resize", function() {
      return (() => {
        window.screenWidth= document.body.clientWidth;
        that.screenWidth= window.screenWidth;
      })();
    });
  },
  methods:{
    //合作伙伴分页
    getPartnerList() {
      let url = 'web/partner/page?pageNum=1&pageSize=24'
      this.getRequest(url).then(res=>{
        this.partnerList = res.row;
        this.pageTotal = res.totalCount;
        this.parameterObj.pageSize = res.pageSize;
        this.parameterObj.pageNum = res.pageNum;
      })
    },
    //点击了合作按钮
    cooperation() {
      this.$router.push({name:'AboutUsPage',query:{activeName:'contact'}})
    },
    //请求另一页的数据
    changeCurrent(currentPage) {
      this.parameterObj.pageNum = currentPage;
      this.getSchoolList();
    },
    goPartnerDetail(id){
      this.$router.push({
        path: `/partner_detailPage/${id}/company`,
      })
    },
    //浏览器样式调整
    monitoringScreenWidth() {
      // xs 	<768px
      // sm 	≥768px
      // md 	≥992px
      // lg 	≥1200px
      // xl 	≥1920px
      if(this.screenWidth < 951) {
        this.miniStyle = true;
        if(this.screenWidth < 768) {
          this.miniLink = true;
        }else {
          this.miniLink = false;
        }
      }else {
        this.miniStyle = false;
        this.miniLink = false;
      }
      // console.log(this.screenWidth)
    }
  }
}
</script>

<style scoped>
.btn-box {
  width: 100%;
  text-align: center;
}
.cooperative-btn {
  background-color: #659CFF;
  color: #FFFFFF;
  margin: 10px 0 20px 0;

}
.partner-item {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #bbbbbb;
  cursor: pointer;
}
.partner-item-mini {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 1px solid #bbbbbb;
  border-top: 1px solid #bbbbbb;
  border-bottom: 1px solid #bbbbbb;
  padding-top: 10px;
  padding-bottom: 10px;
  cursor: pointer;
}
.partner-item-border-left{
  border-right: 1px solid #bbbbbb;
}
.partner-img {
  max-width: 100px;
  max-height: 100%;
}
.partner-img-mini {
  max-width: 50px;
  max-height: 100%;
}
.change-btn {
  color: #bbbbbb;
  font-size: 16px;
  /*font-weight: bold;*/
}
.partner-box {
  margin-bottom: 20px;
}
.friendship-link {
  color: #2954A2;
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 10px;
}
.link-cooperation-btn {
  color: #888888;
  margin-bottom: 10px;
}
.link-item {
  color: #525252;
  font-size: 14px;
  margin-bottom: 20px;
}
.link-item-mini{
  color: #525252;
  font-size: 12px;
  margin-bottom: 20px;
}
</style>
