<template>
  <div>
<!--    我是需求页面-->
    <el-row>
      <Classification @setRequestParameter="getRequestParameter" :classType="classType"></Classification>
      <el-col :span="18" :offset="3">
        <DemandItem :demandList="demandList" v-if="demandListFlag"></DemandItem>
        <!--        分页-->
        <Paging
            :total="pageTotal"
            :pageSize="parameterObj.pageSize"
            :currentPage="parameterObj.pageNum"
            @changeCurrent="changeCurrent"
            @changeSize="changeSize"
        ></Paging>
      </el-col>
    </el-row>
  </div>
</template>

<script>
// import DemandClassification from "@/components/DemandPage/DemandClassification";
import DemandItem from "@/components/DemandPage/DemandItem";
import Paging from "@/components/Paging";
import Classification from "@/components/PatentPage/Classification";
export default {
  name: "DemandPage",
  components:{
    // DemandClassification,
    DemandItem,
    Paging,
    Classification
  },
  data() {
    return {
      demandList:[],
      demandListFlag:false,
      url:'web/demand/list?',//默认需求请求url
      classType:'demand',
      pageTotal:0,//专利总条数
      parameterObj:{
        pageNum:1,
        pageSize:5
      }
    }
  },
  created() {
    this.getDemandList(this.url);
  },
  methods:{
    //获取所有需求列表
    getDemandList(url) {
        this.demandListFlag = false;
        this.getRequest(url).then(res=>{
          this.demandList = res.row;
          this.demandListFlag = true;
          this.pageTotal = res.totalCount;
          this.parameterObj.pageNum = res.pageNum;
          this.parameterObj.pageSize = res.pageSize;
          // console.log(this.demandList)
        })
    },
    //分类请求的参数处理及请求
    getRequestParameter(data) {
      this.parameterObj = data;
      this.handleParameterURL();
    },
    // url处理
    handleParameterURL() {
      let reqUrl = this.url;
      let obj = Object.entries(this.parameterObj);
      obj.forEach( (item,index) =>{
        if(index == 0) {
          reqUrl = reqUrl + item[0] + '=' + item[1]
        } else {
          reqUrl = reqUrl + '&' + item[0] + '=' + item[1]
        }
      })
      this.getDemandList(reqUrl);
    },
    //请求另一页的数据
    changeCurrent(currentPage) {
      this.parameterObj.pageNum = currentPage;
      this.handleParameterURL();
    },
    //改变当前每页的数量
    changeSize(value) {
      this.parameterObj.pageSize = value;
      this.parameterObj.pageNum = 1;
      this.handleParameterURL();
    },
  }
}
</script>

<style scoped>

</style>