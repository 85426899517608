<template>
  <div>
<!--    关于我们页面-->
    <el-row>
      <TopImage :titleFront="titleFront" :titleAfter="titleAfter"></TopImage>
      <el-col :xs="{span:24,offset:0}" :sm="{span:20,offset:2}" :md="{span:18,offset:3}">
        <el-row>
          <el-col :sm="{span:24,offset:0}" :md="{span:20,offset:2}">
            <el-card class="company-msg-card">
              <CompanyMsgCard :companyInfo="companyInfo" :active-name="activeName"></CompanyMsgCard>
            </el-card>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import TopImage from "@/components/AboutUsPage/TopImage";
import CompanyMsgCard from "@/components/AboutUsPage/CompanyMsgCard";
export default {
  name: "AboutUsPage",
  components: {
    TopImage,
    CompanyMsgCard
  },
  data() {
    return {
      titleFront:'关于环宇通',
      titleAfter:'ABOUT US',
      companyInfo:{},
      activeName:'profile'
    }
  },
  created() {
    this.getUsInfo();
    if(this.$route.query.activeName) {
      this.activeName = 'contact';
    }
  },
  methods:{
    getUsInfo() {
      this.getRequest('web/company/info').then(res=>{
        // console.log(res)
        this.companyInfo = res;
      })
    },
    //浏览器样式调整
    monitoringScreenWidth() {
      // xs 	<768px
      // sm 	≥768px
      // md 	≥992px
      // lg 	≥1200px
      // xl 	≥1920px
      // console.log(this.screenWidth)
      if(this.screenWidth < 1173) {
        this.HamburgerBtnFlag = true;
        if(this.screenWidth < 820) {
          this.miniStyle = true;
        }else {
          this.miniStyle = false;
        }
      }else {
        this.HamburgerBtnFlag = false;
        this.miniStyle = false;
      }
    }
  }
}
</script>

<style scoped>
.company-msg-card {
  margin-top: -40px;
  margin-bottom: 40px;
  /*height: 760px;*/
}
</style>