//全局变量文件


// function getRandColor () {
//     var tem = Math.round(Math.random() * colorListLength)
//     return colorList[tem]
// }
const  text = '我是全局变量2.0';

//颜色
const $blue = "#2954A2"

export default
{
    // getRandColor
    text,
    $blue
}