<template>
  <div :class="[miniStyle?'header-box-mini':'header-box']">
    <div>
      <img :class="[miniStyle?'logo-img-mini':'logo-img']" src="../assets/logo.png"  @click="goHome"/>
    </div>
    <div>
      <el-button-group class="btn-margin">
        <el-button type="warning" v-if="!miniStyle" @click="gotoRegister">注册</el-button>
        <el-button type="warning" v-else size="mini" @click="gotoRegister">注册</el-button>
        <el-button type="primary" v-if="!miniStyle" @click="gotoLogin">登录</el-button>
        <el-button type="primary" v-else size="mini" @click="gotoLogin">登录</el-button>
      </el-button-group>
    </div>

  </div>
</template>

<script>
export default {
  name: "HeaderLogin",
  data() {
    return {
      logoUrl:"",
      searchKey:"",
      screenWidth: document.body.clientWidth,//浏览器宽度
      HamburgerBtnFlag:false,//是否显示汉堡按钮
      miniStyle:false,//手机样式
    }
  },
  created() {
    this.monitoringScreenWidth();
  },
  mounted() {
    const that = this
    window.addEventListener("resize", function() {
      return (() => {
        window.screenWidth= document.body.clientWidth;
        that.screenWidth= window.screenWidth;
      })();
    });
  },
  watch:{
    //监听浏览器宽度
    screenWidth() {
      this.monitoringScreenWidth();
    }
  },
  methods: {
    gotoRegister() {
      this.$router.push('/register')
    },
    gotoLogin() {
      this.$router.push('/login')
    },
    goHome() {
      this.$router.push('/')
    },
    //浏览器样式调整
    monitoringScreenWidth() {
      if(this.screenWidth < 1173) {
        this.HamburgerBtnFlag = true;
        if(this.screenWidth < 730) {
          this.miniStyle = true;
        }else {
          this.miniStyle = false;
        }
      }else {
        this.HamburgerBtnFlag = false;
        this.miniStyle = false;
      }
    }
  }
}
</script>

<style scoped>
.header-box {
  height: 68px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /*padding: 0 20px;*/
  background-color: white;
  width: 100%;
}
.header-box-mini {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /*padding: 0 20px;*/
  background-color: white;
  width: 100%;
}
.btn-margin {
  margin-right: 40px;
}
.logo-img {
  max-height: 51px;
  max-width: 150px;
  margin-left: 20px;
}
.logo-img-mini {
  max-height: 30px;
  max-width: 90px;
  margin-left: 10px;
}

</style>
