<template>
  <div class="home">
<!--    顶部-->
<!--    <HeaderGlobal></HeaderGlobal>-->
<!--    首页主体-->
<!--    轮播图-->
    <RotationChart></RotationChart>
<!--    入驻信息-->
    <SettledData></SettledData>
<!--    最新活动-->
    <Activities></Activities>
<!--    交易市场-->
    <TradingMarket></TradingMarket>
<!--    需求大厅-->
    <DemandHall></DemandHall>
<!--    智能匹配-->
    <IntelligentMatching></IntelligentMatching>
<!--    校企合作-->
    <SchoolEnterpriseCooperation></SchoolEnterpriseCooperation>
<!--    合作伙伴-->
    <CooperativePartner></CooperativePartner>
    <!--    在线客服-->
    <div class="kefu">
     <a target="_blank" href="http://wpa.qq.com/msgrd?v=3&uin=2268967408&site=qq&menu=yes"><img border="0" src="http://wpa.qq.com/pa?p=2:2268967408:51" alt="点击这里给我发消息" title="点击这里给我发消息"/></a>
    </div>
<!--    回到顶部按钮-->
    <el-backtop :bottom="116" :right="10" :visibility-height="0">
      <i class="el-icon-arrow-up back-top-icon"></i>
    </el-backtop>


  </div>
</template>

<script>
// @ is an alias to /src
import RotationChart from "../components/HomePage/RotationChart";
import SettledData from "../components/HomePage/SettledData";
import Activities from "../components/HomePage/Activities";
import TradingMarket from "../components/HomePage/TradingMarket";
import DemandHall from "../components/HomePage/DemandHall";
import IntelligentMatching from "../components/HomePage/IntelligentMatching";
import SchoolEnterpriseCooperation from "../components/HomePage/SchoolEnterpriseCooperation";
import CooperativePartner from "../components/HomePage/CooperativePartner";
export default {
  name: 'Home',
  components: {
    RotationChart,
    SettledData,
    Activities,
    TradingMarket,
    DemandHall,
    IntelligentMatching,
    SchoolEnterpriseCooperation,
    CooperativePartner,
  },
  data() {
    return {

    }
  },
  created() {

  },
  methods:{
    clickMe() {
      console.log('你点击了我')
    }
  }
}
</script>
<style>
.kefu {
  position: fixed;
  right: 10px;
  bottom: 160px;
}
</style>
