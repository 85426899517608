import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './plugins/element.js'

//Element 的按需引入
import { Button, Select,CheckboxGroup,Checkbox } from 'element-ui';
Vue.use(Button)
Vue.use(Select)
Vue.use(CheckboxGroup)
Vue.use(Checkbox)

  //请求的封装
  import {postRequest} from "./utils/api";
  import {postKeyValueRequest} from "./utils/api";
  import {putRequest} from "./utils/api";
  import {deleteRequest} from "./utils/api";
  import {getRequest} from "./utils/api";
  import {getRequestJson} from "./utils/api";
  import {getTypeName} from "./utils/api";
  import {getMaturityName} from "./utils/api";
  import {geCooperationName} from "./utils/api";
  import {getPatentLevelName} from "./utils/api";
  import {getPatentTypeName} from "./utils/api";

  Vue.prototype.postRequest = postRequest;
  Vue.prototype.postKeyValueRequest = postKeyValueRequest;
  Vue.prototype.putRequest = putRequest;
  Vue.prototype.getRequest = getRequest;
  Vue.prototype.deleteRequest = deleteRequest;
  Vue.prototype.getTypeName = getTypeName;
  Vue.prototype.getMaturityName = getMaturityName;
  Vue.prototype.geCooperationName = geCooperationName;
  Vue.prototype.getPatentLevelName = getPatentLevelName;
  Vue.prototype.getPatentTypeName = getPatentTypeName;
  Vue.prototype.getRequestJson = getRequestJson;


//全局变量挂载
import global_ from './utils/global'
Vue.prototype.$global = global_

//七牛云的引物挂载
import * as qiNiu from 'qiniu-js'
Vue.prototype.qiniu = qiNiu;

//cookie的引入与挂载
import VueCookies from "vue-cookies";
Vue.use(VueCookies);

import {Message} from 'element-ui';
Vue.prototype.$message = Message


//全局样式
import './assets/css/global.css'
//主题样式修改
import '../theme/index.css'

//引入字体
import '../src/assets/css/font.css';

//引用vuex
import store from '../store/index'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

