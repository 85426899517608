<template>
  <div class="bg-img">
<!--    <HeaderLogin></HeaderLogin>-->
    <el-col :lg="{span:8,offset:8}" :md="{span:12,offset:6}" :sm="{span:14,offset:5}" :xs="{span:20,offset:2}">
<!--      手机号登录-->
      <el-card class="card-css" v-if="flag">
        <div :class="[miniStyle?'login-tt-mini':'login-tt']">手机登录</div>
        <el-form  :model="telForm" ref="telForm" label-width="80px" :rules="telRules">
          <el-form-item label="手机号：" prop="phone">
            <el-input v-model="telForm.phone" placeholder="请输入手机号"></el-input>
          </el-form-item>
          <el-form-item label="验证码：" prop="smsCode">
            <el-row>
              <el-col :span="16">
                <el-input v-model="telForm.smsCode" placeholder="请输入验证码"></el-input>
              </el-col>
              <el-col :span="8">
                <el-button class="code-btn" type="primary" size="small" @click="getTelSmsCode" :disabled="!telVerificationFlag">
                  <span v-if="!telVerificationFlag">重新发送 {{ telWait }} S</span>
                  <span v-else>获取验证码</span>
                </el-button>
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item  label-width="0">
            <div class="vertical-space-between operation-btn">
              <div>
                <el-checkbox v-model="telForm.remember_me">记住我</el-checkbox>
              </div>
              <div>
                <el-button type="text" @click="flag = false">邮箱登录</el-button>
              </div>
            </div>
          </el-form-item>
          <el-form-item label-width="0">
            <el-row>
              <el-col :span="18" :offset="3">
                <el-button type="primary" class="login-btn" @click="telSubmit">确认登录</el-button>
              </el-col>
            </el-row>
          </el-form-item>
        </el-form>
      </el-card>

      <el-card class="card-css" v-else>
        <div :class="[miniStyle?'login-tt-mini':'login-tt']">邮箱登录</div>
        <el-form :model="emailForm" ref="emailForm" label-width="80px" :rules="emailRules">
          <el-form-item label="邮箱：" prop="email">
            <el-input v-model="emailForm.email" placeholder="请输入邮箱"></el-input>
          </el-form-item>
          <el-form-item label="验证码：" prop="smsCode">
            <el-row>
              <el-col :span="16">
                <el-input v-model="emailForm.smsCode" placeholder="请输入验证码"></el-input>
              </el-col>
              <el-col :span="8">
                <el-button class="code-btn" type="primary" size="small" @click="getEmailSmsCode" :disabled="!emailVerificationFlag" >
                  <span v-if="!emailVerificationFlag">重新发送 {{ emailWait }} S</span>
                  <span v-else>获取验证码</span>
                </el-button>
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item  label-width="0">
            <div class="vertical-space-between operation-btn">
              <div>
                <el-checkbox v-model="emailForm.remember_me">记住我</el-checkbox>
              </div>
              <div>
                <el-button type="text" @click="flag = true">手机号登录</el-button>
              </div>
            </div>
          </el-form-item>
          <el-form-item label-width="0">
            <el-row>
              <el-col :span="18" :offset="3">
                <el-button type="primary" class="login-btn" @click="emailSubmit">确认登录</el-button>
              </el-col>
            </el-row>
          </el-form-item>
        </el-form>
      </el-card>
    </el-col>
  </div>
</template>

<script>
// import axios from "axios";
export default {
  name: "login",
  components: {
  },
  data() {
    //手机号正则表达式
    let reg=/^(((13[0-9]{1})|(15[0-9]{1})|(16[0-9]{1})|(17[3-8]{1})|(18[0-9]{1})|(19[0-9]{1})|(14[5-7]{1}))+\d{8})$/
    let validatorPhone = function (phone, value, callback) {
      if (value === '') {
        callback(new Error('手机号不能为空'))
      } else if (!reg.test(value)) {
        callback(new Error('手机号格式错误'))
      } else {
        callback()
      }
    }
    //验证码正则表达式
    let numbReg = /^\d{6}$/
    let validatorSmsCode = function (code, value, callback) {
      if (value === '') {
        callback(new Error('验证码不能为空'))
      } else if (!numbReg.test(value)) {
        callback(new Error('验证码错误'))
      } else {
        callback()
      }
    }
    //邮箱正则表达式
    let emailReg=/^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
    let validatorEmail = function (email, value, callback) {
      if (value === '') {
        callback(new Error('邮箱不能为空'))
      } else if (!emailReg.test(value)) {
        callback(new Error('邮箱格式错误'))
      } else {
        callback()
      }
    }
    return {
      telForm: {
        phone: '',
        smsCode: '',
        remember_me: false
      },
      emailForm:{
        email: '',
        smsCode: '',
        remember_me:false
      },
      flag: true,
      //校验规则
      telRules: {
        phone: [
          { required: true,validator: validatorPhone, trigger: 'blur' }
        ],
        smsCode: [
          { required: true,validator: validatorSmsCode, trigger: 'blur' }
        ],
      },
      emailRules:{
        smsCode: [
          { required: true,validator: validatorSmsCode, trigger: 'blur' }
        ],
        email: [
          { required: true,validator: validatorEmail, trigger: 'blur' }
        ]
      },
      formTelUrl:'web/login/mobile',
      screenWidth: document.body.clientWidth,//浏览器宽度
      HamburgerBtnFlag:false,//是否显示汉堡按钮
      miniStyle:false,//手机样式
      telVerificationFlag:true,//手机验证码按钮可使用与否
      emailVerificationFlag:true,//邮箱验证码按钮可使用与否
      telWait: 60,//再次发送验证码等待的时间
      emailWait: 60,//再次发送验证码等待的时间
    }
  },
  created() {
    this.$cookies.remove('user');
    //监控屏幕变化
    this.monitoringScreenWidth();
    if(this.$route.query.waitTime) {
      this.telWait = this.$route.query.waitTime;
      this.telWaitTime();
    }
  },
  watch:{
    //监听浏览器宽度
    screenWidth() {
      this.monitoringScreenWidth();
    },
  },
  mounted() {
    const that = this
    window.addEventListener("resize", function() {
      return (() => {
        window.screenWidth= document.body.clientWidth;
        that.screenWidth= window.screenWidth;
      })();
    });
  },
  methods: {
    telSubmit() {
      this.$refs['telForm'].validate((valid) => {
        if (valid) {
          let formData = new FormData();
          for(let key in this.telForm){
            formData.append(key,this.telForm[key]);
          }
          formData.append('remember-me',this.telForm.remember_me);
          this.postRequest('web/login/mobile',formData).then(res=>{
            if(res.status == 200) {
              //保存用户数据
              if(this.telForm.remember_me) {
                let expireDays = 30 * 24 * 60 * 60;//保存7天
                this.$cookies.set('user', res.user,expireDays)
              }
              else {
                this.$cookies.set('user', res.user)
              }
              this.$router.push('/'); //跳转首页
            }else{
              this.$message({
                message: res,
                type: 'error'
              });
            }
          })
        } else {
          alert('手机号或者验证码输入错误!');
          return false;
        }
      });
    },
    emailSubmit() {
      this.$refs['emailForm'].validate((valid) => {
        if (valid) {
          let reqUrl = 'web/login/email?'
          let obj = Object.entries(this.emailForm);
          obj.forEach( (item,index) =>{
            if(index == 0) {
              reqUrl = reqUrl + item[0] + '=' + item[1]
            } else {
              reqUrl = reqUrl + '&' + item[0] + '=' + item[1]
            }
          })
          reqUrl = reqUrl+'&remember-me=' + this.emailForm.remember_me;
          this.postRequest(reqUrl).then(res=>{
            if(res.status == 200) {
              // this.$store.commit('saveUser',res.user);
              // this.$router.push('/')
              //保存用户数据
              if(this.emailForm.remember_me) {
                let expireDays = 30 * 24 * 60 * 60;//保存30天
                this.$cookies.set('user', res.user,expireDays)
              }
              else {
                this.$cookies.set('user', res.user)
              }
              this.$router.push('/'); //跳转首页
            }else {
              this.$message({
                message: res,
                type: 'error'
              });
              // console.log(res)
            }
          })
        } else {
          alert('邮箱或者验证码输入错误!');
          return false;
        }
      });
    },
    //发送手机号验证码的请求
    getTelSmsCode () {
      if(!this.telForm.phone) {
        console.log('手机号不能为空')
      }else {
        let url = 'web/code/sms?phone=' + this.telForm.phone;
        this.getRequest(url).then(res=>{
          alert(res);
        })
        this.telWaitTime();
      }
    },
    //发送邮箱验证码请求
    getEmailSmsCode() {
      if(!this.emailForm.email) {
        console.log('邮箱不可为空')
      } else {
        let url = 'web/code/email?email=' + this.emailForm.email;
        this.getRequest(url).then(res=>{
          alert(res);
        })
        this.emailWaitTime();
      }
    },
    //手机验证码等待倒计时
    telWaitTime() {
      let that = this;
      if (this.telWait == 0) {
        this.telVerificationFlag = true;
        this.telWait = 60;
      } else {
        this.telVerificationFlag = false;
        this.telWait--;
        setTimeout(function() {
              that.telWaitTime()
        }, 1000)
      }
    },
    //邮箱验证码等待倒计时
    emailWaitTime() {
      let that = this;
      if (this.emailWait == 0) {
        this.emailVerificationFlag = true;
        this.emailWait = 60;
      } else {
        this.emailVerificationFlag = false;
        this.emailWait--;
        setTimeout(function() {
          that.emailWaitTime()
        }, 1000)
      }
    },
    //浏览器样式调整
    monitoringScreenWidth() {
      // xs 	<768px
      // sm 	≥768px
      // md 	≥992px
      // lg 	≥1200px
      // xl 	≥1920px
      if(this.screenWidth < 1169) {
        this.HamburgerBtnFlag = true;
        if(this.screenWidth < 768) {
          this.miniStyle = true;
        }else {
          this.miniStyle = false;
        }
      }else {
        this.HamburgerBtnFlag = false;
        this.miniStyle = false;
      }
    }

  }
}
</script>

<style scoped>
  .bg-img {
    background:url("../assets/login_bg.png");
    /*background-color: pink;*/
    width: 100%;
    height: 100%;
    position:fixed;
    margin-top: -0px;/*上边距*/
    margin-left: -0px;/*左边距*/
    background-size:100% 100%;
  }
  .card-css {
    margin-top: 100px;
    /*background-color: rgba(255,255,255,0.8);*/
  }
  .login-tt {
    font-size: 30px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
  }
  .login-tt-mini {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
  }
  .code-btn {
    margin-left: 10px;
  }
  .login-btn {
    width: 100%;
  }
  .btm-vertical {
    /*display: flex;*/
    /*justify-content: center;*/
  }
  .operation-btn {
    margin-right: 10px;
    margin-left: 10px;
  }

</style>
