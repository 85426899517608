import Vue from 'vue'
import {
    Button,
    Card,
    Form,
    FormItem,
    Col,
    Input,
    Row,
    Radio,
    Image,
    ButtonGroup,
    Option,
    Menu,
    MenuItem,
    Submenu,
    Carousel,
    CarouselItem,
    Tabs,
    TabPane,
    Pagination,
    Tag,
    Breadcrumb,
    BreadcrumbItem,
    RadioGroup,
    RadioButton,
    Link,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    Dialog,
    DatePicker,
    Upload,
    Cascader,
    Backtop

} from 'element-ui'

Vue.use(Button)
Vue.use(Card)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Col)
Vue.use(Row)
Vue.use(Input)
Vue.use(Radio)
Vue.use(Image)
Vue.use(ButtonGroup)
Vue.use(Option)
Vue.use(Menu)
Vue.use(MenuItem)
Vue.use(Submenu)
Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(Pagination)
Vue.use(Tag)
Vue.use(Breadcrumb)
Vue.use(BreadcrumbItem)
Vue.use(RadioGroup)
Vue.use(RadioButton)
Vue.use(Link)
Vue.use(Dropdown)
Vue.use(DropdownItem)
Vue.use(DropdownMenu)
Vue.use(Dialog)
Vue.use(DatePicker)
Vue.use(Upload)
Vue.use(Cascader)
Vue.use(Backtop)
