<template>
  <div>
    <div :class="[miniStyle?'header-box-mini':'header-box']">
      <div >
        <div @click="goHome"><img src="../assets/logo.png" title="点击回到首页" :class="[miniStyle?'logo-img-mini':'logo-img']"/></div>
      </div>
      <div  class="header-search" v-if="!miniStyle">
          <div class="vertical-center">
            <el-input placeholder="请输入关键字" v-model="searchKey" prefix-icon="el-icon-search" clearable></el-input>
            <el-button id="search-btn" icon="el-icon-search" @click="searchRelevant()" >搜索</el-button>
          </div>
      </div>
      <div>
        <el-dropdown v-if="loginFlag">
          <el-button type="primary" v-if="!miniStyle">
            个人中心<i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-button type="primary" v-else size="mini">
            个人中心<i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
<!--            <el-dropdown-item></el-dropdown-item>-->
            <el-dropdown-item >
              <div @click="gotoMyInfo()">
                个人资料
              </div>
            </el-dropdown-item>
            <el-dropdown-item>
              <div @click="gotoMyProject()">
                我的项目
              </div>
            </el-dropdown-item>
            <el-dropdown-item>
              <div @click="gotoMyPatent()">
                我的专利
              </div>
            </el-dropdown-item>
            <el-dropdown-item>
              <div @click="gotoMyDemand()">
                我的需求
              </div>
            </el-dropdown-item>
            <el-dropdown-item>
              <div @click="logout()">
                退出登录
              </div>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <el-button-group v-else>
          <el-button v-if="!miniStyle" type="warning" @click="gotoRegister">注册</el-button>
          <el-button v-else size="mini" type="warning" @click="gotoRegister">注册</el-button>
          <el-button v-if="!miniStyle" type="primary" @click="gotoLogin">登录</el-button>
          <el-button v-else size="mini" type="primary" @click="gotoLogin">登录</el-button>
        </el-button-group>
      </div>
    </div>
    <!--    导航栏-->
    <div class="menu-box vertical-space-between" v-if="!miniStyle">
      <div class="welcome-tx">
        <span class="name-font">美一</span>
        <span>一个专门为知识产权服务的平台</span>
      </div>
      <div>
        <ul class="menu-ul"  v-if="menuFlag && !HamburgerBtnFlag">
          <li  v-for="(item,index) in menuList" :key="index"  @click="onclick(item.value,item.path)" :class="[activeIndex==item.value?'li-active':'','menu-li']">{{item.name}}</li>
        </ul>
      </div>
      <div v-if="HamburgerBtnFlag" class="HamburgerBtn-box">
        <el-dropdown>
          <el-button class="HamburgerBtn" type="text">
            <i class="el-icon-menu"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
                v-for="(item,index) in menuList"
                :key="index"
            >
             <div @click="onclick(item.value,item.path)">{{item.name}}</div>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <div class="menu-box-mini vertical-space-between" v-else>
      <div class="welcome-tx-center">
        <span class="name-font-mini">美一 </span> 知识产权服务平台
      </div>
      <div v-if="HamburgerBtnFlag" class="HamburgerBtn-box-mini">
        <el-dropdown>
          <el-button class="HamburgerBtn" type="text">
            <i class="el-icon-menu"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
                v-for="(item,index) in menuList"
                :key="index"
            >
              <div @click="onclick(item.value,item.path)">{{item.name}}</div>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>

  </div>
</template>

<script>

export default {
  name: "HeaderGlobal",
  props:['menuFlag','activeVal'],
  data() {
    return {
      logoUrl:"",
      loginFlag:false,//是否登录
      searchKey:"",
      activeIndex: '1',
      menuList: [
        {
          name:'关于我们',
          value:"8",
          path:'/about_us_page'
        },
        {
          name:'最新活动',
          value:"7",
          path:'/activities_page'
        },
        {
          name:'服务大厅',
          value:"6",
          path:'/service_hall_page'
        },
        {
          name:'项目平台',
          value:"5",
          path:'/achievements_page'
        },
        {
          name:'政策法规',
          value:"4",
          path:'/policy_page'
        },
        {
          name:'需求大厅',
          value:"3",
          path:'/demand_page'
        },
        {
          name:'专利平台',
          value:"2",
          path:'/patent_list'
        },
        {
          name:'首页',
          value:"1",
          path:'/'
        },
      ],
      screenWidth: document.body.clientWidth,//浏览器宽度
      HamburgerBtnFlag:false,//是否显示汉堡按钮
      miniStyle:false,//手机样式
    }
  },
  created() {
    if(this.$cookies.get('user')){
      this.loginFlag = true;
    }else {
      this.loginFlag = false;
    }
    //页面重置到首页
    // this.goHome();
    this.monitoringScreenWidth();
  },
  mounted() {
    const that = this
    window.addEventListener("resize", function() {
      return (() => {
        window.screenWidth= document.body.clientWidth;
        that.screenWidth= window.screenWidth;
      })();
    });
  },

  methods: {
    gotoRegister() {
      this.$router.push('/register')
    },
    gotoLogin() {
      this.$router.push('/login')
    },
    onclick(index,path) {
      this.activeIndex = index;
      this.$router.push(path)
    },
    goHome() {
      this.$router.push('/');
    },
    gotoMyProject() {
      this.$router.push('/my_project')
    },
    gotoMyInfo() {
      this.$router.push('/my_info')
    },
    gotoMyPatent() {
      this.$router.push('/my_patent')
    },
    gotoMyDemand() {
      this.$router.push('/my_demand')
    },
    logoutReq() {
      this.getRequest('web/logout').then(res=>{
        if(res.code == 200) {
          this.$message({
            message:res.msg,
            type:'info'
          })
        }
      })
    },
    //退出登录
    logout() {
      this.$cookies.remove('user')
      //注销登录请求
      this.logoutReq();
      this.$router.push('/')
      this.loginFlag = false;
    },
    //浏览器样式调整
    monitoringScreenWidth() {
      if(this.screenWidth < 1173) {
        this.HamburgerBtnFlag = true;
        if(this.screenWidth < 730) {
          this.miniStyle = true;
        }else {
          this.miniStyle = false;
        }
      }else {
        this.HamburgerBtnFlag = false;
        this.miniStyle = false;
      }
    },
    // 根据关键字搜索
    searchRelevant() {
      if(this.searchKey) {
        // console.log(this.searchKey)
        this.$router.push({
          path: `/search_result_page/${this.searchKey}`,
        })
      } else {
        alert('输入框不可为空')
      }

    }
  },
  watch:{
    activeVal() {
      switch (this.activeVal) {
        case 'ActivitiesPage':
          this.activeIndex = '7'
          break
        case "PatentList":
          this.activeIndex = '2'
          break
        case "Home":
          this.activeIndex = '1'
          break
        case 'DemandPage':
          this.activeIndex = '3'
          break
        case "PolicyPage":
          this.activeIndex = '4'
          break
        case "AchievementsPage":
          this.activeIndex = '5'
          break
        case 'AboutUsPage':
          this.activeIndex = '8'
          break
        case "ServiceHallPage":
          this.activeIndex = '6'
          break
      }
    },
    //监听浏览器宽度
    screenWidth() {
      this.monitoringScreenWidth();
    },
    //监听路由，除了搜索结果页面外都需要把顶部输入框清空
    $route(to) {
      // console.log("路由名称"+to.name)
      if(to.name !== 'SearchResult') {
        this.searchKey = '';
      }
      if(to.name == "Home") {
        this.activeIndex = '1'
      }
      if(to.name == "PatentList" || to.name == "PatentDetail") {
        this.activeIndex = '2'
      }
      if(to.name == "DemandPage" || to.name == "DemandDetail") {
        this.activeIndex = '3'
      }
      if(to.name == "PolicyPage" || to.name == "PolicyDetail") {
        this.activeIndex = '4'
      }
      if(to.name == "AchievementsPage" || to.name == "AchievementsDetail") {
        this.activeIndex = '5'
      }
      if(to.name == "ServiceHallPage") {
        this.activeIndex = '6'
      }
      if(to.name == "ActivitiesPage" || to.name == "ActivitiesDetail") {
        this.activeIndex = '7'
      }
      if(to.name == "AboutUsPage") {
        this.activeIndex = '8'
      }

    },

  },

}
</script>

<style scoped>
.header-box {
  height: 68px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: auto 20px;
  color: white;
}
.header-box-mini {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: auto 10px;
  color: white;
}
.home-box {

}
.home-icon {
  color: #2954A2;
  font-size: 30px;
  margin-left: 12px;
  float: left;
}
.header-search {
  width: 60%;
}
#search-btn {
  background-color: #2954A2;
  color: #fff;
  border-radius: 0;

}
.menu-box {
  position: relative;
  z-index: 10;
  width: 100%;
  background-color: rgba(0,0,0,0.8);
  color: white;
  height: 72px;
}
.menu-box-mini {
  position: relative;
  z-index: 10;
  width: 100%;
  background-color: rgba(0,0,0,0.8);
  color: white;
  height: 50px;
  margin-top: 22px;
  top: -22px;
}
.welcome-tx {
  font-size: 16px;
  line-height: 72px;
  margin-left: 20px;
  color: #cccccc;
  cursor: default;
}

.welcome-tx-center {
  line-height: 50px;
  margin-left: 20px;
  text-align: center;
  width: 100%;
  font-size: 14px;
  cursor: default;
}
.name-font {
  font-size: 50px;
  line-height: 72px;
  /*font-weight: bolder;*/
  margin-right: 10px;
  font-family: buer;
  color: #fff;
}
.name-font-mini {
  font-size: 20px;
  font-family: buer;
  /*font-weight: bolder;*/
  color: #fff;
}

.menu-ul {
  list-style-type: none;
  display: inline;
}
.menu-li {
  float: right;
  /*width: 120px;*/
  padding-left: 20px;
  padding-right: 20px;
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.li-active {
  background-color: #2954A2;
}
.HamburgerBtn-box {
  float: right;
  transform: translateY(10%);
  margin-right: 20px;
}
.HamburgerBtn-box-mini {
  float: right;
  /*transform: translateY(10%);*/
  margin-right: 20px;
}
.HamburgerBtn {
  /*background-color: rgba(0,0,0,0.8);*/
  color: #FFFFFF;
  font-size: 24px;
}
.logo-img {
  max-height: 51px;
  max-width: 150px;
  cursor: pointer;
}
.logo-img-mini {
  max-height: 30px;
  max-width: 90px;
  cursor: pointer;
}
</style>
