<template>
  <div class="page-box">
<!--    分页-->
    <el-pagination
        v-if="noSizesFlag || miniStyle"
        :small="miniStyle"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[5, 7, 10, 20]"
        :page-size="pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total">
    </el-pagination>
    <el-pagination
        v-else
        :small="miniStyle"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[5, 7, 10, 20]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
    </el-pagination>
  </div>
</template>

<script>
export default {
  name: "Paging",
  props:['total','currentPage','pageSize','noSizesFlag'],
  data() {
    return {
      screenWidth: document.body.clientWidth,//浏览器宽度
      HamburgerBtnFlag:false,//是否显示汉堡按钮
      miniStyle:false,//手机样式
    }
  },
  created() {
    this.monitoringScreenWidth();
  },
  mounted() {
    const that = this
    window.addEventListener("resize", function() {
      return (() => {
        window.screenWidth= document.body.clientWidth;
        that.screenWidth= window.screenWidth;
      })();
    });
  },
  watch:{
    //监听浏览器宽度
    screenWidth() {
      this.monitoringScreenWidth();
    }
  },
  methods:{
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.$emit('changeSize',val)
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.$emit('changeCurrent', val)
    },
    //浏览器样式调整
    monitoringScreenWidth() {
      // xs 	<768px
      // sm 	≥768px
      // md 	≥992px
      // lg 	≥1200px
      // xl 	≥1920px
      // console.log(this.screenWidth)
      if(this.screenWidth < 570) {
        this.miniStyle = true;
      }else {
        this.miniStyle = false;
      }
      // console.log(this.miniStyle)
    }
  }
}
</script>

<style scoped>
.page-box {
  margin: 30px;
  text-align: right;
}
</style>