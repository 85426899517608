<template>
  <div id="tabs-btn">
    <el-tabs v-model="active_name"  tab-position="top" type="border-card">
      <el-tab-pane label="环宇通简介" name="profile">
        <CompanyProfile :companyInfo="companyInfo"></CompanyProfile>
      </el-tab-pane>
      <el-tab-pane label="联系我们" name="contact">
        <ServiceAndCooperation :companyInfo="companyInfo"></ServiceAndCooperation>
      </el-tab-pane>
      <el-tab-pane label="环宇通印象" name="honor">
        <Honor :companyInfo="companyInfo"></Honor>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import CompanyProfile from "@/components/AboutUsPage/companyMsg/CompanyProfile";
import Honor from "@/components/AboutUsPage/companyMsg/Honor";
import ServiceAndCooperation from "@/components/AboutUsPage/companyMsg/ServiceAndCooperation";

export default {
  name: "CompanyMsgCard",
  components:{
    CompanyProfile,//公司简介
    Honor,//荣誉
    ServiceAndCooperation,//服务与合作
  },
  props:['companyInfo','activeName'],
  data() {
    return {
      // profileText:'1112',
      // introductionObj:{}
      screenWidth: document.body.clientWidth,//浏览器宽度
      HamburgerBtnFlag:false,//是否显示汉堡按钮
      miniStyle:false,//手机样式
      active_name:'profile'
    }
  },
  created() {
    this.handleCompanyMsg();
    this.monitoringScreenWidth();
    if(this.activeName) {
      this.active_name = this.activeName;
    }
  },
  mounted() {
    const that = this
    window.addEventListener("resize", function() {
      return (() => {
        window.screenWidth= document.body.clientWidth;
        that.screenWidth= window.screenWidth;
      })();
    });
  },
  watch:{
    //监听浏览器宽度
    screenWidth() {
      this.monitoringScreenWidth();
    }
  },
  methods:{
    handleCompanyMsg() {
      // console.log(this.companyInfo)
      // const obj2 = (({a, d, e}) => ({a, d, e}))(obj)
      //环宇通简介数据
      // this.introductionObj = (({content,mission,location,culture,pictureVoList}) => ({content,mission,location,culture,pictureVoList})(this.companyInfo))
    },
    //浏览器样式调整
    monitoringScreenWidth() {
      // xs 	<768px
      // sm 	≥768px
      // md 	≥992px
      // lg 	≥1200px
      // xl 	≥1920px
      // console.log(this.screenWidth)
      if(this.screenWidth < 1173) {
        this.HamburgerBtnFlag = true;
      }else {
        this.HamburgerBtnFlag = false;
      }
      if(this.screenWidth < 640) {
        this.miniStyle = true;
      }else {
        this.miniStyle = false;
      }
    }
  }
}
</script>

<style scoped>

</style>