<template>
  <div>
    <!--          技术概述-->
    <div>
      <el-row>
        <el-col :xs="24" :sm="24" :md="16">
          <!--                技术概述文本-->
          <div :class="[miniStyle?'summary-tt-mini':'summary-tt']">
            {{topicIntoMsg.name}}技术概述
          </div>
          <div v-if="miniStyle" class="mini-summary-img-box">
<!--            <img class="summary-img-mini" :src="topicIntoMsg.img_1" alt="">-->
          </div>
          <div :class="[miniStyle?'summary-tx-mini':'summary-tx']">
            {{topicIntoMsg.summaryText}}
          </div>
        </el-col>
        <el-col :md="8" v-if="!miniStyle">
          <!--                技术概述图片-->
          <div>
<!--            <img class="summary-img" :src="topicIntoMsg.img_1" alt="">-->
          </div>
        </el-col>
      </el-row>
    </div>
    <!--          设备特点-->
    <div class="equipment-characteristics-box">
      <el-row>
        <el-col :md="8" v-if="!miniStyle">
          <!--                设备特点图片-->
          <div>
<!--            <img class="summary-img" :src="topicIntoMsg.img_2" alt="">-->
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="16">
          <!--                设备特点文本-->
          <div :class="[miniStyle?'equipment-characteristics-tt-mini':'equipment-characteristics-tt']">
            {{topicIntoMsg.name}}技术难题
          </div>
          <div v-if="miniStyle" class="mini-summary-img-box">
<!--            <img class="summary-img-mini" :src="topicIntoMsg.img_2" alt="">-->
          </div>
          <div :class="[miniStyle?'equipment-characteristics-tx-mini':'equipment-characteristics-tx']">
            {{topicIntoMsg.difficulties}}
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "TopicIntroduce",
  props:['topicIntoMsg'],
  data() {
    return {
      screenWidth: document.body.clientWidth,//浏览器宽度
      HamburgerBtnFlag:false,//是否显示汉堡按钮
      miniStyle:false,//手机样式
    }
  },
  created() {
    //监控屏幕变化
    this.monitoringScreenWidth();
  },
  mounted() {
    this.title = this.$route.params.name + '设备';
    const that = this
    window.addEventListener("resize", function() {
      return (() => {
        window.screenWidth= document.body.clientWidth;
        that.screenWidth= window.screenWidth;
      })();
    });
  },
  watch:{
    demandList() {
      this.dictionaryQueryProcessing();
    },
    //监听浏览器宽度
    screenWidth() {
      this.monitoringScreenWidth();
    }
  },
  methods :{
    //浏览器样式调整
    monitoringScreenWidth() {
      // xs 	<768px
      // sm 	≥768px
      // md 	≥992px
      // lg 	≥1200px
      // xl 	≥1920px
      if(this.screenWidth < 1058) {
        this.HamburgerBtnFlag = true;
      }else {
        this.HamburgerBtnFlag = false;
      }
      if(this.screenWidth < 974) {
        this.miniStyle = true;
      }else {
        this.miniStyle = false;
      }
    }
  }
}
</script>

<style scoped>
/*技术概述*/
.summary-tx {
  color: #666666;
  padding: 20px;
}
.summary-tx-mini {
  color: #666666;
  font-size: 14px;
  padding: 14px;
}
.summary-tt {
  color: #666666;
  padding-bottom: 20px;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  border-bottom: 2px solid #cdcdcd;
  margin-left: 30px;
  margin-right: 30px;
}
.summary-tt-mini {
  color: #666666;
  padding-bottom: 10px;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  border-bottom: 2px solid #cdcdcd;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: -20px;
}
.mini-summary-img-box {
  text-align: center;
  margin-top: 10px;
}
.summary-img {
  width: 100%;
}
.summary-img-mini {
  width: 80%;
}
.equipment-characteristics-box {
  margin-top: 40px;
}
.equipment-characteristics-tt {
  color: #666666;
  padding-bottom: 20px;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  border-bottom: 2px solid #cdcdcd;
  margin-left: 40px;
  margin-right: 30px;
}
.equipment-characteristics-tt-mini {
  color: #666666;
  padding-bottom: 10px;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  border-bottom: 2px solid #cdcdcd;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: -20px;
}
.equipment-characteristics-tx {
  color: #666666;
  padding: 20px;
  margin-left: 20px;
}
.equipment-characteristics-tx-mini {
  color: #666666;
  padding: 14px;
  font-size: 14px;
}
</style>
