<template>
  <div>
<!--    需求详情页面-->
    <el-row>
      <el-col  :xs="{span:24,offset:0}" :sm="{span:22,offset:1}" :md="{span:18,offset:3}">
        <!--    专利详情页-->
        <!--    顶部图片-->
        <div :class="[topImgMove?'top-image-box-mini':'top-image-box']">
          <img src="../assets/page_top_bg.png" alt="" :class="[topImgMove?'top-img-mini':'top-img']">
          <div :class="[topImgMove?'tt-box-mini':'tt-box']">
            <!--        专利名称-->
            <div class="">
              {{demandDetailMsg.title}}
            </div>
          </div>
        </div>
        <!--        基本属性-->
        <div :class="[topImgMove?'basic-properties-box-mini':'basic-properties-box']">
          <el-row>
            <el-col :span="5" :offset="2">
              <!--技术领域-->
              <div>
                <el-row>
                  <el-col :xs="0" :sm="3"><i class="el-icon-menu"></i></el-col>
                  <el-col :xs="24" :sm="21">
                    <div>
                      技术领域
                    </div>
                    <div :class="[topImgMove?'properties-border-mini':'properties-border']">
                      {{demandDetailMsg.typeName}}
                    </div>
                  </el-col>
                </el-row>
              </div>
            </el-col>
            <el-col :span="5">
              <!--需求状态-->
              <div>
                <el-row>
                  <el-col :xs="0" :sm="3"><i class=""></i></el-col>
                  <el-col :xs="24" :sm="21">
                    <div>
                      需求状态
                    </div>
                    <div :class="[topImgMove?'properties-border-mini':'properties-border']" v-if="demandDetailMsg.status == 0">
                      需求中
                    </div>
                    <div :class="[topImgMove?'properties-border-mini':'properties-border']" v-else>
                      已结束
                    </div>
                  </el-col>
                </el-row>
              </div>
            </el-col>
            <el-col :span="5">
              <!--截止时间-->
              <div>
                <el-row>
                  <el-col :xs="0" :sm="3"><i class="el-icon-date"></i></el-col>
                  <el-col :xs="24" :sm="21">
                    <div>
                      截止时间
                    </div>
                    <div :class="[topImgMove?'properties-border-mini':'properties-border']">
                      {{demandDetailMsg.deadline}}
                    </div>
                  </el-col>
                </el-row>
              </div>
            </el-col>
          </el-row>
        </div>
        <!--        参考价格-->
        <div>
          <div :class="[topImgMove?'price-box-mini':'price-box']">
            参考价格：
            <span v-if="demandDetailMsg.price == -1">
              <span :class="[topImgMove?'big-red-font-mini':'big-red-font']">面议</span>
            </span>
            <span v-else>
              <span class="small-red-font">￥{{demandDetailMsg.price}}</span>
              <span :class="[topImgMove?'big-red-font-mini':'big-red-font']">万元</span>
            </span>
          </div>
        </div>
        <!--        专利描述-->
        <div class="describe-box">
          <!--          标题-->
          <div :class="[topImgMove?'describe-tt-mini':'describe-tt']">
           需求描述 <i class="el-icon-s-opportunity describe-icon"></i>
          </div>
          <!--          内容-->
          <div :class="[topImgMove?'describe-tx-mini':'describe-tx']">
            {{demandDetailMsg.content}}
          </div>
        </div>
        <!--        单位信息-->
        <div class="describe-box">
          <!--          标题-->
          <div :class="[topImgMove?'describe-tt-mini':'describe-tt']">
            需求联系人
          </div>
          <!--          内容-->
          <el-card :class="[topImgMove?'company-box-mini':'company-box']">
            <div class="vertical-space-between">
              <!--                单位信息-->
              <div :class="[topImgMove?'small-tx-font-mini':'small-tx-font']">
                <!--                  标题-->
                <div :class="[topImgMove?'company-box-tt-mini':'company-box-tt']"><i class="el-icon-info name-icon"></i>{{userName}}</div>
                <div>联系方式： <span :class="[topImgMove?'company-box-msg-mini':'company-box-msg']">{{userPhone}}</span></div>
              </div>
              <!--                操作按钮-->
            </div>
          </el-card>
        </div>
        <div class="describe-box" v-if="demandDetailMsg.schoolObj">
          <!--          标题-->
          <div :class="[topImgMove?'describe-tt-mini':'describe-tt']">
            学校信息
          </div>
          <!--          内容-->
          <el-card :class="[topImgMove?'company-box-mini':'company-box']">
            <div class="vertical-space-between">
              <!--                单位信息-->
              <div :class="[topImgMove?'small-tx-font-mini':'small-tx-font']">
                <!--                  标题-->
                <div :class="[topImgMove?'company-box-tt-mini':'company-box-tt']">{{demandDetailMsg.schoolObj.label}}</div>
              </div>
              <!--                操作按钮-->
              <div>
                <el-button type="primary" v-if="topImgMove" size="mini" @click="goSchoolDetail(demandDetailMsg.schoolObj.value)">进入单位</el-button>
                <el-button type="primary" v-else @click="goSchoolDetail(demandDetailMsg.schoolObj.value)">进入单位</el-button>
              </div>
            </div>
          </el-card>
        </div>
        <div class="describe-box" v-if="demandDetailMsg.companyObj">
          <!--          标题-->
          <div :class="[topImgMove?'describe-tt-mini':'describe-tt']">
            公司信息
          </div>
          <!--          内容-->
          <el-card :class="[topImgMove?'company-box-mini':'company-box']">
            <div class="vertical-space-between">
              <!--                单位信息-->
              <div :class="[topImgMove?'small-tx-font-mini':'small-tx-font']">
                <!--                  标题-->
                <div :class="[topImgMove?'company-box-tt-mini':'company-box-tt']">{{demandDetailMsg.companyObj.label}}</div>
              </div>
              <!--                操作按钮-->
              <div>
                <el-button type="primary" v-if="topImgMove" size="mini" @click="goCompanyDetail(demandDetailMsg.companyObj.value)">进入单位</el-button>
                <el-button type="primary" v-else @click="goCompanyDetail(demandDetailMsg.companyObj.value)">进入单位</el-button>
              </div>
            </div>
          </el-card>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "DemandDetail",
  props:[''],
  data(){
    return{
      id:0,
      // 面包屑导航栏
      breadList:null,
      //需求详情信息
      demandDetailMsg:{},
      userPhone :'',
      userName:'',
      screenWidth: document.body.clientWidth,//浏览器宽度
      miniStyle:false,//专利操作按钮
      HamburgerBtnFlag:false,//是否显示汉堡按钮
      topImgMove:false,//顶部图片网上移动
    }
  },
  created() {
    // 获取路由传奇过来的专利id,进行请求获取该专利的详情信息
    this.id = this.$route.query.id;
    let url = 'web/demand/' + this.id;
    this.getRequest(url).then(res=>{
      this.demandDetailMsg = res;
      this.demandDetailMsg.typeName = this.getTypeName(res.typeId);
      this.demandDetailMsg.schoolObj = this.getSchool(this.demandDetailMsg.schoolId)
      this.demandDetailMsg.companyObj =  this.getCompany(this.demandDetailMsg.partnerId);

      this.getUserInfo(res.userId);
    })
    //监控屏幕变化
    this.monitoringScreenWidth();

  },
  watch:{
    //监听浏览器宽度
    screenWidth() {
      this.monitoringScreenWidth();
    }
  },
  mounted() {
    const that = this
    window.addEventListener("resize", function() {
      return (() => {
        window.screenWidth= document.body.clientWidth;
        that.screenWidth= window.screenWidth;
      })();
    });
  },
  methods:{
    goCompanyDetail(id) {
      this.$router.push({
        path: `/partner_detailPage/${id}/company`,
      })
    },
    goSchoolDetail(id) {
      this.$router.push({
        path: `/partner_detailPage/${id}/school`,
      })
    },
    getSchool(id) {
      let schoolDictList = this.$store.state.schoolDict;
      let schoolObj = schoolDictList.find(function(ele){
        return ele.value == id
      },id)
      if(schoolObj) {
        return schoolObj
      }
    },
    getCompany(id) {
      let companyDictList =this.$store.state.companyDict;
      let companyObj = companyDictList.find(function(ele){
        return ele.value == id
      },id)
      if(companyObj) {
        return companyObj
      }
    },
    getUserInfo(id) {
      this.getRequest('web/user/' + id).then(res=>{
        this.userName = res.name;
        this.userPhone = res.phone;
      })
    },
    //浏览器样式调整
    monitoringScreenWidth() {
      // xs 	<768px
      // sm 	≥768px
      // md 	≥992px
      // lg 	≥1200px
      // xl 	≥1920px
      // console.log(this.screenWidth)
      if(this.screenWidth < 1058) {
        this.HamburgerBtnFlag = true;
      }else {
        this.HamburgerBtnFlag = false;
      }
      if(this.screenWidth < 776) {
        this.miniStyle = true;
      }else {
        this.miniStyle = false;
      }
      if(this.screenWidth < 730) {
        this.topImgMove = true;
      }else {
        this.topImgMove = false;
      }

    }
  }
}
</script>

<style scoped>
.top-image-box {
  position: relative;
  margin-top: 20px;
}
.top-image-box-mini {
  position: relative;
  margin-top: -22px;
}
.top-img {
  width: 100%;
  height: 120px;
}
.top-img-mini {
  width: 100%;
  height: 100px;
}
.tt-box{
  color: #DCD6D6;
  font-size: 24px;
  font-weight: bold;
  font-family: 微软雅黑;
  position: absolute;
  padding: 20px;
  top: 50%;
  left: 20px;
  transform: translate(0,-50%);
}
.tt-box-mini{
  color: #DCD6D6;
  font-size: 18px;
  font-weight: bold;
  font-family: 微软雅黑;
  position: absolute;
  padding: 20px;
  top: 50%;
  transform: translate(0,-50%);
}
.transfer-tag {
  border-radius: 40px;
  padding: 2px;
  font-size: 12px;
  color: #5daf34;
  background-color: rgba(243,251,248,0.3);
}
.basic-properties-box {
  background-color: rgb(249,250,251);
  padding: 20px;
  font-size: 14px;
  color: rgb(126,130,136);
  margin-top: 20px;
}
.basic-properties-box-mini {
  background-color: rgb(249,250,251);
  padding: 20px 10px 10px 20px;
  font-size: 12px;
  color: rgb(126,130,136);
  margin-top: 10px;
}
.properties-border {
  color: #2C2C2C;
  font-size: 18px;
  margin-top: 10px;
}
.properties-border-mini {
  color: #2C2C2C;
  font-size: 12px;
  margin-top: 8px;
}
.price-box {
  text-align: right;
  color: rgb(126,130,136);
  font-size: 14px;
  margin-top: 10px;
  margin-right: 20px;
}
.price-box-mini {
  text-align: right;
  color: rgb(126,130,136);
  font-size: 12px;
  margin-right: 20px;
  margin-top: 8px;
}
.small-red-font {
  color: rgb(233,70,67);
  font-size: 18px;
  font-weight: bold;
  margin-right: 4px;
}
.big-red-font {
  color: rgb(233,70,67);
  font-size: 22px;
  font-weight: bold;
}
.big-red-font-mini {
  color: rgb(233,70,67);
  font-size: 18px;
  font-weight: bold;
}
.buy-btn {
  margin-left: 30px;
  width: 120px;
  border-radius: 3px;
}
/*专利描述*/
.describe-box {
  margin-top: 20px;
  border-top: 2px solid rgb(240,240,240);
  padding-top: 20px;
  margin-bottom: 30px;
}
.describe-icon {
  margin-left: 6px;
  color: #ffab00;
}
.name-icon {
  color: #ffab00;
  margin-right: 4px;
}
.describe-tt {
  font-size: 20px;
  font-family: 方正大黑简体;
  color: #2F2F2F;
  border-left: 6px solid #2954A2;
  padding-left: 10px;
  margin-left: 6px;
}
.describe-tt-mini {
  font-size: 16px;
  font-family: 方正大黑简体;
  color: #2F2F2F;
  border-left: 6px solid #2954A2;
  padding-left: 10px;
  margin-left: 6px;
}
.describe-tx {
  margin: 20px 20px 0 20px;
  color: #2C2C2C;
  font-size: 18px;
}
.describe-tx-mini {
  margin: 10px;
  color: #2C2C2C;
  font-size: 14px;
}
.small-tx-font {
  color: rgb(126,130,136);
  font-size: 14px;
}
.small-tx-font-mini {
  color: rgb(126,130,136);
  font-size: 10px;
}
.company-box {
  margin: 20px 10px 0 10px;
}
.company-box-mini {
  margin: 10px;
}
.company-box-tt {
  color: #2C2C2C;
  font-family: 方正大黑简体;
  font-size: 18px;
  margin-bottom: 10px;
}
.company-box-tt-mini {
  color: #2C2C2C;
  font-family: 方正大黑简体;
  font-size: 15px;
}
.company-box-msg {
  color: #2C2C2C;
  font-size: 14px;
  font-weight: bold;
}
.company-box-msg-mini {
  color: #2C2C2C;
  font-size: 12px;
  font-weight: bold;
}
</style>
