<template>
  <div>
<!--    通用顶部-->
    <HeaderGlobal v-if="headerFlag" :menuFlag="menuFlag" :activeVal="activeVal"></HeaderGlobal>
<!--    登录页和注册页顶部-->
    <HeaderLogin v-else></HeaderLogin>
<!--    内容页面-->
<!--    <router-view/>-->
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>

    <!--    通用底部-->
    <FooterGlobal v-if="headerFlag"></FooterGlobal>
  </div>
</template>

<script>
import HeaderGlobal from "./components/HeaderGlobal";
import HeaderLogin from "./components/HeaderLogin";
import FooterGlobal from "./components/FooterGlobal";

export default {
  name: 'app',
  components: {
    HeaderGlobal,
    HeaderLogin,
    FooterGlobal
  },
  data() {
    return {
      headerFlag:true,
      menuFlag:true,
      activeVal:false
    }
  },
  // 监听,当路由发生变化的时候执行
  watch:{
    $route(to){
      this.headerFlag = true;
      if(to.path == '/login' || to.path == '/register') {
        this.headerFlag = false;
      }
      if(to.path == '/my_info' || to.path == '/my_patent' || to.path == '/my_demand' || to.path == '/my_project') {
        this.menuFlag = false;
      } else {
        this.menuFlag = true;
      }
      switch (to.name) {
        case 'ActivitiesPage':
          this.activeVal = 'ActivitiesPage'
          break
        case "PatentList":
          this.activeVal = 'PatentList'
          break
        case 'DemandPage':
          this.activeVal = 'DemandPage'
          break
        case "PolicyPage":
          this.activeVal = 'PolicyPage'
          break
        case "AchievementsPage":
          this.activeVal = 'AchievementsPage'
          break
        case 'AboutUsPage':
          this.activeVal = 'AboutUsPage'
          break
        case "ServiceHallPage":
          this.activeVal = 'ServiceHallPage'
          break
        case "Home":
          this.activeVal = 'Home'
          break

      }
    }
  },
  created() {
    document.title = '美一在线知识产权管理平台';
    this.saveTypeDict();
    this.saveCooperationType();
    this.saveMaturityType();
    this.savePatentLevel();
    this.saveUnitDict();
    this.saveMarketDict();
    this.saveGenderDict();
    this.getCompanyDict();
    this.getSchoolDict();
    this.getPatentTypeDict();

  },

  methods:{
    //分类字典请求并保存
    async saveTypeDict() {
      await this.getRequest('web/type/all').then( async res=>{
        //将未处理的数据保存在store中
        await this.$store.commit('saveUntreatedType',res)
        let classification = [];
        // 处理数据
        res.forEach(item=>{
          let obj = (({id, pid, name, icon, isDel}) => ({id, pid, name, icon, isDel}))(item);
          classification.push(obj);
          if(item.children) {
            item.children.forEach(item_children=>{
              let obj = (({id, pid, name, icon, isDel}) => ({id, pid, name, icon, isDel}))(item_children);
              classification.push(obj);
            })
          }
        })
        //分类字典保存到store
        await this.$store.commit('saveType',classification)
      })
    },
    //获取合作方式分类
    async saveCooperationType() {
      await this.getRequest('web/dict/type/before_patent_cooperation_type').then( async res=>{
        // console.log(res)
        if(res.code == 200) {
          //将合作方式分类保存在VueX中
          await this.$store.commit('saveCooperation',res.data)
        }
      })
    },
    //获取研发进度分类
    async saveMaturityType() {
      await this.getRequest('web/dict/type/before_patent_maturity').then(async res=>{
        // console.log(res)
        if(res.code == 200) {
          // 将成熟度分类保存在VueX中
          await this.$store.commit('saveMaturity',res.data)
        }
      })
    },
    //获取专利级别分类
    async savePatentLevel() {
      await this.getRequest('web/dict/type/before_patent_level').then(async res=>{
        // console.log(res)
        if(res.code == 200) {
          await this.$store.commit('savePatenLevel',res.data)
        }
      })
    },
    //获取单位字典并保存
    async saveUnitDict() {
      await this.getRequest('web/dict/type/before_price_type').then(async res=>{
        if(res.code == 200) {
          await this.$store.commit('saveUnitDict',res.data)
        }
      })
    },
    //保存市场化字典
    async saveMarketDict() {
      await this.getRequest('web/dict/type/before_patent_is_market').then(async res=>{
        if(res.code == 200) {
          await this.$store.commit('saveIsMarketDict',res.data)
        }
      })
    },
    //保存性别字典
    saveGenderDict() {
      this.getRequest('web/dict/type/sys_user_sex').then(res=>{
        if(res.code == 200) {
          this.$store.commit('saveGenderDict',res.data);
        }
      })
    },
    //合作公司字典
    getCompanyDict() {
      this.getRequest('web/partner/dict').then(res=>{
          this.$store.commit('saveCompanyDict',res);
      })
    },
    //学校字典
    getSchoolDict() {
      this.getRequest('web/school/dict').then(res=>{
         this.$store.commit('saveSchoolDict',res);
      })
    },
    //专利类型字典
    getPatentTypeDict() {
      this.getRequest('web/dict/type/before_patent_type').then(res=>{
        if(res.code == 200) {
          this.$store.commit('savePatentTypeDict',res.data);
        }
      })
    },
  }
}
</script>

<style>
/*#app {*/
/*  font-family: 'Avenir', Helvetica, Arial, sans-serif;*/
/*  -webkit-font-smoothing: antialiased;*/
/*  -moz-osx-font-smoothing: grayscale;*/
/*  text-align: center;*/
/*  color: #2c3e50;*/
/*  margin-top: 60px;*/
/*}*/
</style>
