<template>
  <div>
    <div v-if="patentList.length">
      <!--    主题专利推荐-->
      <el-row>
        <el-col :xs="12" :sm="12" :md="6" v-for="(item,index) in patentList" :key="index">
          <div :class="[miniStyle?'card-out-box-mini':'card-out-box']">
            <div class="patent-recommend-card">
              <!--          标题-->
              <div  :class="[miniStyle?'patent-recommend-tt-mini':'patent-recommend-tt']">{{item.name}}</div>
              <!--          专利号-->
              <div :class="[miniStyle?'patent-recommend-Numb-mini':'patent-recommend-Numb']">专利号：{{item.version}}</div>
              <!--          专利简介-->
              <div :class="[miniStyle?'patent-recommend-tx-mini':'patent-recommend-tx']">{{item.description}}</div>
              <!--          详情按钮-->
              <div class="patent-recommend-btn-box">
                <el-button class="patent-recommend-btn-mini" v-if="miniStyle"  @click="goPatentDetail(item.id)">了解详情</el-button>
                <el-button class="patent-recommend-btn" v-else @click="goPatentDetail(item.id)">了解详情</el-button>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <div v-else class="no-msg-box">
      暂无相关数据推荐
    </div>
  </div>
</template>

<script>
export default {
  name: "TopicPatentRecommend",
  props:['patentList'],
  data() {
    return {
      screenWidth: document.body.clientWidth,//浏览器宽度
      HamburgerBtnFlag:false,//是否显示汉堡按钮
      miniStyle:false,//手机样式
    }
  },
  created() {
    //监控屏幕变化
    this.monitoringScreenWidth();
  },
  mounted() {
    this.title = this.$route.params.name + '设备';
    const that = this
    window.addEventListener("resize", function() {
      return (() => {
        window.screenWidth= document.body.clientWidth;
        that.screenWidth= window.screenWidth;
      })();
    });
  },
  watch:{
    demandList() {
      this.dictionaryQueryProcessing();
    },
    //监听浏览器宽度
    screenWidth() {
      this.monitoringScreenWidth();
    }
  },
  methods:{
    goPatentDetail(id) {
        if(this.$cookies.get('user')){
          this.$router.push({name:'PatentDetail',query: {id:id}})
        }else {
          this.$message({
            message:'登录成为会员才能查看更多信息哦！',
            type:'warning'
          })
        }
    },
    //浏览器样式调整
    monitoringScreenWidth() {
      // xs 	<768px
      // sm 	≥768px
      // md 	≥992px
      // lg 	≥1200px
      // xl 	≥1920px
      if(this.screenWidth < 1058) {
        this.HamburgerBtnFlag = true;
      }else {
        this.HamburgerBtnFlag = false;
      }
      if(this.screenWidth < 640) {
        this.miniStyle = true;
      }else {
        this.miniStyle = false;
      }
    }
  }
}
</script>

<style scoped>
.card-out-box {
  padding-right: 20px;
  margin-top: 10px;
}
.card-out-box-mini {
  padding-right: 10px;
  margin-top: 10px;
}
.patent-recommend-card {
  box-shadow: 4px 4px 3px #ededed;
  background-color: #FFFFFF;
}
.patent-recommend-tt {
  background-color: #2954A2;
  color: #FFFFFF;
  font-size: 16px;
  /*padding: 10px;*/
  /*font-weight: bold;*/
  line-height: 40px;
  padding: 2px;
  text-align: center;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  word-break: break-all;
}
.patent-recommend-tt-mini {
  background-color: #2954A2;
  color: #FFFFFF;
  font-size: 14px;
  /*padding: 10px;*/
  /*font-weight: bold;*/
  line-height: 30px;
  padding: 2px;
  text-align: center;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  word-break: break-all;
}
.patent-recommend-Numb {
  color: #666666;
  font-size: 12px;
  text-align: center;
  /*margin-top: 5px;*/
  margin: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #cdcdcd;
}
.patent-recommend-Numb-mini {
  color: #666666;
  font-size: 10px;
  text-align: center;
  /*margin-top: 5px;*/
  margin: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #cdcdcd;
}
.patent-recommend-tx {
  color: #666666;
  font-size: 14px;
  padding: 10px;
  height: 64px;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden;
  word-break: break-all;
}
.patent-recommend-tx-mini {
  color: #666666;
  font-size: 12px;
  padding-left: 10px;
  padding-right: 10px;
  height: 64px;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden;
  word-break: break-all;
}
.patent-recommend-btn-box {
  text-align: center;
  margin-top: 10px;
}
.patent-recommend-btn {
  margin-bottom: 14px;
  background-color: #2954A2;
  color: #FFFFFF;
  border-radius: 0;
  font-size: 14px;
  padding-left: 40px;
  padding-right: 40px;
}
.no-msg-box {
  background-color: #FFFFFF;
  color: #666666;
  font-size: 12px;
  padding: 20px;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
}
.patent-recommend-btn-mini {
  margin-bottom: 14px;
  background-color: #2954A2;
  color: #FFFFFF;
  border-radius: 0;
  font-size: 8px;
  text-align: center;
  width: 90%;
  padding: 6px;
}
</style>
