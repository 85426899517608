<template>
  <div>
<!--    智能匹配页面-->
    <ModuleTitle :title="matchObj.role + '专利推荐'"></ModuleTitle>
    <el-row :gutter="10">
      <el-col :xs="{span:22,offset:1}" :sm="{span:18,offset:3}">
        <TopicPatentRecommend :patentList="patentList"></TopicPatentRecommend>
      </el-col>
    </el-row>
    <ModuleTitle :title="matchObj.role + '需求推荐'"></ModuleTitle>
    <el-row :gutter="10">
      <el-col :xs="{span:22,offset:1}" :sm="{span:18,offset:3}">
        <TopicDemandRecommend :demandList="demandList"></TopicDemandRecommend>
      </el-col>
    </el-row>

  </div>
</template>

<script>
import ModuleTitle from "@/components/HomePage/ModuleTitle";
import TopicPatentRecommend from "@/components/TopicDetailPage/TopicPatentRecommend";
import TopicDemandRecommend from "@/components/TopicDetailPage/TopicDemandRecommend";
export default {
  name: "IntelligentMatchDetailPage",
  components:{
    ModuleTitle,
    TopicPatentRecommend,
    TopicDemandRecommend
  },
  data(){
    return {
      matchObj:{},
      patentList:[],
      demandList:[]
    }
  },
  mounted() {
    this.matchObj = this.$route.query.matchObj;
  },
  created() {
    this.getPatentRecommendation();
    this.getDemandRecommendation();
  },
  methods:{
    getPatentRecommendation() {
      let url = 'web/patent/list?pageSize=4&pageNum=1'
      this.getRequest(url).then(res=>{
        this.patentList = res.row;
      })
    },
    getDemandRecommendation() {
      let url = 'web/demand/list?pageSize=4&pageNum=1';
      this.getRequest(url).then(res=>{
        this.demandList = res.row;
      })
    }
  }
}
</script>

<style scoped>

</style>