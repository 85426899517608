<template>
  <div>
<!--    我的需求页面-->
    <el-row>
      <el-col :span="12" :offset="6">
        <ModuleTitle title="我的需求"></ModuleTitle>
      </el-col>
      <el-col :span="3">
        <div class="upLoad-btn-box">
          <el-button class="upLoad-btn" size="mini" type="warning" @click="dialogFormVisible = true">需求发布 <i class="el-icon-s-promotion"></i></el-button>
        </div>
      </el-col>
      <el-col :xs="{span:22,offset:1}" :sm="{span:18,offset:3}">
        <div v-if="pageTotal">
          <DemandItem v-if="demandItemFlag" :demandList="myDemandList" @updateDemandList="getMyDemandList" edit-btn="true"></DemandItem>
        </div>
        <div v-else class="noData-box">
          暂无需求数据，快来发布你的需求吧！
        </div>
      </el-col>
    </el-row>
    <!--    分页-->
    <Paging
        :total="pageTotal"
        :pageSize="parameterObj.pageSize"
        :currentPage="parameterObj.pageNum"
        @changeCurrent="changeCurrent"
        @changeSize="changeSize"
    ></Paging>
    <!--    专利上传对话框-->
    <el-dialog title="发布需求" :visible.sync="dialogFormVisible" :width="dialogWidth">
      <UploadDemand
          v-if="dialogFormVisible"
          :demandObj="demandUploadForm"
          @dialogCancel="dialogCancel()"
          @demandObjSubmit="demandEditObjSubmit()"
      ></UploadDemand>
    </el-dialog>
  </div>
</template>

<script>
import ModuleTitle from "@/components/HomePage/ModuleTitle";
import Paging from "@/components/Paging";
import DemandItem from "@/components/DemandPage/DemandItem";
import UploadDemand from "@/components/DemandPage/UploadDemand";
export default {
  name: "MyDemand",
  components:{
    ModuleTitle,
    Paging,
    DemandItem,
    UploadDemand
  },
  data() {
    return {
      myDemandList:[],
      //专利上传对话框展示标志
      dialogFormVisible: false,
      // 表单提示词宽度
      formLabelWidth:'120px',
      // 上传的专利表单
      demandUploadForm: {},
      userId:null,
      pageTotal:0,//专利总条数
      parameterObj:{
        pageNum:1,
        pageSize:5
      },
      screenWidth: document.body.clientWidth,//浏览器宽度
      dialogWidth:'50%',//控制编辑框宽度
      demandItemFlag:false,
    }
  },
  created() {
    this.userId = this.$cookies.get('user').id;
    //获取我的需求列表
    this.getMyDemandList();
    //监控屏幕变化
    this.monitoringScreenWidth();
  },
  mounted() {
    const that = this
    window.addEventListener("resize", function() {
      return (() => {
        window.screenWidth= document.body.clientWidth;
        that.screenWidth= window.screenWidth;
      })();
    });
  },
  watch:{
    //监听浏览器宽度
    screenWidth() {
      this.monitoringScreenWidth();
    },
  },
  methods:{
    //关闭编辑对话框
    dialogCancel() {
      this.dialogFormVisible = false;
    },
    //拿到已经编辑好的专利数据
    demandEditObjSubmit() {
      this.dialogFormVisible = false;
      this.demandItemFlag = false;
      //刷新请求
      this.getMyDemandList();
    },
    //获取我的需求列表
    getMyDemandList() {
      let url = 'web/demand/user/list?pageSize='+this.parameterObj.pageSize+'&pageNum='+this.parameterObj.pageNum+'&userId=' + this.userId;
      this.getRequest(url).then(res=>{
        if(res.totalCount) {
          this.myDemandList = res.row;
          this.pageTotal = res.totalCount;
          this.parameterObj.pageNum = res.pageNum;
          this.parameterObj.pageSize = res.pageSize;
          this.demandItemFlag = true;
        }
      })
    },
    //请求另一页的数据
    changeCurrent(currentPage) {
      this.parameterObj.pageNum = currentPage;
      this.getMyDemandList();
    },
    //改变当前每页的数量
    changeSize(value) {
      this.parameterObj.pageSize = value;
      this.parameterObj.pageNum = 1;
      this.getMyDemandList();
    },
    //浏览器样式调整
    monitoringScreenWidth() {
      // xs 	<768px
      // sm 	≥768px
      // md 	≥992px
      // lg 	≥1200px
      // xl 	≥1920px
      // console.log(this.screenWidth)
      if(this.screenWidth < 1123) {
        this.dialogWidth ='90%';
      }else {
        this.dialogWidth = '50%';
      }
    }
  }
}
</script>

<style scoped>
.upLoad-btn-box {
  text-align: right;
}
.upLoad-btn {
  position: relative;
  top: 35%; /*偏移*/
  right: 20px;
  /*transform: translateY(100%);*/
}
.noData-box {
  text-align: center;
  margin-top: 20px;
  color: #666666;
  /*height: 200px;*/
  background-color: #efefef;
  padding: 20px;
}
</style>