<template>
  <div class="footer-box vertical-around">
    <div class="footer-tx" :class="[miniStyle?'margin-left-little':'']">
      <img src="../assets/QR_code.png" alt="" width="70px" height="70px">
      <div class="small-tx" v-if="!miniStyle">关注公众号</div>
      <div class="small-tx" v-else>关注公众号</div>
      <div class="left-big-tx" v-if="!miniStyle">
<!--        ICP备案/许可证号：闽ICP备16038990号-4-->
        <el-link href="https://beian.miit.gov.cn/" target="_blank">ICP备案/许可证号：闽ICP备16038990号-4</el-link>
      </div>
    </div>
    <div class="footer-tx">
      <div class="tel-tx">
        <i class="el-icon-headset footer-icon"></i>
        <span>0595-22182666</span>
      </div>
      <div class="small-tx">
        客服热线（服务时间：9：00-18：00工作日）
      </div>
      <div class="right-big-tx">
        地址：泉州市丰泽区高新产业园区（原浔美工业区）科技路海西电子信息产业育成基地办公楼7层
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FooterGlobal",
  data() {
    return {
      screenWidth: document.body.clientWidth,//浏览器宽度
      HamburgerBtnFlag:false,//是否显示汉堡按钮
      miniStyle:false,//手机样式
    }
  },
  created() {
    this.monitoringScreenWidth();
  },
  mounted() {
    const that = this
    window.addEventListener("resize", function() {
      return (() => {
        window.screenWidth= document.body.clientWidth;
        that.screenWidth= window.screenWidth;
      })();
    });
  },
  watch:{
    //监听浏览器宽度
    screenWidth() {
      this.monitoringScreenWidth();
    }
  },
  methods:{
    //浏览器样式调整
    monitoringScreenWidth() {
      if(this.screenWidth < 1173) {
        this.HamburgerBtnFlag = true;
        if(this.screenWidth < 730) {
          this.miniStyle = true;
        }else {
          this.miniStyle = false;
        }
      }else {
        this.HamburgerBtnFlag = false;
        this.miniStyle = false;
      }
    }
  }
}
</script>

<style scoped>
  .footer-box {
    height: 160px;
    width: 100%;
    background-color: #2F2F2F;
  }
  .footer-tx {
    text-align: center;
    color: #929292;
    margin-left: 6px;
  }
  .small-tx {
    font-size: 12px;
  }
  .left-big-tx {
    font-size: 14px;
    font-weight: bold;
    margin-top: 10px;
  }
  .tel-tx {
    font-size: 20px;
    font-weight: bold;
    color: #ffffff;
    margin: 10px;
  }
  .footer-icon {
    margin-right: 10px;
  }
  .right-big-tx {
    font-size: 12px;
    margin-top: 20px;
  }
.margin-left-little {
  margin-left: 4px;
}
</style>
