<template>
  <div class="bg-img">
    <el-row>
      <el-col :lg="{span:10,offset:7}" :md="{span:12,offset:6}" :sm="{span:14,offset:5}" :xs="{span:20,offset:2}">
        <!--      手机号登录-->
        <el-card class="card-css">
          <div class="login-tt">注册</div>
          <el-form :model="form" ref="form" label-width="100px" :rules="rules">
            <el-form-item label="账号：" prop="username">
              <el-input v-model="form.username" placeholder="请输入您的账号名"></el-input>
            </el-form-item>
            <el-form-item label="姓名：" prop="name">
              <el-input v-model="form.name" placeholder="请输入您的姓名"></el-input>
            </el-form-item>
            <el-form-item label="身份证号：" prop="idCard">
              <el-input v-model="form.idCard" placeholder="请输入您的身份证号"></el-input>
            </el-form-item>
            <el-form-item label="手机号：" prop="phone">
              <el-input v-model="form.phone" placeholder="请输入您的手机号"></el-input>
            </el-form-item>
            <el-form-item label="验证码：" prop="phoneCode">
              <el-row>
                <el-col :span="15">
                  <el-input v-model="form.phoneCode" placeholder="请输入手机号验证码"></el-input>
                </el-col>
                <el-col :span="8">
                  <el-button class="code-btn" type="primary" size="small" @click="getPhoneCode" :disabled="!telVerificationFlag">
                    <span v-if="!telVerificationFlag">重新发送 {{ telWait }} S</span>
                    <span v-else>获取验证码</span>
                  </el-button>
                </el-col>
              </el-row>
            </el-form-item>
            <el-form-item label-width="0">
              <el-row>
                <el-col :span="20" :offset="2">
                  <el-button type="primary" class="register-btn" @click="onsubmit" size="medium">确认注册</el-button>
                </el-col>
              </el-row>
            </el-form-item>
          </el-form>
        </el-card>
      </el-col>
    </el-row>

  </div>
</template>

<script>
export default {
  name: "register",
  components: {
  },
  data() {
    //手机号正则表达式
    let reg=/^(((13[0-9]{1})|(15[0-9]{1})|(16[0-9]{1})|(17[3-8]{1})|(18[0-9]{1})|(19[0-9]{1})|(14[5-7]{1}))+\d{8})$/
    let validatorPhone = function (phone, value, callback) {
      if (value === '') {
        callback(new Error('手机号不能为空'))
      } else if (!reg.test(value)) {
        callback(new Error('手机号格式错误'))
      } else {
        callback()
      }
    }
    //验证码正则表达式
    let numbReg = /^\d{6}$/
    let validatorSmsCode = function (code, value, callback) {
      if (value === '') {
        callback(new Error('验证码不能为空'))
      } else if (!numbReg.test(value)) {
        callback(new Error('验证码错误'))
      } else {
        callback()
      }
    }
    //身份证号正则表达式
    let IdCardReg = /^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/
    let validatorIdCard = function (code, value, callback) {
      if (value === '') {
        callback(new Error('身份证号不能为空'))
      } else if (!IdCardReg.test(value)) {
        callback(new Error('身份证号输入错误'))
      } else {
        callback()
      }
    }

    //账号号正则表达式
    let userNameReg = /[0-9A-Za-z]{6,12}/
    let validatorUserName = function (code, value, callback) {
      if (value === '') {
        callback(new Error('账号不能为空'))
      } else if (!userNameReg.test(value)) {
        callback(new Error('账号只能由6-8位数字或字母组成'))
      } else {
        callback()
      }
    }
    return{
      form: {
        username:'',
        name:"",
        idCard:"",
        phone:"",
        phoneCode:"",
      },
      rules:{
        phone: [
          { required: true,validator: validatorPhone, trigger: 'blur' }
        ],
        phoneCode: [
          { required: true,validator: validatorSmsCode, trigger: 'blur' }
        ],
        name:[
          { required: true, message: '名字不能为空', trigger: 'blur' },
          { min: 2, max: 25, message: '长度在 2 到 25 个汉字', trigger: 'blur' }
        ],
        idCard:[
          { required: true,validator: validatorIdCard, trigger: 'blur' }
        ],
        username:[
          { required: true,validator: validatorUserName, trigger: 'blur' }
        ]
      },
      telVerificationFlag:true,//手机验证码按钮可使用与否
      telWait: 60,//再次发送验证码等待的时间
    }
  },
  methods:{
    onsubmit() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          let reqUrl = 'web/user/register?'
          let obj = Object.entries(this.form);
          obj.forEach( (item,index) =>{
            if(index == 0) {
              reqUrl = reqUrl + item[0] + '=' + item[1]
            } else {
              reqUrl = reqUrl + '&' + item[0] + '=' + item[1]
            }
          })
          this.getRequest(reqUrl).then(res=>{
            if(res.code == 200) {
              // alert(res.msg);
              this.$message({
                message:'注册成功！',
                type:'success'
              })
              // this.$router.push('/login');
              this.$router.push({path:'/login',query:{waitTime:this.telWait+''}})
            }else {
              alert(res.msg);
            }
          })
        } else {
          alert('邮箱或者验证码输入错误!');
          return false;
        }
      });

    },
    //获取手机验证码
    getPhoneCode() {
      if(this.form.phone) {
        let url = 'web/user/register/code?phone=' + this.form.phone;
        this.getRequest(url).then(res=>{
          // console.log(res);
          if(res.code == 200) {
            alert(res.msg)
          }
        })
        this.telWaitTime();
      }else {
        alert('手机号不能为空！')
      }
    },
    //手机验证码等待倒计时
    telWaitTime() {
      let that = this;
      if (this.telWait == 0) {
        this.telVerificationFlag = true;
        this.telWait = 60;
      } else {
        this.telVerificationFlag = false;
        this.telWait--;
        setTimeout(function() {
          that.telWaitTime()
        }, 1000)
      }
    },
  }
}
</script>

<style scoped>
  .bg-img {
    background:url("../assets/login_bg.png");
    /*background-color: pink;*/
    width: 100%;
    height: 100%;
    position:fixed;
    margin-top: -0px;/*上边距*/
    margin-left: -0px;/*左边距*/
    background-size:100% 100%;
  }
  .card-css {
    margin-top:30px;
  }
  .login-tt {
    font-size: 30px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
  }
  .code-btn {
    margin-left: 10px;
   }
  .register-btn {
    width: 100%;
    margin-top: 10px;
  }
</style>
