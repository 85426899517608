<template>
  <div>
<!--    <div v-if="smallSize">-->
<!--      <div-->
<!--          class="patent-item"-->
<!--          v-for="(item,index) in projectList"-->
<!--          :key="index"-->
<!--          @click="goDetailPage(item.id)"-->
<!--      >-->
<!--        <el-row >-->
<!--          <el-col :span="4" class="vertical-center">-->
<!--            <div class="blue-circle">项目</div>-->
<!--          </el-col>-->
<!--          <el-col :span="16">-->
<!--            <div>-->
<!--              <div class="patent-item-name">-->
<!--                {{item.name}}-->
<!--                <span class="hot-icon"><i class="el-icon-collection-tag"></i>hot</span>-->
<!--              </div>-->
<!--              <div class="text-font">-->
<!--                <span> <i class="el-icon-document"></i></span>-->
<!--                分类: {{item.typeName}}-->
<!--              </div>-->
<!--              <div class="text-font">-->
<!--                <el-row>-->
<!--                  <el-col :span="3">-->
<!--                    <i class="el-icon-message"></i> 私信-->
<!--                  </el-col>-->
<!--                  <el-col :span="11">-->
<!--                    <i class="el-icon-sell"></i>-->
<!--                    合作方式: {{item.cooperationName}}-->
<!--                  </el-col>-->
<!--                  <el-col :span="10">-->
<!--                    <i class="el-icon-s-operation"></i>-->
<!--                    成熟度: {{item.maturityName}}-->
<!--                  </el-col>-->
<!--                </el-row>-->
<!--              </div>-->

<!--            </div>-->
<!--          </el-col>-->
<!--          <el-col :span="4">-->
<!--            <div v-if="item.price == -1">-->
<!--              <span class="price-font">面议</span>-->
<!--            </div>-->
<!--            <div v-else :class="[item.status == 1?'move-up':'','price-font']">-->
<!--              {{item.price}}-->
<!--              <span class="unit-font" v-if="item.price != -1">万元</span>-->
<!--            </div>-->
<!--          </el-col>-->
<!--        </el-row>-->
<!--      </div>-->
<!--    </div>-->
    <div>
      <div class="patent-item" v-for="(item,index) in projectList" :key="index"  @click="projectItemClick(item.id)">
        <el-row >
          <el-col  :xs="4" :sm="4" :md="4" :lg="3" class="vertical-center">
            <div style="color: #fff">.</div>
            <div :class="[miniStyle?'blue-circle-mini':'blue-circle']">项目</div>
          </el-col>
          <el-col  :xs="15" :sm="15" :md="15" :lg="10">
            <div>
              <div :class="[HamburgerBtnFlag?'patent-item-name-mini':'patent-item-name']">
                {{item.name}}
                <span :class="[HamburgerBtnFlag?'hot-icon-mini':'hot-icon']" v-if="item.isHot" ><i class="el-icon-collection-tag"></i>hot</span>
                <span v-if="item.isTop"><el-tag class="tag-box" size="mini">TOP</el-tag></span>
              </div>
              <div v-if="!miniBtnFlag">
                <div :class="[miniStyle?'text-font-mini':'text-font']">
                  <span> <i class="el-icon-document"></i></span>
                  分类: {{item.typeName}}
                </div>
                <div :class="[miniStyle?'text-font-mini':'text-font']">
                  <el-row>
                    <el-col :span="4" v-if="privateLetter">
                      <i class="el-icon-message"></i> 私信
                    </el-col>
                    <el-col :xs="12" :sm="12" :md="12" :lg="10">
                      <i class="el-icon-sell" v-if="!miniStyle"></i>
                      交易方式: {{item.cooperationName}}
                    </el-col>
                    <el-col :xs="12" :sm="12" :md="12" :lg="10">
                      <i class="el-icon-s-operation" v-if="!miniStyle"></i>
                      成熟度: {{item.maturityName}}
                    </el-col>
                  </el-row>
                </div>
              </div>
              <div v-else class="mini-msg-font">
                {{item.typeName}}/{{item.cooperationName}}/{{item.maturityName}}
              </div>
            </div>
          </el-col>
          <el-col :xs="5" :sm="5" :md="5" :lg="5">
            <div  :class="[HamburgerBtnFlag?'move-right-style':'']">
              <div v-if="item.price == -1" :class="[miniBtnFlag?'price-font-mini':'price-font']">
                <span :class="[miniBtnFlag?'price-font-mini':'price-font']">面议</span>
              </div>
              <div v-else :class="[miniBtnFlag?'price-font-mini':'price-font']">
                {{item.price}}
                <span :class="[miniBtnFlag?'unit-font-mini':'unit-font']" v-if="item.price != -1">万元</span>
              </div>
            </div>
          </el-col>
          <el-col :lg="6" v-if="!HamburgerBtnFlag">
            <div v-if="!editBtn" class="detail-btn">
              <el-button v-if="!miniStyle" type="primary"  @click="goDetailPage(item.id)">了解详情</el-button>
              <el-button v-else size="mini" type="primary"  @click="goDetailPage(item.id)">了解详情</el-button>
            </div>
            <div v-else class="my-detail-btn">
              <el-button-group style="margin-top: 6px">
                <el-button type="primary" size="mini" icon="el-icon-search"  @click="goActivitiesDetail(item.id)">详情</el-button>
                <el-button type="primary" size="mini" @click="editMyPatent(item)">编辑<i class="el-icon-edit el-icon--right"></i></el-button>
                <el-button type="primary" size="mini" @click="deleteMyPatent(item)">删除<i class="el-icon-delete el-icon--right"></i></el-button>
              </el-button-group>
              <el-button-group style="margin-top: 6px">
                <el-button type="primary" size="mini" icon="el-icon-view" @click="viewBoundPatents(item.id)">查看已绑定专利</el-button>
                <el-button type="primary" size="mini" @click="openBindingPatentDialog(item.id)">绑定专利 <i class="el-icon-link"></i></el-button>
              </el-button-group>
            </div>
          </el-col>
        </el-row>
        <div v-if="HamburgerBtnFlag && noJustDetail">
          <div v-if="!oneLineFlag">
            <div class="demand-operation-btn ">
              <el-button type="primary" size="mini" icon="el-icon-search"  @click="goActivitiesDetail(item.id)">详情</el-button>
              <el-button type="primary" size="mini" @click="editMyPatent(item)">编辑<i class="el-icon-edit el-icon--right"></i></el-button>
              <el-button type="primary" size="mini" @click="deleteMyPatent(item)">删除<i class="el-icon-delete el-icon--right"></i></el-button>
            </div>
            <div class="demand-operation-btn" style="margin-top: 5px">
              <el-button type="primary" size="mini" icon="el-icon-view" @click="viewBoundPatents(item.id)">查看已绑定专利</el-button>
              <el-button type="primary" size="mini" @click="openBindingPatentDialog(item.id)">绑定专利 <i class="el-icon-link"></i></el-button>
            </div>
          </div>
          <div v-else>
            <div class="demand-operation-btn ">
              <el-button type="primary" size="mini" icon="el-icon-search"  @click="goActivitiesDetail(item.id)">详情</el-button>
              <el-button type="primary" size="mini" @click="editMyPatent(item)">编辑<i class="el-icon-edit el-icon--right"></i></el-button>
              <el-button type="primary" size="mini" @click="deleteMyPatent(item)">删除<i class="el-icon-delete el-icon--right"></i></el-button>
              <el-button type="primary" size="mini" icon="el-icon-view" @click="viewBoundPatents(item.id)">查看已绑定专利</el-button>
              <el-button type="primary" size="mini" @click="openBindingPatentDialog(item.id)">绑定专利 <i class="el-icon-link"></i></el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--      发布项目的编辑框-->
    <el-dialog title="项目信息编辑" :visible.sync="dialogFormVisible" :width="dialogWidth">
      <UploadProject
          v-if="dialogFormVisible"
          :patentEditObj="patentEditObj"
          @dialogCancel="dialogCancel"
          @updateList="EditObjSubmit"
      ></UploadProject>
    </el-dialog>
<!--    删除项目的对话框-->
    <el-dialog
        title="提示"
        :visible.sync="deleteDialogVisible"
        :width="dialogWidth">
      <span>您是否要删除这条项目记录</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="deleteCancel">取 消</el-button>
        <el-button type="primary" @click="deleteSubmit">确 定</el-button>
      </span>
    </el-dialog>
<!--    查看已绑定专利的对话框-->
    <el-dialog title="查看已绑定专利" :visible.sync="boundPatentsDialog" :width="dialogWidth">
      <div v-if="boundPatentsList.length">
        <PatentItem :patentList="boundPatentsList" :bind-btn="1"  @relievePatent="relievePatent" no-just-detail="true"></PatentItem>
      </div>
      <div v-else class="noData-box">
        暂无数据
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="boundPatentsDialog = false">取 消</el-button>
<!--        <el-button type="primary" @click="boundPatentsDialog = false">确 定</el-button>-->
      </div>
    </el-dialog>
<!--    绑定专利对话框-->
    <el-dialog title="绑定专利" size="mini" :visible.sync="bindingPatentDialog" :width="dialogWidth">
<!--      输入绑定专利查询的参数对象-->
      <el-form :model="bindingPatentParameter" ref="bindingPatentParameter" :rules="rules" size="mini" >
        <el-row>
          <el-col :xs="24" :sm="12">
            <el-form-item label="专利名称" :label-width="formLabelWidth" placeholder="请输入专利名称" prop="name">
              <el-input
                  v-model="bindingPatentParameter.name"
                  autocomplete="off"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12">
            <el-form-item label="专利类别" :label-width="formLabelWidth" prop="typeId">
              <el-cascader
                  v-model="bindingPatentParameter.typeId"
                  :options="typeDict"
                  :props="{ checkStrictly: true }">
              </el-cascader>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12">
            <el-form-item label="专利等级" :label-width="formLabelWidth" prop="level">
              <el-select v-model="bindingPatentParameter.level" placeholder="请选择专利级别">
                <el-option
                    v-for="dict in before_patent_level"
                    :key="dict.dictValue"
                    :label="dict.dictLabel"
                    :value="parseInt(dict.dictValue)"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12">
            <el-form-item label="是否市场化" :label-width="formLabelWidth" prop="isMarket">
              <el-select v-model="bindingPatentParameter.isMarket" placeholder="请选择市场化">
                <el-option
                    v-for="dict in before_patent_is_market"
                    :key="dict.dictValue"
                    :label="dict.dictLabel"
                    :value="parseInt(dict.dictValue)"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12">
            <el-form-item label="成熟度" prop="maturity" :label-width="formLabelWidth">
              <el-select v-model="bindingPatentParameter.maturity" placeholder="请选择成熟度">
                <el-option
                    v-for="dict in before_patent_maturity"
                    :key="dict.dictValue"
                    :label="dict.dictLabel"
                    :value="parseInt(dict.dictValue)"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12">
            <el-form-item label="合作方式" :label-width="formLabelWidth" prop="cooperationType">
              <el-select v-model="bindingPatentParameter.cooperationType" placeholder="请选择合作方式">
                <el-option
                    v-for="dict in before_patent_cooperation_type"
                    :key="dict.dictValue"
                    :label="dict.dictLabel"
                    :value="parseInt(dict.dictValue)"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <div class="search-patents-btn">
            <el-button type="warning" @click="resetParameter" size="mini">重置搜索条件</el-button>
            <el-button type="primary" @click="searchPatents" size="mini">搜索专利 <i class="el-icon-search"></i></el-button>
          </div>
          <div class="tops-front">
            可绑定专利查询结果
          </div>
          <div v-if="bindingPatentList.length">
            <PatentItem :bind-btn="2" :patent-list="bindingPatentList" @bindingPatent="bindingPatent" no-just-detail="true"></PatentItem>
          </div>
          <div v-else class="noData-box">
            暂无数据
          </div>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="bindingPatentDialog = false">取 消</el-button>
        <el-button type="primary" @click="bindingPatentDialog = false">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import UploadProject from "@/components/AchievementsPage/UploadProject";
import PatentItem from "@/components/PatentPage/PatentItem";
export default {
  name: "AchievementsItem",
  props:['editBtn','projectList','smallSize','noJustDetail'],
  components:{
    UploadProject,
    PatentItem
  },
  data() {
    return {
      // patentList:[],
      projectId: null,//需要操作的项目id
      dialogFormVisible:false,//编辑对话框打开与否
      formLabelWidth:'100px',//编辑对话框提示文字的长度
      deleteDialogVisible:false,//删除对话框
      patentEditObj:{},//需要编辑的项目对象
      boundPatentsDialog:false,//已绑定专利对话框的打开与否
      boundPatentsList:[],//已绑定专利列表
      bindingPatentDialog:false,//绑定专利对话框的打开与否
      bindingPatentList:[],//查询到的可以绑定专利的列表
      bindingPatentParameter:{
        projectId:null,
        name:null,
        level:null,
        typeId:null,
        isMarket:null,
        maturity:null,
        cooperationType:null
      },//查询绑定专利的参数对象
      rules:{},
      typeDict:[],//分类字典
      before_patent_level:[],//分类等级字典
      before_patent_is_market:[],//市场化字典
      before_patent_maturity:[],//成熟度字典
      before_patent_cooperation_type:[],//合作方式字典
      HamburgerBtnFlag:false,//是否显示汉堡按钮
      miniStyle:false,//手机样式
      screenWidth: document.body.clientWidth,//浏览器宽度
      miniBtnFlag:false,//专利操作按钮
      privateLetter:true,//私信隐藏
      oneLineFlag:false,//操作按钮一行显示
      dialogWidth:'75%',
    }
  },
  created() {
    //数据处理
    this.dictionaryQueryProcessing();
    //监控屏幕变化
    this.monitoringScreenWidth();
  },
  watch:{
    projectList() {
      this.dictionaryQueryProcessing();
    },
    //监听浏览器宽度
    screenWidth() {
      this.monitoringScreenWidth();
    }
  },
  mounted() {
    const that = this
    window.addEventListener("resize", function() {
      return (() => {
        window.screenWidth= document.body.clientWidth;
        that.screenWidth= window.screenWidth;
      })();
    });
  },
  methods:{
    //删除
    deleteMyPatent(item) {
      this.deleteDialogVisible = true;
      this.deleteId = item.id;
    },
    //删除取消
    deleteCancel() {
      this.deleteDialogVisible = false;
      this.deleteId = null;
      this.$message({
        type: 'info',
        message: '已取消删除'
      });
    },
    //确认删除
    deleteSubmit() {
      this.deleteDialogVisible = false;
      this.deleteRequest('web/project/' + this.deleteId).then(res=>{
        if(res.code == 200) {
          this.$message({
            type: 'success',
            message: '删除成功'
          });
          this.$emit('updateProject')
        }else {
          this.$message({
            message:'操作失败',
            type:'warning'
          })
        }
      })
    },
    //打开编辑对话框
    editMyPatent(item) {
      this.patentEditObj = item;
      this.dialogFormVisible = true;
    },
    //关闭编辑对话框
    dialogCancel() {
      this.dialogFormVisible = false;
    },
    EditObjSubmit() {
      this.dialogFormVisible = false;
      //刷新数据
      this.$emit('updateProject')
    },
    //跳转到详情页面
    goDetailPage(id) {
      if(this.$cookies.get('user')){
        this.$router.push({name:'AchievementsDetail',query:{id:id}})
      }else {
        this.$message({
          message:'登录成为会员才能查看更多信息哦！',
          type:'warning'
        })
      }
    },
    goActivitiesDetail(id) {
      if(this.$cookies.get('user')){
        this.$router.push({name:'AchievementsDetail',query: {id:id}})
      }else {
        this.$message({
          message:'登录成为会员才能查看更多信息哦！',
          type:'warning'
        })
      }
    },
    //没有按钮时点击卡片跳转到详情页
    projectItemClick(id) {
      //卡片点击方法只有点击按钮不生效才能起作用
      if(this.HamburgerBtnFlag && !this.noJustDetail) {
        if(this.$cookies.get('user')){
          this.$router.push({name:'AchievementsDetail',query: {id:id}})
        }else {
          this.$message({
            message:'登录成为会员才能查看更多信息哦！',
            type:'warning'
          })
        }
      }
    },
    //查看已绑定的专利
    viewBoundPatents(id) {
      this.boundPatentsDialog = true;
      this.projectId = id;
      this.getRequest('web/project/binded?projectId=' + this.projectId).then(res=>{
        this.boundPatentsList = res;
      })
    },
    //重置专利查询参数
    resetParameter() {
      //查询绑定专利的参数对象
      this.bindingPatentParameter = {
        name:null,
        level:null,
        typeId:null,
        isMarket:null,
        maturity:null,
        cooperationType:null
      };
      this.bindingPatentParameter.projectId = this.projectId;
      this.searchPatents();
    },
    //打开绑定专利对话框
    openBindingPatentDialog(id) {
      this.bindingPatentParameter.projectId = id;
      this.projectId = id;
      this.searchPatents();
      this.getTypeDict(); //获取分类字典
      this.getPatentLevelDict();//获取分类等级字典
      this.getIsMarketDict();//获取市场化字典
      this.getMaturityDict();//获取成熟度字典
      this.getCooperationDict();//获取合作方式字典
      this.bindingPatentDialog = true;
    },
    //搜索可绑定专利列表
    searchPatents() {
      //专利分类处理
     if(this.bindingPatentParameter.typeId) {
       let typeArray= this.bindingPatentParameter.typeId;
       if (typeArray.length===2){
         this.bindingPatentParameter.typeId = typeArray[1];
       }else if(typeArray.length===1){
         this.bindingPatentParameter.typeId = typeArray[0];
       }else {
         this.bindingPatentParameter.typeId = typeArray;
       }
     }
     this.postRequest('web/project/canBind',this.bindingPatentParameter).then(res=>{
       this.bindingPatentList = res;
     })
    },
    //绑定专利请求
    bindingPatent(id) {
      let url = 'web/project/bind?projectId='+this.bindingPatentParameter.projectId+'&patentId=' + id
      this.getRequest(url).then(res=>{
        if(res.code == 200) {
          this.$message({
            message:res.msg,
            type:'success'
          })
          this.bindingPatentDialog = false;
        }
      })
    },
    //解除绑定专利操作
    relievePatent(id) {
      let url = 'web/project/unbind?projectId='+this.projectId+'&patentId='+id;
      this.getRequest(url).then(res=>{
        if(res.code == 200) {
          this.$message({
            message:res.msg,
            type:'success'
          })
          this.boundPatentsDialog = false;
        }
        if(res.code == 500) {
          this.$message({
            message:'专利解除绑定操作' + res.msg,
            type:'warning'
          })
        }
      })
    },
    //获取分类字典
    getTypeDict() {
      let url = 'web/type/dict';
      this.getRequest(url).then(res=>{
        this.typeDict = res;
      })
    },
    //获取分类等级字典
    getPatentLevelDict() {
      this.before_patent_level = this.$store.state.patentLevel;
    },
    //获取市场化字典
    getIsMarketDict() {
      this.before_patent_is_market = this.$store.state.marketDict;
    },
    //获取成熟度字典
    getMaturityDict() {
      this.before_patent_maturity = this.$store.state.maturity;
    },
    //获取合作方式字典
    getCooperationDict() {
      this.before_patent_cooperation_type = this.$store.state.cooperation;
    },
    //数据的字典查询
    dictionaryQueryProcessing() {
      this.projectList.forEach(item=>{
        //分类名称处理
        item.typeName = this.getTypeName(item.typeId);
        //获取成熟度分类
        item.maturityName = this.getMaturityName(item.maturity)
        //合作方式
        item.cooperationName = this.geCooperationName(item.cooperationType)
      })
    },
    //浏览器样式调整
    monitoringScreenWidth() {
      // xs 	<768px
      // sm 	≥768px
      // md 	≥992px
      // lg 	≥1200px
      // xl 	≥1920px
      console.log(this.screenWidth)
      if(this.screenWidth < 1274) {
        this.HamburgerBtnFlag = true;
        if(this.screenWidth < 858) {
          this.miniStyle = true;
        }else {
          this.miniStyle = false;
        }
      }else {
        this.HamburgerBtnFlag = false;
        this.miniStyle = false;
      }
      if(this.screenWidth < 585) {
        this.miniBtnFlag = true;
      }else {
        this.miniBtnFlag = false;
      }
      if(this.screenWidth >1200) {
        this.privateLetter = true;
      }else {
        this.privateLetter = false;
      }
      if(this.screenWidth >710) {
        this.oneLineFlag = true;
      }else {
        this.oneLineFlag = false;
      }
      if(this.screenWidth < 1123) {
        this.dialogWidth ='90%';
      }else {
        this.dialogWidth = '75%';
      }
    }
  }
}
</script>

<style scoped>
.patent-item {
  padding: 20px;
  box-shadow:2px 2px 5px #aaa;
  margin-top: 20px;
}
.blue-circle {
  height: 68px;
  width: 68px;
  border-radius: 50%;
  background-color: #2954A2;
  color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
}
.blue-circle-mini {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: #2954A2;
  color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
}
.patent-item-name {
  font-size: 16px;
  font-weight: bold;
  color: #222222;
}
.patent-item-name-mini {
  font-size: 14px;
  font-weight: bold;
  color: #222222;
}
.hot-icon {
  font-size: 16px;
  color: #FD3A0C;
}
.hot-icon-mini {
  font-size: 12px;
  color: #FD3A0C;
}
.text-font {
  color: #666666;
  font-size: 14px;
  margin-top: 10px;
}
.text-font-mini {
  color: #666666;
  font-size: 12px;
  margin-top: 5px;
}
.mini-msg-font {
  color: #666666;
  font-size: 11px;
  margin-top: 5px;
}
.text-font i {
  font-weight: bold;
}
.price-font {
  font-size: 25px;
  color: #FD3A0C;
  font-weight: bold;
  position: relative;
  top: 50%; /*偏移*/
  transform: translateY(50%);
  text-align: center;
}
.price-font-mini {
  font-size: 16px;
  color: #FD3A0C;
  font-weight: bold;
  position: relative;
  top: 50%; /*偏移*/
  text-align: center;
}
.move-up {
  margin-top: -20px;
}
.unit-font {
  font-size: 14px;
}
.unit-font-mini {
  font-size: 10px;
}
.detail-btn {
  position: relative;
  top: 50%; /*偏移*/
  transform: translateY(50%);
  text-align: center;
}
.my-detail-btn {
  position: relative;
  top: 50%; /*偏移*/
  /*transform: translateY(-50%);*/
  text-align: center;
}
/*搜索专利的按钮*/
.search-patents-btn {
  text-align: right;
  border-bottom: 2px solid #cccccc;
  padding-bottom: 10px;
  margin-bottom: 10px;
}
.tops-front {
  font-size: 14px;
  color: #acacac;
  text-align: center;
}
.mini-btn {
  float: right;
  margin-top: -50px;
}
.move-right-style {
  text-align: right;
}
.demand-operation-btn {
  text-align: center;
  margin-top: 12px;
}
.noData-box {
  text-align: center;
  margin-top: 20px;
  color: #666666;
  /*height: 200px;*/
  padding: 20px;
  background-color: #efefef;
}
.tag-box {
  position: absolute;
  /*background: #92b7bd;*/
  background-color: rgb(245,108,108);
  z-index: 999;
  border-radius: 3px;
  border: none;
  color: #fff;
  font-size: 70%;
  padding: 2px 4px 0;
  top: -22px;
  left: -25px;
  line-height: 1.4;
  -ms-transform: rotate(-7deg);
  -webkit-transform: rotate(-7deg);
  transform: rotate(-7deg);
  transition: transform 0.1s ease-in;
}
</style>
