<template>
  <div>
<!--    政策法规-->
    <el-card
        class="regulation-item-box"
        v-for="(item,index) in policyRegulationData"
        :key="index">
      <div >
        <!--      内容-->
        <div>
          <el-row>
            <!--          图片-->
            <el-col :xs="24" :sm="8" :md="7" :lg="6">
              <div class="img-vertical-center">
                <img :src="item.pictue" alt="" :class="[miniStyle?'regulation-img-mini':'regulation-img']">
              </div>

            </el-col>
            <!--          文字信息-->
            <el-col :xs="24" :sm="16" :md="17" :lg="18">
              <!--      标题-->
              <div :class="[miniStyle?'regulation-tt-mini':'regulation-tt']" @click="goDetailPage(item.id)">
                <span v-if="item.isTop"><el-tag class="tag-box" size="mini">TOP</el-tag></span>
                {{item.title}}
              </div>
              <div :class="[miniStyle?'regulation-tx-mini':'regulation-tx']">
                {{item.description}}
              </div>
              <div class="vertical-space-between" :class="[miniStyle?'regulation-release-msg-mini':'regulation-release-msg']">
                <div>发布时间:{{item.addTime}}</div>
                <div>发布单位: <span class="releaseCompany-font">{{item.company}}</span></div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "PolicyRegulations",
  props:['policyRegulationData'],
  data(){
    return {
      screenWidth: document.body.clientWidth,//浏览器宽度
      HamburgerBtnFlag:false,//是否显示汉堡按钮
      miniStyle:false,//手机样式
      urlList:[]
    }
  },
  created() {
    this.monitoringScreenWidth();
    // console.log(this.policyRegulationData)
  },
  mounted() {
    const that = this
    window.addEventListener("resize", function() {
      return (() => {
        window.screenWidth= document.body.clientWidth;
        that.screenWidth= window.screenWidth;
      })();
    });
  },
  watch:{
    //监听浏览器宽度
    screenWidth() {
      this.monitoringScreenWidth();
    },
  },
  methods:{
    goDetailPage(id) {
      this.$router.push({
        path: `/policy_page/policy_detail/${id}`,
      })
      // this.$router.push({name:'PolicyDetail',query:{id:id}})
      // if(this.$cookies.get('user')){
      //   this.$router.push({name:'PolicyDetail',query:{id:id}})
      // }else {
      //   this.$message({
      //     message:'登录成为会员才能查看更多消息！',
      //     type:'warning'
      //   })
      // }
    },
    //浏览器样式调整
    monitoringScreenWidth() {
      // xs 	<768px
      // sm 	≥768px
      // md 	≥992px
      // lg 	≥1200px
      // xl 	≥1920px
      // console.log(this.screenWidth)
      if(this.screenWidth < 1173) {
        this.HamburgerBtnFlag = true;
        if(this.screenWidth < 768) {
          this.miniStyle = true;
        }else {
          this.miniStyle = false;
        }
      }else {
        this.HamburgerBtnFlag = false;
        this.miniStyle = false;
      }
    },
  }
}
</script>

<style scoped>
.regulation-item-box {
margin-bottom: 20px;

}
.regulation-tt {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 16px;
  color: #222222;
  cursor: pointer;
}
.regulation-tt-mini {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 8px;
  color: #222222;
  margin-top: 8px;
  cursor: pointer;
}
.regulation-img {
  max-width: 90%;
  max-height: 140px;
  width: auto;
  height: auto
}
.regulation-img-mini {
  width: 80%;
}
.img-vertical-center {
  /*width: 100%;*/
  text-align: center;

}
.regulation-tx {
  color: #565555;
  font-size: 14px;

}
.regulation-tx-mini {
  color: #565555;
  font-size: 12px;
}
.regulation-release-msg {
  font-size: 14px;
  color: #222222;
  margin-top: 16px;
}
.regulation-release-msg-mini {
  font-size: 12px;
  color: #222222;
  margin-top: 16px;
}
.releaseCompany-font {
  color: #2954A2;
}
.tag-box {
  position: absolute;
  /*background: #92b7bd;*/
  background-color: rgb(245,108,108);
  z-index: 999;
  border-radius: 3px;
  border: none;
  color: #fff;
  font-size: 70%;
  padding: 2px 4px 0;
  top: -15px;
  left: -17px;
  line-height: 1.4;
  -ms-transform: rotate(-7deg);
  -webkit-transform: rotate(-7deg);
  transform: rotate(-7deg);
  transition: transform 0.1s ease-in;
}
</style>
